<template>
  <div id="UserCenter">
    <div class="content-main">
      <!--下半部分-->
      <div class="more-user">
        <!--左边-->
        <div class="mu-left">
          <Left></Left>
        </div>
        <!--右边-->
        <div class="mu-right">
          <div class="mr-title">基本资料<span></span></div>
          <div class="mr-fcm">
            <div class="mf-one">
              <span>登录账号：</span>
              <input type="text" disabled :value="userinfo.account" />
            </div>
            <div class="mf-one">
              <span>手机号码：</span>
              <input type="text" disabled :value="userinfo.phone" />
              <label @click="changePhone">变更手机</label>
            </div>
            <div class="mf-one">
              <span>账号状态：</span>
              <input
                type="text"
                disabled
                :value="userinfo.disable ? '禁用' : '正常'"
              />
            </div>
            <div class="mf-one">
              <span>注册时间：</span>
              <input type="text" disabled :value="userinfo.updated_at" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--变更手机-->
    <el-dialog title="变更手机号码" :visible.sync="phoneshow" width="25%">
      <div class="phone-main">
        <div class="phone-one">
          <span>原手机：</span>
          <input type="number" disabled v-model="param.phone" />
        </div>
        <div class="phone-one">
          <span>验证码：</span>
          <input
            type="number"
            style="width: 33%"
            v-model="param.code"
            placeholder="请输入验证码"
          />
          <label v-if="!isSend" @click="sendCode">{{ phoneCodeText }}</label>
          <label class="sendok" v-else>{{ sendDate }}秒后获取</label>
        </div>
        <div class="phone-one">
          <span>新手机：</span>
          <input type="number" v-model="param.new" placeholder="请输入新手机" />
        </div>
        <div class="phone-one">
          <span></span>
          <div class="change-btn" @click="changeNow">确认变更</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import UserApi from "../api/userApi";
import Left from "../components/Left.vue";
import { mapActions } from "vuex";

export default {
  name: "UserCenter",
  metaInfo() {
    return this.$head("用户中心");
  },
  data() {
    return {
      userinfo: {},
      phoneshow: false,
      param: {
        phone: "",
        code: "",
        new: "",
      },
      //注册验证码弹窗
      isSend: false,
      sendDate: 300,
      phoneCodeText: "发送验证码",
    };
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),

    async toSubmit() {
      try {
        await UserApi.realNameAuthentication(this.param);
        this.$message.success("认证成功");
        await this.getUserInfo();
      } catch (e) {
        this.$message.error(e);
      }
    },
    //获取用户基本信息
    async getUSerinfo() {
      try {
        let res = await UserApi.user();
        this.userinfo = res;
        this.param.phone = res.phone;
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    changePhone() {
      this.phoneshow = true;
    },
    async sendCode() {
      try {
        let res = await UserApi.sendCode({
          phone: this.param.phone,
        });
        this.isSend = true;
        this.sendDate = 300;
        let t = setInterval(() => {
          if (this.sendDate == 0) {
            clearInterval(t);
            this.isSend = false;
          } else {
            this.sendDate--;
          }
        }, 1000);
        this.$message.success("验证码发送成功");
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    changeNow() {
      if (!this.param.code) {
        this.$message.error("请输入验证码");
      } else if (!this.param.new) {
        this.$message.error("请输入新手机");
      } else {
        this.$confirm(
          "确认绑定新手机" + this.param.new + ", 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(async () => {
          try {
            await UserApi.cPhone(this.param);
            this.$message.success("修改成功");
            this.phoneshow = false
            this.param.code = "";
            this.param.new = "";
          } catch (error) {}
        });
      }
    },
  },
  components: {
    Left,
  },
  mounted() {},
  async created() {
    if (localStorage.getItem("token")) {
      await this.getUSerinfo();
    }
  },
};
</script>
<style scoped>
/* 整体盒子 */
#UserCenter {
  width: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}

.content-main {
  width: 1280px;
  height: auto;
  position: relative;
  margin: auto;
  z-index: 2;
  padding-top: 20px;
  overflow: hidden;
}

.more-user {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin-bottom: 30px;
}
.mu-left {
  width: 300px;
  height: auto;
  overflow: hidden;
  background: #fff;
  float: left;
  padding: 30px 0;
}
.mu-right {
  width: 960px;
  min-height: 800px;
  overflow: hidden;
  background: #fff;
  float: right;
  padding: 30px 0;
}
.mr-title {
  width: 93%;
  height: auto;
  margin: auto;
  border-left: solid 3px #000;
  height: 16px;
  line-height: 16px;
  padding-left: 1%;
}
.mr-title span {
  font-size: 14px;
  color: #999;
}
.mr-fcm {
  width: 89%;
  height: auto;
  overflow: hidden;
  padding: 2% 0 2% 50px;
}
.mr-fcm i {
  font-size: 20px;
  color: #008800;
}
.mf-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 30px;
}
.mf-one span {
  font-size: 14px;
}
.mf-one input {
  display: inline-block;
  background: #fff;
  border: solid 1px #eee;
  padding: 12px 10px;
  width: 250px;
}
.mf-one label {
  display: inline-block;
  font-size: 13px;
  padding-left: 15px;
  cursor: pointer;
  color: #008800;
}
.mf-btn {
  width: 160px;
  height: auto;
  overflow: hidden;
  text-align: center;
  background: #333;
  margin-top: 30px;
  margin-left: 76px;
  padding: 10px 0;
  color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
}
.mf-btn:hover {
  background: #000;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.phone-main {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.phone-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 20px;
}
.phone-one span {
  display: block;
  width: 100px;
  float: left;
  text-align: right;
  padding-top: 10px;
}
.phone-one input {
  display: block;
  width: 62%;
  float: left;
  padding: 10px;
}
.change-btn {
  width: 140px;
  float: left;
  background: #cb141d;
  color: #fff;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
}

.change-btn:hover {
  background: #e52d27;
}

.phone-one label {
  display: block;
  width: 25%;
  height: auto;
  text-align: center;
  background: #cb141d;
  color: #fff;
  float: left;
  margin-left: 20px;
  padding: 10px 0;
  cursor: pointer;
  margin-right: 10px;
}

.sendok {
  background: #eee !important;
  color: #666;
}
</style>
