<template>
    <transition name="fade">
        <div class="toast" v-show="$store.state.toast.show">
            {{$store.state.toast.msg}}
        </div>
    </transition>

</template>

<script>
export default {
    components: {},
    data() {
        return {

        };
    },
    created() {

    },
    methods: {

    },
    computed: {

    },
}
</script>

<style scoped lang="less">
.toast {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 8px 25px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    z-index: 99999;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
