<template>
  <div id="HomePage">
    <!--招标信息-->
    <div class="block-place">
      <div class="block-box">
        <!--顶部-->
        <div class="top-bg">
          <span>询价详情</span>
          <label for="" @click="toSignup" v-if="biddetail.state==2">参与询价</label>
        </div>
        <!--基础内容-->
        <div class="content">
          <div class="info">
            <div class="in-bigtitle">{{ biddetail.name }}</div>
            <div class="info-one">
              询价类型：{{ biddetail.tName }}
            </div>
            <div class="info-one">
              询价方式：{{ biddetail.type==1?'一般':'紧急' }}
            </div>
            <div class="info-one">
              询价编号：{{ biddetail.num}}
            </div>
            <div class="info-one">发布日期：{{ biddetail.created_at }}</div>
            <div class="info-one">开始日期：{{ biddetail.start_time }}</div>
            <div class="info-one">
              截止日期：{{ biddetail&&biddetail.end_time?biddetail.end_time:'' }}
            </div>
            <div class="info-one">
              当前状态：<span style="font-weight: bold; color: #333333" v-if="biddetail.state == 1">未开始</span>
              <span style="font-weight: bold; color: #25b864" v-if="biddetail.state == 2">询价中</span>
              <span style="font-weight: bold; color: #eb1d24" v-if="biddetail.state == 3">询价结束</span>
              <span style="font-weight: bold; color: #747da1" v-if="biddetail.state == 4">已开标</span>
              <span style="font-weight: bold; color: #666666" v-if="biddetail.state == 6">已流标（{{biddetail.reason}}）</span>
              <span style="font-weight: bold; color: #747da1" v-if="biddetail.state == 5">已中标</span>
            </div>
            <div class="info-one" v-if="biddetail.pName">中标单位：{{ biddetail.pName }}</div>
            <!-- <div v-if="timeshow" class="end-time">
              距询价结束：{{ count }}
            </div> -->
          </div>
          <div class="dese">
            <div class="desc-title">询价详情</div>
            <div class="desc-desc" v-html="biddetail.remarks"></div>
          </div>
          <div class="dese">
            <div class="desc-title">附件<span>点击即可下载</span></div>
            <div class="desc-desc">
              <div class="file-one" @click="downFile(item)" v-for="(item, index) in biddetail.inquiry_list_enclosure"
                :key="index">
                <i style="color: #e52d27" class="iconfont icon-file-jpg-Fill" v-if="
                  item.path.split('.')[1] == 'jpg' ||
                  item.path.split('.')[1] == 'png' ||
                  item.path.split('.')[1] == 'bmp'
                "></i>
                <i style="color: blue" class="iconfont icon-Word" v-else-if="
                  item.path.split('.')[1] == 'doc' ||
                  item.path.split('.')[1] == 'docx'
                "></i>
                <i style="color: green" class="iconfont icon-excel" v-else-if="
                  item.path.split('.')[1] == 'xls' ||
                  item.path.split('.')[1] == 'xlsx'
                "></i>
                <i style="color: #c00" class="iconfont icon-pdf" v-else-if="item.path.split('.')[1] == 'pdf'"></i>
                <i style="color: #333" class="iconfont icon-wenjian" v-else></i>
                <label for="">{{
                item.path.substring(item.path.lastIndexOf("/") + 1)
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RsaPubEncrypt } from "../../util/cipher";
import BidApi from "../../api/bidApi";
import UserApi from "../../api/userApi";
import NewsApi from "../../api/newsApi";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "HomePage",
  metaInfo() {
    return this.$head("询价详情-电子招标采购平台-四川纹江致远建筑开发工程有限公司");
  },
  data() {
    return {
      bid: "",
      biddetail: {},
      userinfo: {},
      isLogin: "",
      show: false,
      param: {
        account: "",
        password: "",
      },
      count: "", //倒计时
      seconds: null, // 10天的秒数
      status: 0,
      timeshow: true,
      dict: {
        0: {
          label: "未启用",
          color: "#333333",
        },
        1: {
          label: "未开始",
          color: "#25b864",
        },
        2: {
          label: "询价中",
          color: "#EB1D24",
        },
        3: {
          label: "询价结束",
          color: "#747DA1",
        },
        4: {
          label: "已开标",
          color: "#2c2cb5",
        },
        5: {
          label: "已流标",
          color: "#747DA1",
        },
      },
    };
  },
  components: {},
  computed: {
    ...mapState("game", ["gameList"]),
  },
  watch: {},
  mounted() {
    this.Time(); //调用定时器
  },
  methods: {
    downFile(item) {
      let imgUrl = "";
      if (item.path.includes("http") || item.path.includes("https")) {
        imgUrl = item.path;
      } else {
        imgUrl = "http://oa.scwjzy.cn/UpLoader/" + item.path;
      }

      const el = document.createElement("a");
      el.style.display = "none";
      el.setAttribute("target", "_blank");
      el.href = imgUrl;
      console.log(el);
      document.body.appendChild(el);
      el.click();
      document.body.removeChild(el);
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      d = d < 10 ? "0" + d : d;
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      s = s < 10 ? "0" + s : s;
      this.count = d + "天" + h + "时" + m + "分" + s + "秒";
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        this.seconds -= 1;
        this.countDown();
      }, 1000);
    },
    ...mapActions("user", ["login", "getUserInfo"]),
    ...mapMutations("user", ["loginOut"]),
    //用户登录
    toLogin() {
      this.$store.commit("login/setShow", true);
      this.$store.commit("login/setType", "accountReg");
    },
    async userLogin() {
      if (!this.param.account) {
        this.$message.error("请输入账号或手机号");
      } else if (!this.param.password) {
        this.$message.error("请输入密码");
      } else {
        let form = { ...this.param };
        form.password = RsaPubEncrypt(form.password);
        try {
          let res = await UserApi.uLogin(form);
          this.$message.success("登录成功");
          localStorage.setItem("token", res.token);
          this.userinfo = res.user;
          this.isLogin = res.token;
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },
    //获取用户信息
    async getUSerinfo() {
      try {
        let res = await UserApi.user();
        this.userinfo = res;
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    //获取招标详情
    async getDetail() {
      try {
        let res = await BidApi.inDetail({
          id: this.bid,
        });
        this.biddetail = res;
        let now = Date.parse(new Date()) / 1000;
        let date = new Date(res.end_time).getTime() / 1000;
        if (now > date) {
          this.timeshow = false
        }
        this.seconds = date - now;
        console.log(this.seconds);
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    //报名
    toSignup() {
      if (!this.isLogin) {
        this.$message.error("请先登录");
        setTimeout(() => {
          this.$router.push("/");
        }, 1200);
      } else {
        //用户已登录，判断招标状态
        if (this.biddetail.state == 2) {
          //判断是否有审核通过的供应商
          if (!this.userinfo.info) {
            this.$message.error("暂无审核通过的供应商");
          } else {
            //判断是否黑名单
            if (this.userinfo.info.black) {
              this.$message.error("您已被加入供应商黑名单，暂不能进行报名");
            } else if (this.biddetail.is_offer == 1) {
              this.$message.error('您已参与报价，不能重复报价')
            } else {
              this.$confirm(
                "确认使用用" + this.userinfo.info.name + "进行询价, 是否继续?",
                "提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then(() => {
                  //前往报名页面
                  this.$router.push({
                    path: "/inquiry/userinquiry",
                    query: { id: this.bid },
                  });
                })
                .catch(() => { });
            }
          }
        } else {
          this.$message.error("已过询价期，停止询价");
        }
      }
    },
  },
  async created() {
    //获取招标ID
    if (this.$route.query.id) {
      this.bid = this.$route.query.id;
    }
    if (localStorage.getItem("token")) {
      this.isLogin = localStorage.getItem("token");
      await this.getUSerinfo();
    }
    await this.getDetail();
  },
};
</script>
<style scoped>
/* 整体盒子 */
#HomePage {
  width: 100%;
}

.block-place {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin: auto;
}

.block-box {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 20px 0 40px 0;
}

.top-bg {
  width: 1240px;
  height: 120px;
  background-image: url("../../assets/img/bbg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  line-height: 120px;
  padding: 0 20px;
}

.top-bg span {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.desc-title span {
  float: right;
  font-size: 14px;
  color: #999;
  padding-top: 10px;
}

.file-list {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 50px;
}

.file-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 14px;
  padding: 15px 0;
  cursor: pointer;
}

.file-one i {
  display: inline-block;
  padding-right: 5px;
}

.file-one label {
  cursor: pointer;
}

.file-one label:hover {
  color: #9e241d;
}

.top-bg label {
  display: block;
  background: #f08616;
  cursor: pointer;
  color: #fff;
  float: right;
  width: 120px;
  height: 46px;
  line-height: 46px;
  margin-top: 37px;
  text-align: center;
}

.top-bg label:hover {
  background: #f59834;
}

.content {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.info {
  width: calc(100% - 40px);
  height: auto;
  overflow: hidden;
  padding: 30px 20px 20px 20px;
  background: #fff;
}

.dese {
  width: calc(100% - 40px);
  height: auto;
  overflow: hidden;
  margin-top: 20px;
  background: #fff;
  padding: 20px;
}

.desc-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-bottom: solid 1px #eee;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}

.desc-desc {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 20px;
  line-height: 24px;
}

.info-one {
  width: 30%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-bottom: 20px;
}
.info-one:nth-child(3n){float:right}
.info-one:nth-child(3n-2){margin-left:5%}
.end-time {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
  color: #f08616;
}

.in-bigtitle {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 10px 0 30px 0;
  font-size: 20px;
  font-weight: bold;
}

.b-right {
  width: 370px;
  float: right;
  height: auto;
  overflow: hidden;
}

.bid-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
  color: #9e241d;
}

.file-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-weight: bold;
  padding-bottom: 20px;
}

.file-title span {
  font-size: 14px;
  font-weight: normal !important;
  color: #999;
}

.file-list {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.file-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 14px;
  padding: 15px 0;
  cursor: pointer;
}

.file-one i {
  display: inline-block;
  padding-right: 5px;
}

.file-one label {
  cursor: pointer;
}

.file-one label:hover {
  color: #9e241d;
}
</style>
