import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'babel-polyfill'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



import common from './common/common';
Vue.use(common);

//SEO 网页标题
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo);


import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

/* swiper */

/* 重置样式 */
import './assets/css/reset.min.css';
//全局组件
import Toast from './components/Toast';
Vue.component('Toast', Toast);
import Login from './components/Login';
Vue.component('Login', Login);



Vue.config.productionTip = false
Vue.use(ElementUI);


router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

  // 兼容IE
  window.scrollTo(0, 0);
  next();
})

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
}).$mount('#app')
