<template>
  <div id="HomePage">
    <!--轮播，公告，用户登录-->
    <div class="banner-user">
      <!--banner-->
      <div class="banner">
        <el-carousel height="380px">
          <el-carousel-item v-for="(item, index) in balist" :key="index">
            <img :src="item.url" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--用户登录-->
      <div class="login-qu">
        <div class="user-login" v-if="!isLogin">
          <div class="ul-title">
            <div class="hg-title">用户登录</div>
          </div>
          <div class="ul-one">
            <i class="iconfont icon-zhanghao1"></i>
            <input type="text" placeholder="请输入账号或手机号" v-model="param.account" />
          </div>
          <div class="ul-one">
            <i class="iconfont icon-mima"></i>
            <input type="password" placeholder="请输入密码" v-model="param.password" />
          </div>
          <div class="ul-btn" @click="userLogin">立即登录</div>
          <div class="forget">
            <span @click="toLogin">新用户注册</span>
            <label for="" @click="findPwd">忘记密码？</label>
          </div>
        </div>
        <div class="user-login" v-else>
          <div class="ul-title">
            <div class="hg-title">欢迎您</div>
          </div>
          <div class="user-desc">
            <div class="user-logo">
              <img src="https://srm.cnzgc.com/static/portal/ec2021M8/style/images/zjttx.png" />
            </div>
            <div class="user-accou">{{ userinfo.account }}</div>
            <div class="user-num">
              <div>
                <span>投标数</span>
                <label for="">{{ userinfo.tbs ? userinfo.tbs : "0" }}</label>
              </div>
              <div>
                <span>中标数</span>
                <label for="">{{ userinfo.zbs ? userinfo.zbs : "0" }}</label>
              </div>
              <div>
                <span>询价数</span>
                <label for="">{{ userinfo.xjs ? userinfo.xjs : "0" }}</label>
              </div>
            </div>
            <div class="user-btn">
              <span @click="toUser">个人中心</span>
              <span @click="loginOut">退出登录</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--操作流程-->
    <div class="step">
      <div class="st-title">
        <span>平台入门指南</span>
        <label for="" @click="tozhinan">了解更多</label>
      </div>
      <div class="step-main">
        <div>
          <i class="iconfont icon-bianji"></i><span>注册账号</span><label for="">›</label>
        </div>
        <div>
          <i class="iconfont icon-bianjiwenjian"></i><span>完善信息</span><label for="">›</label>
        </div>
        <div>
          <i class="iconfont icon-shenhe"></i><span>公司审核</span><label for="">›</label>
        </div>
        <div>
          <i class="iconfont icon-tejiabaojia"></i><span>开始报价</span>
        </div>
      </div>
    </div>
    <!--招标信息-->
    <div class="block-place">
      <div class="title">| 招标公告<span @click="toMore" v-if="bList.length > 0">更多 ›</span></div>
      <div class="block-box">
        <div class="typelist">
          <span :class="selecttempids == item.id ? 'actives' : ''" @click="chooseTids(item)"
            v-for="(item, index) in typelist" :key="index">{{ item.name }}</span>
        </div>
        <!--所有询价-->
        <div class="in-list" v-if="typelist.length > 0">
          <div class="in-header">
            <span style="width: calc(50% - 20px); text-align: left; padding-left: 20px">招标名称</span>
            <span style="width: 20%; text-align: left;">发布日期</span>
            <span style="width: 20%; text-align: left;">投标截止日期</span>
            <span style="width: 10%; text-align: left;">状态</span>
          </div>
          <div v-if="bList.length > 0">
            <div class="in-one" @click="toDetail(item)" v-for="(item, index) in bList" :key="index">
              <div style="width: calc(50% - 20px); text-align: left; padding-left: 20px">
                {{ item.title }}
              </div>
              <div style="width: 20%; text-align: left;">{{ item.created_at }}</div>
              <div style="width: 20%; text-align: left;">{{ item.bidding_end_time }}</div>
              <div style="width: 10%; text-align: left;" v-if="item.bid_open==0">
                <span :style="'font-weight:bold;color:' + dicts[item.status].color">{{ dicts[item.status].label
                }}</span>
              </div>
              <div style="width: 10%; text-align: left;" v-if="item.bid_open==1">
                <span style="font-weight: bold">该项目为线下开标</span>
              </div>
            </div>
          </div>
          <div class="no-data" v-else>暂无招标项目</div>
        </div>
      </div>
    </div>
    <!--询价信息-->
    <div class="block-place" style="margin-top: 20px">
      <div class="title">| 询价公告<span @click="toMores" v-if="inList.length > 0">更多 ›</span></div>
      <div class="block-box">
        <!--分类-->
        <div class="typelist">
          <span :class="selecttempid == item.id ? 'active' : ''" @click="chooseTid(item)"
            v-for="(item, index) in typelist" :key="index">{{ item.name }}</span>
        </div>
        <!--所有询价-->
        <div class="in-list" v-if="typelist.length > 0">
          <div class="in-header">
            <span style="width: calc(40% - 20px); text-align: left; padding-left: 20px">询价项目</span>
            <span style="width: 20%; text-align: left;">询价方式</span>
            <span style="width: 15%; text-align: left;">开始日期</span>
            <span style="width: 15%; text-align: left;">截止日期</span>
            <span style="width: 10%; text-align: left;">状态</span>
          </div>
          <div v-if="inList.length > 0">
            <div class="in-one" @click="toDetails(item)" v-for="(item, index) in inList" :key="index">
              <div style="width: calc(40% - 20px); text-align: left; padding-left: 20px">
                {{ item.name }}
              </div>
              <div style="width: 20%; text-align: left;">{{ item.type == 1 ? '一般询价' : '紧急询价' }}</div>
              <div style="width: 15%; text-align: left;">{{ item.start_time }}</div>
              <div style="width: 15%; text-align: left;">{{ item.end_time }}</div>
              <div style="width: 10%; text-align: left;">
                <span style="font-weight:bold;color:#333333" v-if="item.state == 1">未启用</span>
                <span style="font-weight:bold;color:#25b864" v-if="item.state == 2">询价中</span>
                <span style="font-weight:bold;color:#EB1D24" v-if="item.state == 3">询价结束</span>
                <span style="font-weight:bold;color:#747DA1" v-if="item.state == 4">已开标</span>
                <span style="font-weight:bold;color:#666666" v-if="item.state == 5">已中标</span>
                <span style="font-weight:bold;color:#747DA1" v-if="item.state == 6">已流标</span>
              </div>
            </div>
          </div>
          <div class="no-data" v-else>暂无询价项目</div>

        </div>
      </div>
    </div>
    <!--中标公示-->
    <div class="block-place" style="margin-top: 20px">
      <div class="title">| 中标公示<span @click="toMoress" v-if="plist.length > 0">更多 ›</span></div>
      <div class="block-box">
        <!--所有询价-->
        <div class="in-list">
          <div class="in-header" style="display: block !important">
            <span style="
                width: 78% !important;
                text-align: left !important;
                padding-left: 20px;
                float: left;
              ">公示标题</span>
            <span style="color: #999; width: 20% !important; float: left">创建时间</span>
          </div>
          <div v-if="plist.length > 0">
            <div class="in-one" style="display: block !important" @click="toDetailss(item)"
              v-for="(item, index) in plist" :key="index">
              <div style="
                width: 78% !important;
                text-align: left !important;
                padding-left: 20px;
                float: left;
              ">
                {{ item.title }}
              </div>
              <div style="color: #999; width: 20% !important; float: left">
                {{ item.created_at }}
              </div>
            </div>
          </div>
          <div class="no-data" v-else>暂无中标公示</div>

        </div>
      </div>
    </div>
    <!--流标公告-->
    <div class="block-place" style="margin-top: 20px">
      <div class="title">| 流标公告<span @click="toMoresss" v-if="liulist.length > 0">更多 ›</span></div>
      <div class="block-box">
        <!--所有询价-->
        <div class="in-list">
          <div class="in-header" style="display: block !important">
            <span style="
                width: 78% !important;
                text-align: left !important;
                padding-left: 20px;
                float: left;
              ">公告标题</span>
            <span style="color: #999; width: 20% !important; float: left">创建时间</span>
          </div>
          <div v-if="liulist.length > 0">
            <div class="in-one" style="display: block !important" @click="toDetailss(item)"
              v-for="(item, index) in liulist" :key="index">
              <div style="
                width: 78% !important;
                text-align: left !important;
                padding-left: 20px;
                float: left;
              ">
                {{ item.title }}
              </div>
              <div style="color: #999; width: 20% !important; float: left">
                {{ item.created_at }}
              </div>
            </div>
          </div>
          <div class="no-data" v-else>暂无流标公示</div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RsaPubEncrypt } from "../util/cipher";
import BidApi from "../api/bidApi";
import BannerApi from "../api/bannerApi";
import UserApi from "../api/userApi";
import NewsApi from "../api/newApi";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "HomePage",
  metaInfo() {
    return this.$head("电子招标采购平台-四川纹江致远建筑开发工程有限公司");
  },
  data() {
    return {
      balist: [],
      userinfo: {},
      isLogin: "",
      show: false,
      param: {
        account: "",
        password: "",
      },
      inparam: {
        page: 1,
        size: 10,
        tid: "",
      },
      bparam: {
        page: 1,
        size: 10,
        tid: "",
      },
      bList: [],
      bannerList: [
        {
          id: 0,
          url: "http://builderp.cn/DYJGJTJC/images/2020Theme/index_top2.jpg",
        },
      ],
      typelist: [],
      selecttempid: "",
      selecttempids: "",
      inList: [],
      dict: {
        0: {
          label: "未启用",
          bcolor: "#333333",
          color: "#333333",
        },
        1: {
          label: "未开始",
          bcolor: "#98EBBB",
          color: "#25b864",
        },
        2: {
          label: "询价中",
          bcolor: "rgba(235, 29, 36, .1)",
          color: "#EB1D24",
        },
        3: {
          label: "询价结束",
          bcolor: "rgba(116,125,161,0.2)",
          color: "#747DA1",
        },
        4: {
          label: "已开标",
          bcolor: "#cccccc",
          color: "#666666",
        },
        5: {
          label: "已流标",
          bcolor: "rgba(116,125,161,0.2)",
          color: "#747DA1",
        },
      },
      dicts: {
        0: {
          label: "未启用",
          bcolor: "#333333",
          color: "#333333",
        },
        1: {
          label: "未开始",
          bcolor: "#98EBBB",
          color: "#25b864",
        },
        2: {
          label: "招标中",
          bcolor: "rgba(235, 29, 36, .1)",
          color: "#EB1D24",
        },
        3: {
          label: "招标结束",
          bcolor: "rgba(116,125,161,0.2)",
          color: "#747DA1",
        },
        4: {
          label: "已开标",
          bcolor: "#cccccc",
          color: "#666666",
        },
        5: {
          label: "已流标",
          bcolor: "rgba(116,125,161,0.2)",
          color: "#747DA1",
        },
        6: {
          label: "已中标",
          bcolor: "rgba(116,125,161,0.2)",
          color: "#747DA1",
        },
         7: {
          label: "终止招标",
          bcolor: "rgba(116,125,161,0.2)",
          color: "#747DA1",
        },
      },

      userDetail: {},
      platform: "2",
      allPhoneGame: [],
      codeUrl: location.origin + "/MobileGameDown",
      page: 1,
      size: 20,
      aid: "",
      zblist: {
        id: '17151090344595456',
        page: 1,
        size: 20
      },
      lblist: {
        id: '7986588965408768',
        page: 1,
        size: 20
      },
      plist: [],
      liulist: []
    };
  },
  components: {

  },
  computed: {},
  methods: {
    ...mapActions("user", ["login", "getUserInfo"]),
    ...mapMutations("user", ["loginOut"]),
    //获取banner列表
    async getBannerList() {
      let res = await BannerApi.getBanner();
      this.bannerList = res;
    },
    //用户登录
    toLogin() {
      this.$store.commit("login/setShow", true);
      this.$store.commit("login/setType", "accountReg");
    },
    async userLogin() {
      if (!this.param.account) {
        this.$message.error("请输入账号或手机号");
      } else if (!this.param.password) {
        this.$message.error("请输入密码");
      } else {
        let form = { ...this.param };
        form.password = RsaPubEncrypt(form.password);
        try {
          let res = await UserApi.uLogin(form);
          this.$message.success("登录成功");
          localStorage.setItem("token", res.token);
          this.userinfo = res.user;
          this.isLogin = res.token;
        } catch (error) {
          this.$message.error("账号或密码错误");
        }
      }
    },
    //获取用户信息
    async getUSerinfo() {
      let res = await UserApi.user();
      this.userinfo = res;
    },
    toUser() {
      location.href = "/UserCenter";
    },
    loginOut() {
      this.$message.success("退出成功");
      localStorage.clear();
      this.isLogin = "";
    },
    //获取招标询价分类
    async getAlltype() {
      let res = await BidApi.allType();
      this.typelist = res;
      this.typelist.unshift({
        id: "999",
        name: "全部",
      });
      this.selecttempid = "999";
      this.selecttempids = "999";
    },
    async chooseTid(item) {
      this.selecttempid = item.id;
      if (item.id != "999") {
        this.inparam.tid = item.id;
      } else {
        this.inparam.tid = "";
      }
      await this.getInquirylist();
    },
    async chooseTids(item) {
      this.selecttempids = item.id;
      if (item.id != "999") {
        this.bparam.tid = item.id;
      } else {
        this.bparam.tid = "";
      }
      await this.getBidlist();
    },
    //获取询价列表
    async getInquirylist() {
      let res = await BidApi.inquiryList(this.inparam);
      this.inList = res.list;
    },
    //获取招标列表
    async getBidlist() {
      let res = await BidApi.bidList(this.bparam);
      this.bList = res.list;
    },
    //获取公示列表
    async getPubliclist() {
      let res = await NewsApi.allNews(this.zblist);
      this.plist = res.list;
    },
    //获取流标列表
    async getPubliclists() {
      let res = await NewsApi.allNews(this.lblist);
      this.liulist = res.list;
    },
    //获取轮播图列表
    async getBanner() {
      let res = await BidApi.bannerList();
      this.balist = res;
    },
    tozhinan() {
      this.$router.push('/news/list?id=7986874362630144')
    },
    toMore() {
      this.$router.push("/bid/bidlist");
    },
    toMores() {
      this.$router.push("/inquiry/inquirylist");
    },
    toMoress() {
      this.$router.push({ path: "/news/list", query: { id: '17151090344595456' } });
    },
    toMoresss() {
      this.$router.push({ path: "/news/list", query: { id: '7986588965408768' } });
    },
    toDetail(item) {
      this.$router.push({ path: "/bid/detail", query: { id: item.id } });
    },
    toDetails(item) {
      this.$router.push({ path: "/inquiry/detail", query: { id: item.id } });
    },
    toDetailss(item) {
      this.$router.push({ path: "/news/detail", query: { id: item.id } });
    },
    findPwd() {
      this.$router.push("/forgetpwd");
    },
  },
  async created() {
    if (localStorage.getItem("token")) {
      this.isLogin = localStorage.getItem("token");
      await this.getUSerinfo();
    }
    await this.getBanner();
    await this.getAlltype();
    await this.getBidlist();
    await this.getInquirylist();
    await this.getPubliclist();
    await this.getPubliclists()
  },
};
</script>
<style scoped>
/* 整体盒子 */
#HomePage {
  width: 100%;
}

.banner-user {
  width: 100%;
  height: 380px;
  overflow: hidden;
  margin: auto;
  margin-bottom: 20px;
  position: relative;
}

.banner {
  width: 100%;
  height: 380px;
  margin: auto;
  z-index: 1;
}

.banner img {
  display: block;
  width: 100%;
  height: 380px;
}

.login-qu {
  position: absolute;
  right: calc((100% - 1280px) / 2);
  top: 20px;
  width: 360px;
  height: auto;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.95);
  z-index: 2;
}

.user-login {
  width: 320px;
  height: 300px;
  overflow: hidden;
  padding: 20px;
}

.ul-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 14px;
  margin-bottom: 20px;
}

.ul-title p {
  display: block;
  float: left;
  font-weight: bold;
}

.ul-title p span {
  margin-left: 10px;
}

.ul-title span {
  display: block;
  float: right;
  color: #999;
  cursor: pointer;
}

div::-webkit-scrollbar {
  width: 0;
}

.ul-one {
  width: 90%;
  margin: auto;
  height: auto;
  background: #f9f9f9;
  border: solid 1px #e9e9e9;
  padding: 16px 0;
  margin-top: 30px;
  padding-left: 3%;
  overflow: hidden;
}

.ul-one i {
  display: block;
  float: left;
}

.ul-one input {
  border: 0;
  background: #f9f9f9;
  width: 245px;
  display: block;
  float: left;
  margin-top: 2px;
}

.ul-btn {
  width: 90%;
  margin: auto;
  height: auto;
  overflow: hidden;
  text-align: center;
  background: #e52d27;
  color: #fff;
  margin-top: 25px;
  font-size: 14px;
  padding: 16px 0;
  cursor: pointer;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.2);
}

.forget {
  width: 90%;
  height: auto;
  overflow: hidden;
  margin: auto;
  margin-top: 30px;
}

.forget span {
  display: block;
  float: left;
  font-size: 12px;
  cursor: pointer;
}

.forget label {
  display: block;
  float: right;
  font-size: 12px;
  cursor: pointer;
}

.forget span:hover {
  color: #9e241d;
  text-decoration: underline;
}

.ul-btn:hover {
  background: #c00;
}

.hg-title {
  width: 100%;
  height: 16px;
  line-height: 16px;
  border-left: solid 3px #9e241d;
  padding-left: 10px;
  font-weight: bold;
}

.hg-title span {
  float: right;
  font-size: 12px;
  padding-right: 10px;
  cursor: pointer;
}

.hg-title span:hover {
  color: #e52d27;
}

.step {
  width: 1240px;
  height: auto;
  overflow: hidden;
  margin: auto;
  margin-top: 30px;
  background: #fff;
  margin-bottom: 20px;
  padding: 30px 20px;
}

.st-title {
  width: 130px;
  height: auto;
  overflow: hidden;
  float: left;
  text-align: center;
  border-right: solid 1px #eee;
}

.st-title span {
  display: block;
  font-size: 14px;
}

.st-title label {
  display: inline-block;
  background: #9e241d;
  color: #fff;
  font-size: 10px;
  color: #fff;
  padding: 4px 10px;
  margin-top: 10px;
  cursor: pointer;
}

.step-main {
  width: 1100px;
  display: flex;
  float: left;
}

.step-main>div {
  flex: 1;
  text-align: center;
  padding-top: 12px;
  cursor: pointer;
}

.step-main>div i {
  display: inline-block;
  font-size: 22px;
  padding-right: 10px;
  color: #9e241d;
}

.step-main>div span {
  display: inline-block;
  font-size: 14px;
}

.step-main>div label {
  display: inline-block;
  float: right;
  color: #999;
  font-size: 20px;
}

.step-main>div:hover {
  color: #9e241d;
}

.zhaobiao {
  width: 1280px;
  height: auto;
  overflow: hidden;
  background: #fff;
  margin: auto;
  margin-bottom: 20px;
}

.user-desc {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.user-logo {
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 100px;
}

.user-logo img {
  width: 60px;
  height: 60px;
  border-radius: 100px;
}

.user-accou {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  padding-top: 12px;
}

.user-num {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  padding-top: 30px;
}

.user-num>div {
  flex: 1;
  text-align: center;
}

.user-num>div span {
  display: block;
  font-size: 12px;
  color: #333;
}

.user-num>div label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  padding-top: 6px;
}

.user-btn {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  padding-top: 40px;
}

.user-btn>span {
  display: inline-block;
  background: #9e241d;
  font-size: 14px;
  padding: 12px 24px;
  cursor: pointer;
  color: #fff;
}

.user-btn>span:last-child {
  margin-left: 20px;
  background: #204d74;
}

.block-place {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin: auto;
}

.title {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 15px;
}

.title span {
  font-size: 16px;
  float: right;
  display: block;
  font-weight: normal !important;
  color: #999;
  padding-top: 8px;
  cursor: pointer;
}

.title span:hover {
  color: #000;
}

.block-box {
  width: calc(100% - 40px);
  height: auto;
  overflow: hidden;
  background: #fff;
  padding: 20px;
}

.typelist {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 10px 0;
}

.typelist span {
  display: inline-block;
  margin-right: 25px;
  background: #f2f2f2;
  font-size: 14px;
  padding: 12px 35px;
  cursor: pointer;
  color: #999;
}

.typelist span:hover {
  color: #666;
}

.active {
  background: #9e241d !important;
  color: #fff !important;
}

.actives {
  background: #204d74 !important;
  color: #fff !important;
}

.in-list {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 10px;
  border-left: solid 1px #d5d5d5;
  border-right: solid 1px #d5d5d5;
}

.in-header {
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #f2f2f2;
  border-top: solid 1px #d5d5d5
}

.in-header>span {
  float: left;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #666;
  padding: 15px 0;
}

.in-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-bottom: solid 1px #d5d5d5;
}

.in-one>div {
  float: left;
  text-align: center;
  font-size: 14px;
  padding: 25px 0 25px 0;
  cursor: pointer;
}

.in-one:hover {
  background: #f2f9fe;
}


.no-data {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  padding: 20px 0;
  color: #999;
  font-size: 14px;
  border-bottom: solid 1px #d5d5d5;
}
</style>
