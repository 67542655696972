<template>
  <div id="UserCenter">
    <div class="content-main">
      <!--下半部分-->
      <div class="more-user">
        <!--左边-->
        <p>| 您正在找回密码</p>
        <div class="f-main">
          <div class="fm-one">
            <span>登录账号：</span>
            <input
              type="text"
              v-model="param.account"
              placeholder="请输入登录账号"
            />
          </div>
          <div class="fm-one">
            <span>手机号码：</span>
            <input
              v-model="param.phone"
              type="number"
              placeholder="请输入手机号码"
            />
            <label v-if="!isSend" @click="sendCode">{{ phoneCodeText }}</label>
            <label class="sendok" v-else>{{ sendDate }}秒后获取</label>
          </div>
          <div class="fm-one">
            <span>验证码：</span>
            <input
              v-model="param.code"
              type="number"
              placeholder="请输入验证码"
            />
          </div>
          <div class="fm-one">
            <span>登录密码：</span>
            <input
              v-model="param.password"
              type="password"
              placeholder="请输入登录密码"
            />
          </div>
          <div class="fm-one">
            <span>确认密码：</span>
            <input
              v-model="param.confirmPassword"
              type="password"
              placeholder="请输入确认密码"
            />
          </div>
          <div class="fm-one">
            <span></span>
            <div class="submit-btn" @click="resPwd">重置密码</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RsaPubEncrypt } from "../util/cipher";
import UserApi from "../api/userApi";
import Left from "../components/Left.vue";
import { mapActions } from "vuex";

export default {
  name: "UserCenter",
  metaInfo() {
    return this.$head("找回密码");
  },
  data() {
    return {
      userinfo: {},
      param: {
        account: "",
        phone: "",
        code: "",
        password: "",
        confirmPassword: "",
      },
      phoneCodeText: "发送验证码",
      isSend: false,
      sendDate: 300,
    };
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),

    async toSubmit() {
      try {
        await UserApi.realNameAuthentication(this.param);
        this.$message.success("认证成功");
        await this.getUserInfo();
      } catch (e) {
        this.$message.error(e);
      }
    },
    //获取用户基本信息
    async getUSerinfo() {
      try {
        let res = await UserApi.user();
        this.userinfo = res;
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    async sendCode() {
      if (!this.param.phone) {
        this.$message.error("请输入手机号码");
      } else {
        try {
          let res = await UserApi.sendCode({
            phone: this.param.phone,
          });
          this.isSend = true;
          this.sendDate = 300;
          let t = setInterval(() => {
            if (this.sendDate == 0) {
              clearInterval(t);
              this.isSend = false;
            } else {
              this.sendDate--;
            }
          }, 1000);
          this.$message.success("验证码发送成功");
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },
    async resPwd() {
      if (!this.param.account) {
        this.$message.error("请输入登录账号");
      } else if (!this.param.phone) {
        this.$message.error("请输入手机号码");
      } else if (!this.param.code) {
        this.$message.error("请输入验证码");
      } else if (!this.param.password) {
        this.$message.error("请输入新密码");
      } else if (!this.param.confirmPassword) {
        this.$message.error("请输入确认密码");
      } else if (this.param.password != this.param.confirmPassword) {
        this.$message.error("两次密码输入不一致");
      } else {
        try {
          let p = {...this.param}
          p.password = RsaPubEncrypt(p.password);
          p.confirmPassword = RsaPubEncrypt(p.confirmPassword);
          await UserApi.findPwd(p)
          this.$message.success('重置密码成功')
          this.param.account = ""
          this.param.phone = ""
          this.param.code = ""
          this.param.password = ""
          this.param.confirmPassword = ""
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },
  },
  components: {
    Left,
  },
  mounted() {},
  async created() {
    if (localStorage.getItem("token")) {
      await this.getUSerinfo();
    }
  },
};
</script>
<style scoped>
/* 整体盒子 */
#UserCenter {
  width: 100%;
  height: auto;
  background: #f2f2f2;
  overflow: hidden;
}

.content-main {
  width: 1280px;
  height: auto;
  position: relative;
  margin: auto;
  z-index: 2;
  padding-top: 20px;
  overflow: hidden;
}

.more-user {
  width:1280px;
  height: auto;
  overflow: hidden;
  background: #fff;
  padding: 20px;
}
.f-main {
  width: 500px;
  height: auto;
  margin: auto;
  padding: 50px 0;
}
.fm-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 30px;
}
.fm-one span {
  width: 100px;
  height: auto;
  overflow: hidden;
  text-align: right;
  float: left;
  font-size: 14px;
  margin-top: 10px;
}
.fm-one input {
  display: block;
  width: 200px;
  height: auto;
  overflow: hidden;
  border: solid 1px #ccc;
  font-size: 14px;
  padding: 10px;
  float: left;
}
.fm-one label {
  display: block;
  float: left;
  margin-left: 10px;
  background: #9e241d;
  color: #fff;
  width: 120px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
}
.submit-btn {
  width: 160px;
  margin-bottom: 80px;
  height: 48px;
  background: #9e241d;
  color: #fff;
  float: left;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.submit-btn:hover {
  background: #e52d27;
}
.sendok {
  background: #eee !important;
  color: #999;
}
</style>
