

export default {

    namespaced: true,

    state: {
        show: false,
        msg: '',
        timer: null,

    },

    mutations: {
        setShow: (state, data) => { state.show = data },
        setMsg: (state, data) => { state.msg = data },
        setTimer: (state, data) => { state.timer = data },

    },

    actions: {


    },
}
