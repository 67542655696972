<template>
  <!-- 头部整体盒子 -->
  <div id="header" class="container-fuild">
    <!-- 头部顶部 -->
    <div class="header-top">
      <div class="container">
        <div class="server">
          您好，欢迎来到四川纹江致远建筑开发工程有限公司电子招标采购平台！
        </div>
        <div class="shejiao">
          <span @click="toGuan">联系我们</span>
          <span v-if="isLogin">-</span>
          <span @click="toUser" v-if="isLogin">个人中心</span>
        </div>
      </div>
    </div>
    <div class="gn" style="width: 100%; height: 1px; background: #fff"></div>
    <!-- 电脑导航 -->
    <div class="header-nav">
      <!-- 导航logo -->
      <div class="header-logo">
        <img src="@/assets/img/web-logo.png" />
      </div>
      <!-- 导航内容 -->
      <div class="web-nav">
        <ul class="header-nav-wrapper">
          <li v-for="(item, index) in navList" :key="index" :class="activePath == item.path ? 'active' : ''"
            @click="navClick(item.path)">
            <a>
              {{ item.name }}
              <span v-if="item.children.length > 0" class="glyphicon glyphicon-menu-down"></span>
              <i class="underline"></i>
            </a>
            <dl v-if="item.children.length > 0">
              <dt v-for="(i, n) in item.children" :key="n">
                <router-link :to="i.path">{{ i.name }}</router-link>
              </dt>
            </dl>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import NewsApi from "../api/newApi"
export default {
  name: "Header",
  data() {
    return {
      path: "/",
      gameName: "",
      isLogin: "",
      userDetail: {},
      hgameid: "0",
      show: false,
      menuClass: "glyphicon glyphicon-menu-down",
      activePath: "/Index",
      navList: [
        {
          name: "首页",
          path: "/Index",
          children: [],
        },
        {
          name: "招标公告",
          path: "/bid/bidlist",
          children: [],
        },
        {
          name: "询价公告",
          path: "/inquiry/inquirylist",
          children: [],
        }
        // {
        //   name: "中标公示",
        //   path: "/public/list",
        //   children: [],
        // }
      ],
    };
  },
  components: {},
  async created() {
    if (localStorage.getItem('token')) {
      this.isLogin = localStorage.getItem('token')
    } else {
      this.isLogin = ""
    }
    //栏目加载
    let nav = []
    if (sessionStorage.getItem('nav')) {
      nav = JSON.parse(sessionStorage.getItem('nav'));
      for (let i = 0; i < nav.length; i++) {
        this.navList.push({
          name: nav[i].name,
          path: '/news/list?id=' + nav[i].id,
          sortid: nav[i].sort,
          children: []
        })
      }
    } else {
      await this.getAllCloumn()
    }
  },
  // 判断路由
  mounted() {
    this.activePath = this.$route.fullPath;
    console.log(this.$route)
  },
  watch: {
    $route(to) {
      this.activePath = this.$route.fullPath;
    },
  },
  computed: {
  },
  methods: {
    //获取所有栏目
    async getAllCloumn() {
      let res = await NewsApi.allCloumn()
      if (res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          this.navList.push({
            name: res[i].name,
            path: '/news/list?id=' + res[i].id,
            sortid: res[i].sort,
            children: []
          })
        }
        sessionStorage.setItem('nav', JSON.stringify(res));
      }
    },
    toGuan() {
      const { href } = this.$router.resolve({
        path: '/news/detail',
        query: {
          id: '51119522853163008'
        }
      });
      window.open(href, '_blank');
      // window.open('http://scwjzy.cn/', "");
      // this.$router.resolve('/news/detail?id=20020365090623488')
    },
    toUser() {
      if (localStorage.getItem('token')) {
        this.$router.push('/UserCenter')
      } else {
        this.$message.error('请先登录')
      }
    },
    toLogin() {
      this.setType("login");
      this.setShow(true);
    },
    navClick(url) {
      location.href = url;
    },
    menuClick() {
      if (this.menuClass == "glyphicon glyphicon-menu-down") {
        this.menuClass = "glyphicon glyphicon-menu-up";
      } else {
        this.menuClass = "glyphicon glyphicon-menu-down";
      }
    },
  },
};
</script>
<style scoped>
/* 顶部 */
#header {
  transition: all ease 0.6s;
}

.container-fuild {
  width: 100%;
  height: auto;
  background: #fff;
  z-index: 3;
  user-select: none;
}

.server {
  float: left;
}

.shejiao {
  float: right;
  cursor: pointer;
}

.container:hover .all-game {
  display: block;
}

@keyframes scaleDraw {

  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    top: -100px;
    /*开始为原始大小*/
  }

  25% {
    top: -75px;
    /*放大1.1倍*/
  }

  50% {
    top: -50px;
  }

  75% {
    top: -25px;
  }

  100% {
    top: 36px;
  }
}

#header .header-top {
  width: 100%;
  height: 36px;
  color: #000;
  font-size: 12px;
  line-height: 36px;
  margin: auto;
  background: #F3F4F8;
}

.container {
  width: 1280px;
  margin: auto;
  height: auto;
  position: relative;
  cursor: pointer;
}

.all-game {
  width: 1280px;
  position: absolute;
  left: 0;
  top: 36px;
  display: none;
  background: rgba(255, 255, 255, 0.98);
  color: #999;
  z-index: 9;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.03);
}

.ag-piclist {
  width: 425px;
  height: 255px;
  float: left;
  position: relative;
}

.ag-pic {
  width: 425px;
  height: 255px;
  position: absolute;
  left: 0;
  top: 0;
}

.ag-pic img {
  width: 425px;
  height: 255px;
}

.ag-list {
  width: 575px;
  height: 255px;
  float: left;
  padding: 0 80px;
}

.al-box {
  width: 100%;
  height: auto;
}

.al-box p {
  width: 160px !important;
  display: block;
  height: auto;
  overflow: hidden;
  float: left;
  margin-top: 10px;
}

.al-box p span {
  display: block;
  float: left;
}

.al-box p i {
  display: block;
  float: left;
}

.ag-title {
  display: block;
  width: 100%;
  height: auto;
  padding: 10px 0 10px 0;
  border-bottom: solid 1px #eee;
  color: #000;
  font-size: 16px;
}

.al-one:hover {
  color: #000;
}

.hot {
  color: #9E241D;
}

.jian {
  color: #008800;
}

.game-tool {
  width: 240px;
  height: auto;
  text-align: center;
  float: left;
  font-size: 12px;
}

.game-tool p {
  line-height: 20px;
  margin: 20px 0;
}

.game-tool a {
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.game-tool a i {
  font-size: 38px;
  color: #5fba20;
}

.game-tool a:last-child i {
  color: #128bf8;
}

.game-tool a span {
  display: block;
  color: #333;
}

/* 顶部的图标 */
#header .header-top span {
  margin: 0 8px;
}

/* 导航栏 */
.header-nav {
  width: 1280px;
  height: 110px;
  margin: auto;
  background: #fff;
}

.header-logo {
  width: 320px;
  height: auto;
  overflow: hidden;
  float: left;
}

.header-logo img {
  display: block;
  width: 100%;
  height: auto;
}

/* 导航栏logo */
.header-nav .header-nav-logo {
  width: 200px;
  height: 100%;
  float: left;
  position: relative;
}

/* 导航栏logo图片 */
.header-nav .header-nav-logo img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.web-nav {
  width: auto;
  overflow: hidden;
  float: left;
}

/* 导航栏 导航容器 */
.header-nav-fixed .header-nav-wrapper {
  line-height: 50px;
}

.server i {
  font-size: 14px;
  padding-right: 5px;
}

.header-nav .header-nav-wrapper {
  line-height: 95px;
  margin: 0 0 0 50px;
  max-width: 1000px;
}

/* 导航栏 每个导航 */
.header-nav .header-nav-wrapper>li {
  float: left;
  margin: 0 40px;
  position: relative;
  cursor: pointer;
}

/* 导航栏 每个导航下面的 a 链接 */
.header-nav .header-nav-wrapper>li>a {
  color: #000;
  font-size: 15px;
  font-weight: bold;
  padding: 15px 0;
  position: relative;
}

/* 导航栏 每个导航下面的 a 链接的下划线 */
.header-nav .header-nav-wrapper>li>a>i {
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  opacity: 0;
  transition: all 0.6s ease;
  background-color: #9E241D;
}

/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
.header-nav .header-nav-wrapper>li>a>span {
  font-size: 12px;
  transition: transform ease 0.5s;
}

/* 导航栏 每个导航下面的 a 链接 鼠标滑上去的样式 */
.header-nav .header-nav-wrapper>li>a:hover {
  color: #9E241D;
  text-decoration: none;
}

/* 导航栏 每个导航下面的 a 链接 鼠标滑上去下划线的样式 */
.header-nav .header-nav-wrapper>li>a:hover .underline {
  opacity: 1;
  width: 100%;
  left: 0;
}

/* 导航栏 每个导航下面的 a 链接 鼠标滑上去三角标的样式 */
.header-nav .header-nav-wrapper>li>a:hover span {
  transform: rotate(180deg);
}

/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
.header-nav .header-nav-wrapper>li.active>a {
  color: #9E241D;
  text-decoration: none;
  border-bottom: 2px solid #9E241D;
}

/* 导航栏 每个导航下面的二级导航容器 */
.header-nav .header-nav-wrapper>li>dl {
  display: none;
  position: absolute;
  width: 168px;
  top: 80%;
  left: 0;
  z-index: 999999;
  box-shadow: 0 0 3px 1px #ccc;
  background: #fff;
}

/* 导航栏 每个导航下面的二级导航容器的每个导航 */
#header .header-nav .header-nav-wrapper>li>dl>dt {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

/* 导航栏 每个导航下面的二级导航容器的每个导航 当鼠标滑上时的样式*/
#header .header-nav .header-nav-wrapper>li>dl>dt>a:hover {
  text-decoration: none;
}

/* 导航栏 滑上一级导航显示二级导航 */
#header .header-nav .header-nav-wrapper>li:hover dl {
  display: block;
}

#header .header-nav .header-nav-wrapper>li>dl>dt:hover {
  cursor: pointer;
  background: #ccc;
}

.search {
  width: 260px;
  height: 36px;
  border: solid 1px #eee;
  float: right;
  border-radius: 100px;
  background: #fff;
  margin-top: 37px;
}

.search input {
  width: 205px;
  height: 36px;
  line-height: 36px;
  border: 0;
  float: left;
  border-radius: 100px;
  padding-left: 15px;
}

.search i {
  display: block;
  float: left;
  font-size: 26px;
  margin-top: 4px;
  color: #999;
  cursor: pointer;
}

.search i:hover {
  color: #000;
}

@media screen and (max-width: 997px) {
  #header .header-nav-m {
    position: relative;
  }

  /* 导航栏logo容器 */
  #header .header-nav-m .header-nav-m-logo {
    height: 80px;
    position: relative;
  }

  /* 导航栏logo图片 */
  #header .header-nav-m .header-nav-m-logo img {
    width: 95px;
    height: 45px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  /* 导航栏  菜单容器 */
  #header .header-nav-m .header-nav-m-menu {
    color: #fff;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    background: #474747;
    position: relative;
  }

  /* 导航栏 菜单图标 */
  #header .header-nav-m .header-nav-m-menu-wrapper {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -20px;
    width: 50px;
    height: 40px;
    color: #fff;
    z-index: 999999;
    font-size: 12px;
  }

  /* 导航栏 */
  #header .header-nav-m .header-nav-m-wrapper {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background: #474747;
    z-index: 9999999;
  }

  /* 导航栏 每个导航 */
  #header .header-nav-m .header-nav-m-wrapper>li {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
  }

  /* 导航栏 每个导航下面的 a 链接 */
  #header .header-nav-m .header-nav-m-wrapper>li>a {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 15px 0;
    position: relative;
  }

  /* 导航栏 每个导航下面的 a 链接的右侧小三角 */
  #header .header-nav .header-nav-wrapper>li>a>span {
    font-size: 10px;
  }
}
</style>
