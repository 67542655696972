<template>
  <div id="UserCenter">
    <div class="content-main">
      <!--下半部分-->
      <div class="more-user">
        <!--左边-->
        <div class="mu-left">
          <Left></Left>
        </div>
        <!--右边-->
        <div class="mu-right">
          <div class="mr-title">邀请招标</div>
          <div class="mr-order">
            <el-table
              :data="bidlist"
              style="width: 100%; font-size: 12px"
              :cell-style="{ textAlign: 'center' }"
              :header-cell-style="{ textAlign: 'center' }"
            >
              <el-table-column prop="number" label="项目标题">
                <template slot-scope="scope">
                  <span>{{ scope.row.title }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="money" label="投标日期">
                <template slot-scope="scope">
                  <span>{{ scope.row.bidding_start_time }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="money" label="开标日期">
                <template slot-scope="scope">
                  <span>{{ scope.row.bid_open_time }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="money" label="状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 1">未开始</span>
                  <span v-if="scope.row.status == 2">报名中</span>
                  <span v-if="scope.row.status == 3">报名结束</span>
                  <span v-if="scope.row.status == 4">已开标</span>
                  <span v-if="scope.row.status == 5">已流标</span>
                  <span v-if="scope.row.status == 6">已中标</span>
                </template>
              </el-table-column>
              <el-table-column prop="money" label="操作">
                <template slot-scope="scope">
                  <el-link type="primary" v-if="scope.row.status==2" @click="toTouAiain(scope.row)"
                    >前往投标</el-link
                  >
                  <el-link type="primary" v-else @click="toDetail(scope.row)"
                    >查看详情</el-link
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--分页-->
          <div class="listpage" v-if="bidlist.length > 0">
            <el-pagination
              :current-page="param.page"
              :page-size="param.size"
              layout="prev, pager, next"
              :total="numtotal"
              @current-change="handlePageChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadApi from "../../api/uploadApi";
import BidApi from "../../api/bidApi";
import Left from "../../components/Left.vue";
import UserApi from "../../api/userApi";
export default {
  name: "UserCenter",
  metaInfo() {
    return this.$head("我的投标");
  },
  data() {
    return {
      dataCounts: null,
      isLogin: "",
      bidlist: [],
      param: {
        page: 1,
        size: 20,
      },
      numtotal: null,
      drawer: false,
      qua: [],
      signparam: {
        id: "",
        qua: [],
        files: [""],
        contact: [{ name: "", tel: "" }],
      },
      quaindex:null,
      fileindex: null
    };
  },
  components: {
    Left,
  },
  mounted() {},
  methods: {
    //获取我的投标
    async getMyBid() {
      try {
        let res = await BidApi.inviBid(this.param);
        this.bidlist = res.list;
        this.numtotal = res.total;
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    handlePageChange(page) {
      this.param.page = page;
      this.getMyBid();
    },
    toTouAiain(item) {
      this.$router.push({path:'/bid/detail',query:{id:item.id}})
    },
    toDetail(item){
      this.$router.push({ path: "/bid/detail", query: { id: item.id } });
    }
  },
  async created() {
    this.isLogin = localStorage.getItem("token");
    if (this.isLogin) {
      await this.getMyBid();
    } else {
      this.bidlist = [];
    }
  },
};
</script>
<style>
.signup-main::-webkit-scrollbar {
  display: none !important;
}
.el-drawer__wrapper::-webkit-scrollbar {
  display: none;
}
</style>
<style scoped>
/* 整体盒子 */
#UserCenter {
  width: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}

.content-main {
  width: 1280px;
  height: auto;
  position: relative;
  margin: auto;
  z-index: 2;
  overflow: hidden;
}

.more-user {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 30px;
}

.mu-left {
  width: 300px;
  height: auto;
  overflow: hidden;
  background: #fff;
  float: left;
  padding: 30px 0;
}

.mu-right {
  width: 960px;
  min-height: 800px;
  overflow: hidden;
  background: #fff;
  float: right;
  padding: 30px 0;
}

.mr-title {
  width: 93%;
  height: auto;
  margin: auto;
  border-left: solid 3px #000;
  height: 16px;
  line-height: 16px;
  padding-left: 1%;
}

.mr-title span {
  font-size: 14px;
  color: #999;
}

.mr-order {
  width: 94%;
  height: auto;
  overflow: hidden;
  background: #f9f9f9;
  margin: auto;
  margin-top: 60px;
}
.listpage {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  padding: 20px 0;
}
.signup-main {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 20px;
}
.s-main {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 35px;
}
.s-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-weight: bold;
}
.qua-one {
  width: calc(99% - 40px);
  height: auto;
  overflow: hidden;
  border: solid 1px #f2f2f2;
  margin-top: 20px;
  padding: 20px;
}
.qua-one span {
  float: left;
  display: block;
  color: #666;
}
.c-image {
  display: block;
  float: right;
}
.success-style {
  width: auto;
  font-size: 12px;
  background: green;
  color: #fff;
  padding: 3px 8px;
}
.success-styles {
  width: auto;
  font-size: 12px;
  background: rgb(84, 84, 84);
  color: #fff;
  padding: 3px 8px;
}
.submit-sign {
  width: 200px;
  height: 54px;
  background: #9e241d;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 54px;
  margin-top: 50px;
}
.submit-sign:hover {
  background: #d1372f;
}
.qua-one input {
  display: inline-block;
  padding: 8px 10px;
  margin-right: 15px;
  border: solid 1px #f2f2f2;
}
.que-main {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.que-main textarea {
  display: block;
  width: calc(100% - 20px);
  border: 0;
  background: #eee;
  padding: 10px;
  font-size: 14px;
}
.q-list {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 30px;
}
.q-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 20px;
  border-bottom: dashed 1px #f2f2f2;
  padding-bottom: 20px;
}
.q-question {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.q-question span {
  width: 20px;
  height: 20px;
  background: #04be02;
  color: #fff;
  font-size: 12px;
  text-align: center;
  display: block;
  line-height: 20px;
  float: left;
}
.q-question label {
  display: block;
  width: calc(100% - 30px);
  height: auto;
  float: right;
  font-size: 14px;
  line-height: 22px;
}
.bu-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 10px 0;
  cursor: pointer;
}
.bu-one:hover {
  color: #e52d27;
}
.bu-one span {
  float: right;
  font-size: 14px;
  color: #999;
}
</style>
