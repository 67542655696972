import Base from "./base";

export default class UserApi extends Base {



    //用户注册
    static register(param) {
        return super.post('/companyAccount/register', param)
    }

    //用户登录
    static uLogin(param) {
        return super.post('/companyAccount/login', param)
    }

    //修改密码
    static cPwd(param) {
        return super.post('/companyAccount/updatePassword', param)
    }

    //修改手机号码
    static cPhone(param) {
        return super.post('/companyAccount/modifyPhone', param)
    }


    //找回密码
    static findPwd(param) {
        return super.post('/companyAccount/forgotPassword', param)
    }


    //发送验证码
    static sendCode(param) {
        return super.post('/companyAccount/getCode', param)
    }


    //获取用户信息
    static user(param) {
        return super.get('/companyAccount/info', param)
    }

    //获取用户玩过的游戏
    static gameRecord(param) {
        return super.get('/gameRecord', param)
    }

    //修改密码
    static updatePwd(param) {
        return super.post('/user/updatePwd', param)
    }

    //实名认证
    static realNameAuthentication(param) {
        return super.post('/user/realNameAuthentication', param, false, true)
    }

}
