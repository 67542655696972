import Base from "./base";

export default class NewsApi extends Base {



    //获取栏目列表
    static allCloumn(param) {
        return super.get('/websiteColumn/parent', param)
    }

    //根据栏目ID获取新闻列表
    static allNews(param) {
        return super.get('/websiteColumn/child', param)
    }

    //根据ID获取新闻详情
    static newsDetail(param) {
        return super.get('/websiteColumn/detail', param)
    }

}
