<template>
  <div id="UserCenter">
    <div class="content-main">
      <!--下半部分-->
      <div class="more-user">
        <!--左边-->
        <div class="mu-left">
          <Left></Left>
        </div>
        <!--右边-->
        <div class="mu-right">
          <div class="mr-title">我的询价</div>
          <div class="mr-order">
            <el-table
              :data="inlist"
              style="width: 100%; font-size: 12px"
              :cell-style="{ textAlign: 'center' }"
              :header-cell-style="{ textAlign: 'center' }"
            >
              <el-table-column prop="name" label="项目名称">
                <template slot-scope="scope">
                  <span @click="toInDetail(scope.row)" style="cursor: pointer;">{{scope.row.name}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="offer_time" label="报价时间">
              </el-table-column>
              <el-table-column prop="end_time" label="结束时间">
              </el-table-column>
              <el-table-column prop="status" label="项目状态" width="200">
                <template slot-scope="scope">
                  <span v-if="scope.row.state == 1"
                    >未开始</span
                  >
                  <span v-if="scope.row.state == 2"
                    >报名中</span
                  >
                  <span v-if="scope.row.state == 3"
                    >报名结束</span
                  >
                  <span v-if="scope.row.state == 4"
                    >已开标</span
                  >
                  <span v-if="scope.row.state == 5"
                    >已中标</span
                  >
                  <span v-if="scope.row.state == 6"
                    >已流标</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="status" label="操作" width="200">
                <template slot-scope="scope">
                  <el-link
                    style="margin-right: 20px"
                    v-if="scope.row.state != 2"
                    @click="openIns(scope.row)"
                    type="primary"
                    >查看报价</el-link
                  >
                  <el-link
                    style="margin-right: 20px"
                    v-if="scope.row.state == 2"
                    @click="openIn(scope.row)"
                    type="primary"
                    >修改报价</el-link
                  >
                  <!-- <el-link @click="toBao(scope.row)" type="primary"
                    >重新报价</el-link
                  > -->
                </template>
              </el-table-column>
            </el-table>
            <!--分页-->
            <div class="listpage" v-if="inlist.length > 0">
              <el-pagination
                :current-page="param.page"
                :page-size="param.size"
                layout="prev, pager, next"
                :total="numtotal"
                @current-change="handlePageChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--重新报价-->
    <!--上传招标文件-->
    <el-dialog
      width="70%"
      title=""
      top="8vh"
      :close-on-click-modal="false"
      :wrapperClosable="false"
      :modal="true"
      :visible.sync="drawerinfo"
    >
      <div class="signup-main">
        <!--添加联系人-->
        <div class="s-main">
          <div class="s-title">
            提交报价 - 请填写询价物资单价！
            <span v-if="!look" for="" style="float: right"
              ><UploadExcel :onSuccess="dy" /></span
            ><label v-if="!look" for="" @click="dc">导出报价清单</label>
          </div>

          <div style="margin-top: 20px; width: 98%">
            <el-table :data="inparam" style="width: 100%">
              <el-table-column type="index" label="编号" width="80">
              </el-table-column>
              <el-table-column prop="wuzi" label="名称">
                <template slot-scope="scope">
                  <span>{{ inparam[scope.$index].wuzhi }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="danwei" label="含税单价（元）">
                <template slot-scope="scope">
                  <el-input
                    disabled
                    style="width: 90%"
                    type="number"
                    v-model="inparam[scope.$index].tax_inclusive_price"
                    placeholder="请输入含税单价"
                  />
                </template>
              </el-table-column>
              <el-table-column prop="danwei" label="不含税单价（元）">
                <template slot-scope="scope">
                  <el-input
                    :disabled="look"
                    style="width: 90%"
                    type="number"
                    v-model="inparam[scope.$index].price_excluding_tax"
                    placeholder="请输入不含税单价"
                    @blur="changePrice"
                  />
                </template>
              </el-table-column>
              <el-table-column prop="danwei" label="税率（%）">
                <template slot-scope="scope">
                  <el-input
                    :disabled="look"
                    style="width: 90%"
                    type="number"
                    min="0"
                    v-model="inparam[scope.$index].tax_rate"
                    placeholder="请输入税率"
                    @blur="changePrice"
                  />
                </template>
              </el-table-column>
              <el-table-column prop="danwei" label="发票类型">
                <template slot-scope="scope">
                  <el-input
                    :disabled="look"
                    style="width: 90%"
                    type="text"
                    v-model="inparam[scope.$index].type"
                    placeholder="请输入发票类型"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="s-main" v-if="!look">
            <div class="s-title">投标文件 - 必传项</div>
            <div class="qua-one" v-for="(item, index) in files" :key="index">
              <span style="font-size: 14px"
                >{{
                  item
                    ? item.substring(item.lastIndexOf("/") + 1)
                    : "请上传投标文件"
                }}<i
                  style="padding-left: 20px; cursor: pointer"
                  @click="delFiles(index)"
                  class="el-icon-circle-close"
                  v-if="index > 0 && item"
                ></i
              ></span>
              <div @click="uploadindexs(index)">
                <el-upload
                  class="c-image"
                  action=""
                  :on-change="upMemEntrImages"
                  :show-file-list="false"
                  :auto-upload="false"
                >
                  <i
                    class="el-icon-plus el-icon-circle-plus"
                    style="font-size: 24px"
                  ></i>
                </el-upload>
              </div>
            </div>
            <el-link type="primary" @click="addFiles" style="margin-top: 20px" v-if="!look"
              >添加投标文件</el-link
            >
          </div>


        <div class="submit-sign" @click="submitSign" v-if="!look">提交投标</div>
      </div>
    </el-dialog>
    <!--报价详情-->
    <!-- <el-drawer
      title="报价详情"
      :wrapperClosable="false"
      :modal="true"
      size="50%"
      :visible.sync="drawerinfo"
      direction="rtl"
    >
      <div class="signup-main">
        <div class="s-main">
          <div class="s-title">以下是您的报价详情，如需重新报价请关闭此弹窗点击重新报价！</div>
          <div class="in-one" v-for="(item, index) in inqdetails" :key="index">
            <div class="in-title">物资名称：{{ item.name}}，数量：{{item.quantity+item.company}}，规格：{{item.specifications}}</div>
            <div class="in-desc">
              <div>
                <span>不含税价</span
                ><input
                  type="number"
                  disabled
                  v-model="item.price_excluding_tax"
                  placeholder="请输入不含税价"
                />
              </div>
              <div>
                <span>含税价</span
                ><input
                  type="number"
                  disabled
                  v-model="item.tax_inclusive_price"
                  placeholder="请输入含税价"
                />
              </div>
              <div>
                <span>税率(%)</span
                ><input
                  type="number"
                  disabled
                  v-model="item.tax_rate"
                  placeholder="请输入税率"
                />
              </div>
              <div>
                <span>发票类型</span
                ><input
                  type="text"
                  disabled
                  v-model="item.type"
                  placeholder="请输入发票类型"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer> -->
  </div>
</template>
<script>
import BidApi from "../../api/bidApi";
import Left from "../../components/Left.vue";
import UploadExcel from "../../components/UploadExcel";
import UploadApi from "../../api/uploadApi";
import ConsultApi from "../../api/consultApi";
export default {
  name: "UserCenter",
  metaInfo() {
    return this.$head("我的询价");
  },
  data() {
    return {
      dataCounts: null,
      param: {
        page: 1,
        size: 20,
      },
      isLogin: "",
      inlist: [],
      page: 1,
      size: 10,
      cshow: false,
      replay: "",
      dict: {
        0: {
          label: "未启用",
        },
        1: {
          label: "未开始",
        },
        2: {
          label: "询价中",
        },
        3: {
          label: "询价结束",
        },
        4: {
          label: "已开标",
        },
        5: {
          label: "已流标",
        },
      },
      drawer: false,
      inparam: [],
      biddetail: {},
      drawerinfo: false,
      inqdetails: [],
      look: false,
      fileindex: null,
      files:[]
    };
  },
  watch: {
    drawerinfo(n) {
      if (!n) {
        setTimeout(()=>{
          this.look = false;
        },200)
      }
    },
  },
  components: {
    Left,
    UploadExcel,
  },
  mounted() {},
  methods: {

    delFiles(index) {
      if (index == 0) {
        this.$message.error("请至少添加一个附件");
      } else {
        this.files.splice(index, 1);
      }
    },

    uploadindexs(index) {
      this.fileindex = index;
      console.log(index);
    },

    addFiles() {
      this.files.push("");
    },

    async upMemEntrImages(file) {
      try {
        let res = await UploadApi.uFiles({
          file: file.raw,
          e: 1,
        });
        this.$set(this.files, this.fileindex, res);
        // this.signparam.files[this.fileindex] = res;
      } catch (e) {
        this.$message.error(e.message);
      }
    },


    changePrice() {
      for (let i = 0; i < this.inparam.length; i++) {
        if (this.inparam[i].price_excluding_tax && this.inparam[i].tax_rate) {
          this.inparam[i].tax_inclusive_price = (this.inparam[i].price_excluding_tax * (1 + this.inparam[i].tax_rate / 100)).toFixed(2);
        }
      }
    },
    //导入报价
    dy(results) {
      for (let i = 0; i < results.results.length; i++) {
        for (let j = 0; j < this.inparam.length; j++) {
          if (
            results.results[i].id ==this.inparam[j].inquiry_materials_id){
            this.inparam[j].tax_inclusive_price = (results.results[i].不含税价 * (1 + results.results[i].税率 / 100)).toFixed(2);
            this.inparam[j].price_excluding_tax = results.results[i].不含税价;
            this.inparam[j].tax_rate = results.results[i].税率;
            this.inparam[j].type = results.results[i].发票类型;
          }
        }
      }
    },
    //导出报价
    dc() {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "id",
          "名称",
          "不含税价",
          "税率",
          "发票类型",
        ];
        const filterVal = [
          "inquiry_materials_id",
          "wuzhi",
          "tax_inclusive_price",
          "tax_rate",
          "type",
        ];
        const data = this.formatJson(filterVal, this.inparam);
        excel.export_json_to_excel({
          header: tHeader, //表头 必填
          data, //具体数据 必填
          filename: "询价清单", //非必填
          autoWidth: true, //非必填
          bookType: "xlsx", //非必填
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    //获取报价详情
    async getIndetail(id) {
      try {
        let res = await BidApi.inDetailinfo({
          id: id,
        });
         this.files = res.files
        res = res.offer
        this.inparam = [];
        for (let i = 0; i < res.length; i++) {
          this.inparam.push({
            inquiry_materials_id: res[i].id.toString(),
            wuzhi: res[i].name,
            price_excluding_tax: res[i].price_excluding_tax,
            tax_inclusive_price: res[i].tax_inclusive_price,
            tax_rate: res[i].tax_rate,
            quantity: res[i].quantity,
            type: res[i].type,
          });
        }
        // this.inqdetails = res
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    async openIn(item) {
      await this.getIndetail(item.id);
      this.drawerinfo = true;
    },
    async openIns(item) {
      this.look = true;
      await this.getIndetail(item.id);
      this.drawerinfo = true;
    },
    //查询所有问题列表
    async getAll() {
      try {
        let res = await BidApi.myIn(this.param);
        this.inlist = res.list;
        this.numtotal = res.total;
      } catch (e) {
        this.$message.error(e);
      }
    },
    handlePageChange(page) {
      this.param.page = page;
      this.getAll();
    },
    againQue() {
      location.href = "/GameService";
    },
    toBao(item) {
      if (item.state == 2) {
        this.$confirm("重新报价将覆盖之前的报价数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          await this.getDetail(item.id);
          this.drawer = true;
        });
      } else {
        this.$message.error("已过询价期，不能再次报价");
      }
    },
    toInDetail(item){
      this.$router.push({ path: "/inquiry/detail", query: { id: item.id } });
    },
    checkData() {
      for (let i = 0; i < this.inparam.length; i++) {
        for (let key in this.inparam[i]) {
          if (this.inparam[i][key] == "" || this.inparam[i][key] == null) {
            this.$message.error(
              "请完善物资" + this.inparam[i].wuzhi + "的询价表单"
            );
            return;
          }
        }
      }
      return true;
    },
    //获取招标详情
    async getDetail(id) {
      try {
        let res = await BidApi.inDetail({
          id: id,
        });
        this.biddetail = res;
        // for (let i = 0; i < res.materials.length; i++) {
        //   this.inparam.push({
        //     inquiry_materials_id: (res.materials[i].id).toString(),
        //     wuzhi:res.materials[i].name,
        //     price_excluding_tax: "",
        //     tax_inclusive_price: "",
        //     tax_rate: "",
        //     quantity: res.materials[i].quantity,
        //     type: "",
        //   });
        // }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    async submitSign() {
      //检测是否有询价未上传
      if (this.checkData()) {
        try {
          let from = {
            inparam:this.inparam,
            files:this.files
          }
          await BidApi.inSubmit(from);
          this.$message.success("报价成功");
          this.drawerinfo = false;
          this.inparam = [];
          this.files = []
          await this.getAll();
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },
  },
  async created() {
    this.isLogin = localStorage.getItem("token");
    if (this.isLogin) {
      await this.getAll();
    } else {
      this.uQue = [];
    }
  },
};
</script>
<style>
.el-dialog {
  margin-top: 8vh !important;
}
.signup-main::-webkit-scrollbar {
  display: none !important;
}
.el-drawer__wrapper::-webkit-scrollbar {
  display: none;
}
</style>
<style type="text/css" scoped>
.el-input__inner {
  width: 100px !important;
}
</style>
<style scoped>
/* 整体盒子 */
#UserCenter {
  width: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}

.content-main {
  width: 1280px;
  height: auto;
  position: relative;
  margin: auto;
  z-index: 2;
  overflow: hidden;
}
.s-title label {
  display: block;
  float: right;
  margin-left: 20px;
  color: #fff;
  background: #e52d27;
  cursor: pointer;
  border-radius: 3px;
  padding: 6px 15px;
  font-size: 12px;
}
.more-user {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 30px;
}

.mu-left {
  width: 300px;
  height: auto;
  overflow: hidden;
  background: #fff;
  float: left;
  padding: 30px 0;
}

.mu-right {
  width: 960px;
  min-height: 800px;
  overflow: hidden;
  background: #fff;
  float: right;
  padding: 30px 0;
}

.mr-title {
  width: 93%;
  height: auto;
  margin: auto;
  border-left: solid 3px #000;
  height: 16px;
  line-height: 16px;
  padding-left: 1%;
}

.mr-title span {
  font-size: 14px;
  color: #999;
}

.mr-order {
  width: 94%;
  height: auto;
  overflow: hidden;
  margin: auto;
  margin-top: 60px;
}
.order-color {
  width: 94%;
  height: auto;
  overflow: hidden;
  margin: auto;
  margin-top: 30px;
  border-bottom: solid 1px #eee;
  padding-bottom: 20px;
}
.oc-one {
  width: auto;
  float: left;
  margin-right: 25px;
}
.oc-one label {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: #eee;
  display: block;
  float: left;
}
.oc-one span {
  display: inline-block;
  height: 14px;
  font-size: 14px;
  line-height: 14px;
  display: block;
  float: left;
  margin-left: 8px;
}
.listpage {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  padding: 20px 0;
}
.signup-main {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 20px;
}
.s-main {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 35px;
}
.s-title {
  width: 98%;
  height: auto;
  overflow: hidden;
  font-weight: bold;
}
.submit-sign {
  width: 200px;
  height: 54px;
  background: #9e241d;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 54px;
  margin-top: 50px;
}
.submit-sign:hover {
  background: #d1372f;
}
.in-one {
  width: calc(99% - 40px);
  height: auto;
  overflow: hidden;
  padding: 20px;
  border: solid 1px #f2f2f2;
  margin-top: 30px;
}
.in-title {
  width: 100%;
  height: auto;
  padding-bottom: 15px;
  font-weight: bold;
}
.in-desc {
  width: 100%;
  height: auto;
  display: flex;
}
.in-desc > div {
  flex: 1;
}
.in-desc > div span {
  display: block;
  font-size: 14px;
  color: #999;
  padding-bottom: 15px;
}
.in-desc > div input {
  display: block;
  width: 80%;
  padding: 10px;
  border: solid 1px #eee;
  
}


.qua-one {
  width: calc(99% - 40px);
  height: auto;
  overflow: hidden;
  border: solid 1px #f2f2f2;
  margin-top: 20px;
  padding: 15px 10px;
}

.qua-one span {
  float: left;
  display: block;
  color: #666;
  margin-top: 3px;
}

.c-image {
  display: block;
  float: right;
}
</style>
