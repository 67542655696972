<template>
  <div id="UserCenter">
    <div class="content-main">
      <!--下半部分-->
      <div class="more-user">
        <!--左边-->
        <div class="mu-left">
          <Left></Left>
        </div>
        <!--右边-->
        <div class="mu-right">
          <div class="mr-title">
            我的企业
            <label for="" @click="openAdd" v-if="companylist.length < 1">添加企业</label>
          </div>
          <div class="mr-order">
            <el-table :data="companylist" style="width: 100%; font-size: 12px">
              <el-table-column prop="number" label="企业名称">
                <template slot-scope="scope">
                  <label>
                    <el-tooltip class="item" effect="dark" content="该企业已被列入黑名单" placement="top"><b
                        v-if="scope.row.black" class="black-com">黑</b></el-tooltip>
                    <el-tooltip class="item" effect="dark" content="该企业已被选为优质供应商" placement="top"><b class="good-com"
                        v-if="scope.row.high">优</b></el-tooltip>{{ scope.row.name }}
                  </label>
                </template>
              </el-table-column>
              <el-table-column prop="money" label="企业类型">
                <template slot-scope="scope">
                  <label v-for="(item, index) in scope.row.type" :key="index">{{ item.name
                  }}<span v-if="scope.row.type.length > 1"> | </span></label>
                </template>
              </el-table-column>
              <el-table-column prop="money" label="企业法人">
                <template slot-scope="scope">
                  <label>{{ scope.row.legal_person }}</label>
                </template>
              </el-table-column>
              <el-table-column prop="money" label="企业状态">
                <template slot-scope="scope">
                  <label>{{ scope.row.status.label }}
                    <el-tooltip class="item" effect="dark" :content="scope.row.memo" placement="top"><i
                        v-if="scope.row.status.label == '已拒绝'" class="el-icon-warning"></i></el-tooltip>
                  </label>
                </template>
              </el-table-column>
              <el-table-column prop="money" label="审核意见">
                <template slot-scope="scope">
                  <label>{{ scope.row.memo }}</label>
                </template>
              </el-table-column>
              <el-table-column prop="money" label="添加时间">
                <template slot-scope="scope">
                  <label>{{ scope.row.created_at }}</label>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="操作" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.status.value != 1" style="
                      display: inline-block;
                      color: #000;
                      border-radius: 6px;
                      font-size: 12px;
                      padding: 2px 10px;
                      cursor: pointer;
                    " @click="toEdit(scope.row)"><i class="el-icon-edit"></i> 编辑资料</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!--添加企业-->
    <el-dialog title="添加我的企业" :visible.sync="dialogVisible" :fullscreen="true" :append-to-body="true" :modal="false"
      top="0" width="30%">
      <div class="add-com-main">
        <!--左边基础资料-->
        <div class="ac-left">
          <div class="acl-title">| 基础资料<span> - 带 * 为必填项</span></div>
          <div class="acl-one">
            <span>企业名称：</span>
            <input type="text" :disabled="suatus==3" v-model="param.name" placeholder="请输入企业名称" />
            <i>*</i>
          </div>
          <div class="acl-one">
            <span>联系地址：</span>
            <input type="text" v-model="param.address" placeholder="请输入联系地址" />
          </div>
          <div class="acl-one">
            <span>公司电话：</span>
            <input type="text" v-model="param.tel" placeholder="请输入公司电话" />
          </div>
          <div class="acl-one">
            <span>公司传真：</span>
            <input type="text" v-model="param.fax" placeholder="请输入公司传真" />
          </div>
          <div class="acl-one">
            <span>注册金额(万)：</span>
            <input type="text" v-model="param.register_money" placeholder="请输入注册金额 ( 万 )" />
          </div>
          <div class="acl-one">
            <span>主要供货类型：</span>
            <input type="text" v-model="param.supply_type" placeholder="请输入主要供货类型" />
          </div>
          <div class="acl-one">
            <span>公司法人：</span>
            <input type="text" v-model="param.legal_person" placeholder="请输入公司法人" />
            <i>*</i>
          </div>
          <div class="acl-one">
            <span>法人电话：</span>
            <input type="text" v-model="param.corporate_tel" placeholder="请输入法人电话" />
            <i>*</i>
          </div>
          <div class="acl-one">
            <span>营业期限开始：</span>
            <el-date-picker format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" v-model="param.term_start" type="date"
              placeholder="选择日期">
            </el-date-picker>
            <i>*</i>
          </div>
          <div class="acl-one">
            <span>营业期限结束：</span>
            <el-date-picker format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" v-model="param.term_end" type="date"
              placeholder="选择日期">
            </el-date-picker>
            <i>*</i>
            <el-checkbox style="margin-left: 3px" v-model="checked">长期</el-checkbox>
          </div>
          <div class="acl-one">
            <span>社会统一代码：</span>
            <input type="text" v-model="param.code" placeholder="请输入社会统一代码" />
            <i>*</i>
          </div>
          <div class="acl-one">
            <span>供应商类型：</span>
            <el-select v-model="param.tid" multiple collapse-tags placeholder="请选择">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <i>*</i>
          </div>
          <div class="acl-one">
            <span>营业执照：</span>
            <el-upload class="c-image" action="" :on-change="upMemEntrImages" :show-file-list="false"
              :auto-upload="false">
              <img v-if="param.charter" :src="param.charter" class="avatar" style="width: 94px; height: 94px" />
              <i v-else class="el-icon-plus el-icon-circle-plus" style="font-size: 24px; padding-top: 8px"></i>
            </el-upload>
            <i>*</i>
          </div>
          <div class="acl-one">
            <span>安全生产许可证：</span>
            <el-upload class="c-image" action="" :on-change="upMemEntrImagess" :show-file-list="false"
              :auto-upload="false">
              <img v-if="param.security" :src="param.security" class="avatar" style="width: 94px; height: 94px" />
              <i v-else class="el-icon-plus el-icon-circle-plus" style="font-size: 24px; padding-top: 8px"></i>
            </el-upload>
          </div>
          <div class="acl-ones">
            <span>经营范围：</span>
            <textarea placeholder="请输入经营范围" v-model="param.range"></textarea>
          </div>
        </div>
        <!--右边资质资料-->
        <div class="ac-right">
          <div class="acl-title">
            | 资质证书 <span>- 非必填项，如有请准确添加证书内容</span>
          </div>
          <div class="acr-main">
            <!--证书文件-->
            <div class="certificate" v-for="(item, index) in param.auerbachs" :key="index">
              <div class="del-cer" @click="delCer(index)">×</div>
              <div @click="imageNum(index)">
                <el-upload class="c-image" action="" :on-change="upMemEntrImage" :show-file-list="false"
                  :auto-upload="false">
                  <img v-if="param.auerbachs[index].url" :src="param.auerbachs[index].url" class="avatar"
                    style="width: 94px; height: 94px" />
                  <i v-else class="el-icon-plus el-icon-circle-plus" style="font-size: 24px; padding-top: 8px"></i>
                </el-upload>
              </div>
              <div class="c-info">
                <input type="text" v-model="param.auerbachs[index].name" placeholder="请输入证书名称" />
                <input type="text" v-model="param.auerbachs[index].type" placeholder="请输入证书类型" />
                <input type="text" v-model="param.auerbachs[index].institutions" placeholder="请输入认证机构" />
                <input type="text" v-model="param.auerbachs[index].no" placeholder="请输入认证编号" />
                <el-date-picker style="width: 30%; margin-left: 5%" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                  v-model="param.auerbachs[index].effect_time" type="date" placeholder="选择日期">
                </el-date-picker>
                <el-date-picker style="width: 30%; float: right" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                  v-model="param.auerbachs[index].failure_time" type="date" placeholder="选择日期">
                </el-date-picker>
              </div>
            </div>
            <div class="acr-add" @click="addCer">
              <span>+</span><label for="">添加证书</label>
            </div>
          </div>
        </div>
        <!--提交按钮-->
        <div class="submit-btn">
          <span @click="rInput">重置表单</span>
          <span @click="submitForm">{{
          isEdit ? "更新资料" : "提交审核"
          }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import TEST from "../../util/index";
import CompanyApi from "../../api/companyApi";
import UploadApi from "../../api/uploadApi";
import Left from "../../components/Left.vue";
import UserApi from "../../api/userApi";
export default {
  name: "UserCenter",
  metaInfo() {
    return this.$head("我的企业");
  },
  data() {
    return {
      suatus: '',
      checked: false,
      dialogVisible: false,
      companylist: [],
      options: [],
      value: "",
      dataCounts: null,
      isLogin: "",
      ugamelist: [],
      param: {
        name: "", //公司名称
        address: "", //联系地址
        tel: "", //公司电话
        fax: "", //公司传真
        is_long: 0,
        register_money: "", //注册金额(万)
        range: "", //经营范围
        supply_type: "", //主要供货类型
        legal_person: "", //公司法人
        corporate_tel: "", //法人电话
        term_start: "", //营业期限开始
        term_end: "", //营业期限结束
        code: "", //社会信用统一代码
        charter: "",
        security: '',
        tid: [],
        auerbachs: [],
      },
      memberNum: null,
      disc: {
        name: "证书名称",
        type: "证书类型",
        institutions: "认证机构",
        no: "认证编号",
        effect_time: "生效时间",
        failure_time: "失效时间",
        url: "证书图片",
      },
      isEdit: false,
    };
  },
  components: {
    Left,
  },
  mounted() { },
  watch: {
    checked(n) {
      if (!n) {
        this.param.is_long = 0;
      } else {
        this.param.is_long = 1;
        this.param.term_end = "";
      }
    },
  },
  methods: {
    async toEdit(item) {
      await this.getComtype();
      await this.getComInfo(item.id);
      this.param.id = item.id;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    //获取企业详情
    async getComInfo(id) {
      try {
        let res = await CompanyApi.comDetail({ id: id });
        if (res.is_long == 1) {
          this.checked = true;
        }
        this.suatus = res.status.value
        //复制到编辑
        this.param.name = res.name;
        this.param.address = res.address;
        this.param.tel = res.tel;
        this.param.fax = res.fax;
        this.param.register_money = res.register_money;
        this.param.range = res.range;
        this.param.security = res.security;
        this.param.supply_type = res.supply_type;
        this.param.legal_person = res.legal_person;
        this.param.corporate_tel = res.corporate_tel;
        this.param.term_start = res.term_start;
        this.param.term_end = res.term_end;
        this.param.code = res.code;
        this.param.is_long = res.is_long;
        this.param.charter = res.charter;
        this.param.tid = [];
        for (let j = 0; j < res.type.length; j++) {
          this.param.tid.push(res.type[j].id);
        }
        if (res.accessory.length > 0) {
          this.param.auerbachs = [];
          for (let i = 0; i < res.accessory.length; i++) {
            this.param.auerbachs.push({
              url: res.accessory[i].accessory.url,
              name: res.accessory[i].name,
              type: res.accessory[i].type,
              institutions: res.accessory[i].institutions,
              no: res.accessory[i].no,
              effect_time: res.accessory[i].effect_time,
              failure_time: res.accessory[i].failure_time,
            });
          }
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    //查询所有企业
    async getList() {
      try {
        let res = await CompanyApi.myCompany();
        this.companylist = res;
      } catch (e) {
        this.$message.error(e.message);
      }
    },
    async openAdd() {
      if (this.companylist.length >= 1) {
        this.$message.error("只能添加一个企业");
      } else {
        this.dialogVisible = true;
        await this.getComtype();
      }
    },
    //获取所有供应商类型
    async getComtype() {
      try {
        let res = await CompanyApi.comType();
        this.options = res;
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    //添加证书
    addCer() {
      this.param.auerbachs.push({
        url: "", //图片地址
        name: "", //认证类型
        type: "", //认证机构
        institutions: "", //认证名称
        no: "", //认证编号
        effect_time: "", //生效时间
        failure_time: "", //失效时间
      });
    },
    imageNum(index) {
      this.memberNum = index;
    },
    async upMemEntrImages(file) {
      let pnglist = ['JPG', 'jpg', 'jpeg', 'png', 'PNG', 'gif', 'GIF', 'PNG']
      let fname = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (pnglist.indexOf(fname) > -1) {
        try {
          let res = await UploadApi.uFiles({
            file: file.raw
          });
          this.param.charter = res;
        } catch (e) {
          this.$message.error(e.message);
        }
      } else {
        this.$message.error('仅支持PNG,JPG,GIF格式文件')
      }

    },
    async upMemEntrImagess(file) {
      let pnglist = ['JPG', 'jpg', 'jpeg', 'png', 'PNG', 'gif', 'GIF', 'PNG']
      let fname = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (pnglist.indexOf(fname) > -1) {
        try {
          let res = await UploadApi.uFiles({
            file: file.raw
          });
          this.param.security = res;
        } catch (e) {
          this.$message.error(e.message);
        }
      } else {
        this.$message.error('仅支持PNG,JPG,GIF格式文件')
      }
    },
    async upMemEntrImage(file) {
      let pnglist = ['JPG', 'jpg', 'jpeg', 'png', 'PNG', 'gif', 'GIF', 'PNG']
      let fname = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (pnglist.indexOf(fname) > -1) {
        try {
          let res = await UploadApi.uFiles({
            file: file.raw
          });
          this.param.auerbachs[this.memberNum].url = res;
        } catch (e) {
          this.$message.error(e.message);
        }
      } else {
        this.$message.error('仅支持PNG,JPG,GIF格式文件')
      }
    },
    delCer(index) {
      this.param.auerbachs.splice(index, 1);
    },
    rInput() {
      Object.keys(this.param).forEach((key) => (this.param[key] = ""));
      this.param.tid = [];
      this.param.auerbachs = [];
      console.log(this.param);
    },
    async submitForm() {
      if (!this.param.name) {
        this.$message.error("请输入企业名称");
      } else if (!this.param.legal_person) {
        this.$message.error("请输入企业法人");
      }else if(!TEST.chinese(this.param.legal_person)){
        this.$message.error("法人姓名只能为中文");
      } else if (!this.param.corporate_tel) {
        this.$message.error("请输入法人电话");
      } else if (!TEST.mobile(this.param.corporate_tel)) {
        this.$message.error("法人电话号码格式错误");
      } else if (!this.param.term_start) {
        this.$message.error("请选择企业营业开始时间");
      } else if (this.param.is_long == 0 && !this.param.term_end) {
        this.$message.error("请选择企业营业结束时间");
      } else if (!this.param.code) {
        this.$message.error("请输入社会信用统一代码");
      } else if (this.param.tid.length == 0) {
        this.$message.error("请选择企业类型");
      } else if (!this.param.charter) {
        this.$message.error("请上传企业营业执照");
      } else {
        //检测是否上传了证书
        if (this.param.auerbachs.length > 0) {
          for (let i = 0; i < this.param.auerbachs.length; i++) {
            for (let key in this.param.auerbachs[i]) {
              if (this.param.auerbachs[i][key] == "") {
                this.$message.error(
                  "请完善证书" + (i + 1) + "的" + this.disc[key] + "信息"
                );
                return;
              }
            }
          }
        }
        if (!this.isEdit) {
          try {
            await CompanyApi.createCom(this.param);
            this.$message.success("添加成功，等待审核");
            this.dialogVisible = false;
            await this.getList();
          } catch (error) {
            this.$message.error(error.message);
          }
        } else {
          //编辑
          console.log(this.param)
          try {
            await CompanyApi.updateCom(this.param);
            this.$message.success("编辑成功");
            this.dialogVisible = false;
            await this.getList();
          } catch (error) {
            this.$message.error(error.message);
          }
        }
      }
    },
  },
  async created() {
    if (localStorage.getItem("token")) {
      await this.getList();
    }
  },
};
</script>
<style>
.el-dialog.is-fullscreen {
  margin-top: 0vh !important;
  border: 10px solid #c4c4c4;
}

.el-dialog {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05) !important;
  margin-top: 0vh !important;
  z-index: 11 !important;
  border-radius: 0px !important;
}

.el-dialog::-webkit-scrollbar {
  display: none;
}
</style>
<style scoped>
/* 整体盒子 */
#UserCenter {
  width: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}

.content-main {
  width: 1280px;
  height: auto;
  position: relative;
  margin: auto;
  z-index: 2;
  padding-top: 20px;
  overflow: hidden;
}

.more-user {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin-bottom: 30px;
}

.mu-left {
  width: 300px;
  height: auto;
  overflow: hidden;
  background: #fff;
  float: left;
  padding: 30px 0;
}

.mu-right {
  width: 960px;
  min-height: 800px;
  overflow: hidden;
  background: #fff;
  float: right;
  padding: 30px 0;
}

.mr-title {
  width: 93%;
  height: auto;
  margin: auto;
  border-left: solid 3px #000;
  height: 16px;
  line-height: 16px;
  padding-left: 1%;
}

.mr-title span {
  font-size: 14px;
  color: #999;
}

.mr-title label {
  display: inline-block;
  float: right;
  background: #9e241d;
  color: #fff;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.mr-title label:hover {
  background: #de342b;
}

.mr-order {
  width: 94%;
  height: auto;
  overflow: hidden;
  background: #f9f9f9;
  margin: auto;
  margin-top: 60px;
}

.cell>span:hover {
  color: #e52d27 !important;
}

.order-color {
  width: 94%;
  height: auto;
  overflow: hidden;
  margin: auto;
  margin-top: 30px;
  border-bottom: solid 1px #eee;
  padding-bottom: 20px;
}

.oc-one {
  width: auto;
  float: left;
  margin-right: 25px;
}

.oc-one label {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: #eee;
  display: block;
  float: left;
}

.oc-one span {
  display: inline-block;
  height: 14px;
  font-size: 14px;
  line-height: 14px;
  display: block;
  float: left;
  margin-left: 8px;
}

.ac-left {
  width: 50%;
  min-height: 80vh;
  overflow: hidden;
  float: left;
  border-right: solid 1px #f2f2f2;
}

.acl-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-weight: bold;
  color: #000;
  margin-bottom: 30px;
}

.acl-title span {
  color: #ccc;
}

.acl-one {
  width: 45%;
  height: auto;
  overflow: hidden;
  float: left;
  margin-bottom: 30px;
}

.acl-one:nth-child(2n-1) {
  float: right;
}

.acl-one span {
  width: 120px;
  display: block;
  float: left;
  text-align: right;
  padding-top: 10px;
}

.acl-one input {
  display: block;
  width: calc(100% - 180px);
  float: left;
  background: #fefefe;
  border: solid 1px #eee;
  padding: 12px 0 12px 10px;
  border-radius: 5px;
}

.acl-one i {
  display: inline-block;
  font-style: normal;
  color: #c00;
  padding-top: 10px;
  padding-left: 5px;
}

.acl-ones {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 30px;
}

.acl-ones span {
  width: 120px;
  display: block;
  float: left;
  text-align: right;
  padding-top: 10px;
}

.acl-ones textarea {
  display: block;
  width: calc(100% - 180px);
  float: left;
  min-height: 220px;
  background: #fefefe;
  border: solid 1px #eee;
  padding: 12px 0 12px 10px;
  border-radius: 5px;
}

.ac-right {
  width: 47%;
  min-height: 80vh;
  overflow: hidden;
  float: left;
  margin-left: 2%;
}

.add-com-main {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}

.submit-btn {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 40px;
}

.submit-btn span {
  background: #9e241d;
  color: #fff;
  letter-spacing: 2px;
  font-size: 14px;
  padding: 12px 25px;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}

.submit-btn span:first-child {
  margin-right: 40px;
  background: #444;
}

.submit-btn span:first-child:hover {
  background: #333;
}

.submit-btn span:hover {
  background: #bd2f27;
}

.acr-add {
  width: 100px;
  height: auto;
  padding: 10px 0;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  margin: auto;
  margin-top: 50px;
  background: #f2f2f2;
  border: solid 1px #eee;
  text-align: center;
  position: relative;
  z-index: 99;
}

.acr-add span {
  display: block;
  cursor: pointer;
}

.acr-add label {
  display: block;
  font-size: 12px;
  cursor: pointer;
}

.acr-add:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}

.certificate {
  width: 95%;
  height: 105px;
  border: solid 1px #eee;
  padding: 2% 2% 1% 2%;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;
}

.certificate:hover {
  background: #f9f9f9;
}

.c-image {
  width: 94px;
  height: 94px;
  float: left;
  border: solid 1px #eee;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
  line-height: 100px;
  color: #999;
  background-color: #f9f9f9;
}

.c-info {
  width: calc(100% - 120px);
  float: right;
}

.c-info input {
  display: block;
  float: left;
  width: 28%;
  padding: 12px 0 12px 2%;
  margin-bottom: 15px;
  border-radius: 6px;
  border: solid 1px #eee;
}

.c-info input:nth-child(3n) {
  float: right;
}

.c-info input:nth-child(3n-1) {
  margin-left: 5%;
}

.del-cer {
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 15px;
  background: #333;
  position: absolute;
  right: -8px;
  top: -8px;
  border-radius: 100px;
  color: #fff;
  font-size: 12px;
}

.del-cer:hover {
  background: #9e241d;
}

.black-com {
  display: inline-block;
  background: #000;
  margin-right: 5px;
  color: #fff;
  font-size: 12px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 16px;
  border-radius: 2px;
  cursor: pointer;
}

.good-com {
  display: inline-block;
  background: green;
  margin-right: 5px;
  color: #fff;
  font-size: 12px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 16px;
  border-radius: 2px;
  cursor: pointer;
}
</style>
