<template>
    <div id="HomePage">
        <!--招标信息-->
        <div class="block-place">
            <!--上传招标文件-->
            <el-dialog width="80%" :title="biddetail.title" top="8vh" :close-on-click-modal="false" :wrapperClosable="false" :modal="true" :visible.sync="drawer">
                <div class="signup-main">
                    <!--资格文件上传-->
                    <div class="s-main">
                        <!-- <div class="s-title">资格文件上传 - 必传项</div> -->
                        <!-- <div class="qua-one" v-for="(item, index) in qua" :key="index">
              <span>
                <label class="success-style" v-if="signparam.qua[index][item.ident]">已上传</label>
                <label class="success-styles" v-else>未上传</label>
                {{ item.name }}</span>
              <div @click="uploadindex(index)">
                <el-upload class="c-image" action="" :on-change="upMemEntrImage" :show-file-list="false"
                  :auto-upload="false">
                  <i class="el-icon-plus el-icon-circle-plus" style="font-size: 24px"></i>
                </el-upload>
              </div>
            </div> -->
                    </div>
                    <!--其他评分法-->
                    <div class="s-mains">
                        <div class="s-title">
                            报价清单
                            <span for="" style="float: right">
                                <UploadExcel :onSuccess="dy" />
                            </span>
                            <label for="" @click="dc">导出报价清单</label>
                        </div>
                        <div style="margin-top: 20px">
                            <el-table :data="signparam.bao" style="width: 100%">
                                <el-table-column type="index" label="编号" width="80"></el-table-column>
                                <el-table-column prop="wuzi" label="名称">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="danwei" label="单位" width="70">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.danwei }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="danwei" label="数量" width="70">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.shuliang }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="guige" label="规格(特征描述，参数说明)">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.guige }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="danwei" label="控制价不含税单价（元）">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.nomore }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="danwei" label="不含税单价（元）">
                                    <template slot-scope="scope">
                                        <el-input style="width: 90%" type="number" @blur="changePrice" v-model="signparam.bao[scope.$index].unprice" placeholder="请输入不含税单价" />
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column prop="danwei" label="含税单价（元）">
                  <template slot-scope="scope">
                    <el-input
                      disabled
                      style="width: 90%"
                      type="number"
                      v-model="signparam.bao[scope.$index].inprice"
                      placeholder="该列自动计算"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="danwei"
                  label="税率（纯数字，不填写%号）"
                >
                  <template slot-scope="scope">
                    <el-input
                      style="width: 90%"
                      type="number"
                      @blur="changePrice"
                      v-model="signparam.bao[scope.$index].taxrate"
                      placeholder="请输入税率"
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="danwei" label="发票类型">
                  <template slot-scope="scope">
                    <el-input
                      style="width: 90%"
                      type="text"
                      v-model="signparam.bao[scope.$index].invoicetype"
                      placeholder="请输入发票类型"
                    />
                  </template>
                </el-table-column> -->
                                <el-table-column prop="beizhu" label="备注" width="200">
                                    <template slot-scope="scope">
                                        <el-input style="width: 90%" type="text" v-model="signparam.bao[scope.$index].beizhu" placeholder="请输入备注内容" />
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div
                                style="
                  width: 100%;
                  height: auto;
                  overflow: hidden;
                  font-size: 14px;
                  text-align: right;
                  font-weight: bold;
                  margin-top: 20px;
                "
                            >
                                <!-- 含税总计：{{ parseFloat(hszj).toFixed(2) }}，不含税总计：{{
                  parseFloat(bhszj).toFixed(2)
                }} -->
                                不含税总计：{{ parseFloat(bhszj).toFixed(2) }}
                            </div>
                        </div>
                    </div>
                    <!--附件上传-->
                    <div class="s-main">
                        <div class="s-title">投标文件 - 必传项</div>
                        <div class="qua-one" v-for="(item, index) in signparam.files" :key="index">
                            <span style="font-size: 14px">
                                {{ item ? item.substring(item.lastIndexOf('/') + 1) : '请上传投标文件' }}
                                <i style="padding-left: 20px; cursor: pointer" @click="delFiles(index)" class="el-icon-circle-close" v-if="index > 0 && item"></i>
                            </span>
                            <div @click="uploadindexs(index)">
                                <el-upload class="c-image" action="" :on-change="upMemEntrImages" :show-file-list="false" :auto-upload="false">
                                    <i class="el-icon-plus el-icon-circle-plus" style="font-size: 24px"></i>
                                </el-upload>
                            </div>
                        </div>
                        <el-link type="primary" @click="addFiles" style="margin-top: 20px">添加投标文件</el-link>
                    </div>
                    <!--添加联系人-->
                    <div class="s-main">
                        <div class="s-title">添加联系人 - 至少添加一位联系人</div>
                        <div class="qua-one" v-for="(item, index) in signparam.contact" :key="index">
                            <label>姓名：</label>
                            <input type="text" v-model="signparam.contact[index].name" placeholder="请输入联系人姓名" />
                            <label>电话：</label>
                            <input type="number" v-model="signparam.contact[index].tel" placeholder="请输入联系人电话" />
                            <i class="el-icon-circle-close" v-if="index > 0" style="cursor: pointer" @click="delPerson(index)"></i>
                        </div>
                        <el-link type="primary" @click="addPerson" style="margin-top: 20px">添加联系人</el-link>
                    </div>
                    <div class="submit-sign" @click="submitSign">提交投标</div>
                </div>
            </el-dialog>
            <div class="b-left">
                <div class="block-box">
                    <!--基础内容-->
                    <div class="content">
                        <div class="info">
                            <div class="bid-title">{{ biddetail.title }}</div>
                            <div class="info-one" v-if="biddetail.json && biddetail.json.bianhao">招标编号：{{ biddetail.json && biddetail.json.bianhao }}</div>
                            <div class="info-one" v-if="biddetail.json && biddetail.json.xm">项目名称：{{ biddetail.json && biddetail.json.xiangmu }}</div>
                            <div class="info-one">招标单位：{{ biddetail.json && biddetail.json.gongsi }}</div>
                            <div class="info-one">发布日期：{{ biddetail.created_at }}</div>
                            <div class="info-one">截止日期：{{ biddetail.bidding_end_time }}</div>
                            <div class="info-one">开标日期：{{ biddetail.bidding_end_time }}</div>
                            <div class="info-one">招标分类：{{ biddetail.classify && biddetail.classify.name ? biddetail.classify.name : '' }}</div>
                            <div class="info-one">
                                评标模式：
                                <label for="" v-if="biddetail.score_model == 0">综合评分法</label>
                                <label for="" v-if="biddetail.score_model == 1">经评审的合理低价法</label>
                                <label for="" v-if="biddetail.score_model == 2">经评审的最低价法</label>
                            </div>
                            <div class="info-one">
                                开标方式：
                                <label for="" v-if="biddetail.bid_open == 0">网络线上开标</label>
                                <label for="" v-if="biddetail.bid_open == 1">线下开标</label>
                            </div>
                        </div>
                        <!-- <div class="dese">
              <div class="desc-title">采购详情</div>
              <div class="desc-desc">
                <el-table
                  :data="biddetail.json.details"
                  style="width: 100%"
                >
                  <el-table-column prop="wuzi" label="名称">
                    <template slot-scope="scope">
                      <span>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="guige" label="规格描述">
                    <template slot-scope="scope">
                      <span>{{ scope.row.guige }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="shuliang" label="单位" width="70">
                    <template slot-scope="scope">
                      <span>{{ scope.row.danwei }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="danwei" label="数量" width="70">
                    <template slot-scope="scope">
                      <span>{{ scope.row.shuliang }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="danwei" label="控制价（元）">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nomore }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="danwei" label="beizhu">
                    <template slot-scope="scope">
                      <span>{{ scope.row.beizhu }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div> -->
                        <div class="dese">
                            <div class="desc-title">招标详情</div>
                            <div class="desc-desc" v-html="biddetail.content"></div>
                        </div>
                        <div class="dese">
                            <div class="desc-title">
                                招标附件
                                <span>提示：点击文件名即可下载</span>
                            </div>
                            <div class="file-list" v-if="biddetail.json && biddetail.json.accessory">
                                <div class="file-one" @click="downFile(item)" v-for="(item, index) in biddetail.json.accessory" :key="index">
                                    <i
                                        style="color: #e52d27"
                                        class="iconfont icon-file-jpg-Fill"
                                        v-if="
                                            item.wjmc.substring(item.wjmc.lastIndexOf('/') + 1).split('.')[1] == 'jpg' ||
                                                item.wjmc.substring(item.wjmc.lastIndexOf('/') + 1).split('.')[1] == 'png' ||
                                                item.wjmc.substring(item.wjmc.lastIndexOf('/') + 1).split('.')[1] == 'bmp'
                                        "
                                    ></i>
                                    <i
                                        style="color: blue"
                                        class="iconfont icon-Word"
                                        v-else-if="
                                            item.wjmc.substring(item.wjmc.lastIndexOf('/') + 1).split('.')[1] == 'doc' || item.wjmc.substring(item.wjmc.lastIndexOf('/') + 1).split('.')[1] == 'docx'
                                        "
                                    ></i>
                                    <i
                                        style="color: green"
                                        class="iconfont icon-excel"
                                        v-else-if="
                                            item.wjmc.substring(item.wjmc.lastIndexOf('/') + 1).split('.')[1] == 'xls' || item.wjmc.substring(item.wjmc.lastIndexOf('/') + 1).split('.')[1] == 'xlsx'
                                        "
                                    ></i>
                                    <i style="color: #c00" class="iconfont icon-pdf" v-else-if="item.wjmc.split('.')[1] == 'pdf'"></i>
                                    <i style="color: #333" class="iconfont icon-wenjian" v-else></i>
                                    <label for="" v-if="item.wjmc.includes('http') || item.wjmc.includes('https')">{{ item.wjmc.substring(item.wjmc.lastIndexOf('/') + 1) }}</label>
                                    <label for="" v-else>{{ item.wjmc }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="dese" v-if="biddetail.clarify && biddetail.clarify.length > 0">
                            <div class="desc-title">招标补遗</div>
                            <div class="desc-desc">
                                <div class="bu-one" @click="toBuDetail(v)" v-for="(v, k) in biddetail.clarify" :key="k">
                                    {{ v.title }}
                                    <label style="font-weight: bold; padding-left: 15px">[附件数量：{{ v && v.enclosure ? v.enclosure.length : '0' }}]</label>
                                    <span>发布时间：{{ v.created_at }}</span>
                                </div>
                            </div>
                        </div>
                        <!--报名按钮-->
                        <div class="submit" @click="opendrawer" v-if="biddetail.status == 2 && tou == 2">
                            立即投标
                        </div>
                    </div>
                </div>
            </div>
            <div class="b-right">
                <div class="file-title">
                    疑问解答 -
                    <span>如果您对该招标有疑问，可以在此提出</span>
                </div>
                <div class="question">
                    <div class="q-list">
                        <div class="q-one" v-for="(item, index) in qlist" :key="index">
                            <div class="q-question">
                                <span>问</span>
                                <label for="">{{ item.content }}</label>
                            </div>
                            <div class="q-question" style="margin-top: 10px" v-if="item.children.length > 0">
                                <div v-for="item2 in item.children" :key="item2.id" style="margin: 20px 0;">
                                    <span style="background: #e52d27;">答</span>
                                    <label :key="item2.id">{{ item2.content }}</label>
                                    <div v-if="item2.fj2">
                                        <el-link type="primary" @click="downloadFile33(item2.fj2)">
                                            下载回复附件
                                        </el-link>
                                    </div>
                                    <div v-else style="height: 10px;"></div>
                                </div>
                                <div v-if="item.children.length == 0">
                                    <span style="background: #e52d27">答</span>
                                    <label>暂无回答</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-link icon="el-icon-question" style="margin-bottom: 200px" type="primary" @click="questionshow = true">
                        对招标项目有疑问，点此提交。
                    </el-link>
                </div>
                <div class="file-title">
                    澄清通知 -
                    <span>以下是管理员给您发出的澄清通知</span>
                </div>
                <div class="question">
                    <div class="q-list" v-if="tongzhilist.length > 0">
                        <div class="q-one" @click="huifutongzhi(item)" v-for="(item, index) in tongzhilist" :key="index">
                            <div class="q-question">
                                <span>答</span>
                                <label for="">{{ item.content }}</label>
                            </div>
                            <div class="q-question" style="margin-top: 10px" v-if="item.reply != null">
                                <span style="background: #e52d27">回</span>
                                <label for="">{{ item.reply }}</label>
                            </div>
                        </div>
                    </div>
                    <div v-else style="font-size: 14px; color: #ccc">暂无澄清通知</div>
                </div>
                <el-dialog title="疑问提交" :visible.sync="questionshow" width="25%">
                    <div class="que-main">
                        <textarea name="" id="" v-model="qparam.content" cols="30" rows="6" placeholder="请输入您的问题"></textarea>
                    </div>
                    <div>
                        <el-upload class="c-image" action="" :on-change="addFj" :show-file-list="false" :auto-upload="false">
                            {{ qparam.fj != '' ? '已上传' : '添加附件' }}
                            <i class="el-icon-plus el-icon-circle-plus" style="font-size: 24px"></i>
                        </el-upload>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="questionshow = false">取 消</el-button>
                        <el-button type="primary" @click="submitQuestion">确 定</el-button>
                    </span>
                </el-dialog>
                <!--澄清回复-->
                <el-dialog title="通知回复" :visible.sync="tongshow" width="25%">
                    <div class="que-main">
                        <textarea name="" id="" v-model="hui.content" cols="30" rows="6" placeholder="请输入您的回复"></textarea>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="tongshow = false">取 消</el-button>
                        <el-button type="primary" @click="submitTong">确 定</el-button>
                    </span>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Base from '../../api/base';
import { RsaPubEncrypt } from '../../util/cipher';
import BidApi from '../../api/bidApi';
import UploadApi from '../../api/uploadApi';
import UserApi from '../../api/userApi';
import NewsApi from '../../api/newsApi';
import UploadExcel from '../../components/UploadExcel';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    name: 'HomePage',
    metaInfo() {
        return this.$head('参与投标-电子招标采购平台-四川纹江致远建筑开发工程有限公司');
    },
    data() {
        return {
            hui: {
                id: '',
                content: '',
            },
            tongid: '',
            tongshow: false,
            tongzhilist: [],
            hszj: 0,
            bhszj: 0,
            baojialist: [],
            drawer: false,
            bid: '',
            biddetail: {},
            userinfo: {},
            isLogin: '',
            show: false,
            param: {
                account: '',
                password: '',
            },
            bidmodel: {
                0: { name: '综合评分法' },
                1: { name: '经评审的合理低价法' },
                2: { name: '经评审的最低价法' },
            },
            bidopen: {
                0: { name: '网络线上开标' },
                1: { name: '线下开标' },
            },
            status: 0,
            qua: [],
            signparam: {
                id: '',
                qua: [],
                price: {
                    inprice: '',
                    unprice: '',
                    taxrate: '',
                    invoicetype: '',
                    beizhu: '',
                },
                bao: [],
                files: [''],
                contact: [{ name: '', tel: '' }],
            },
            quaindex: null,
            disc: {
                name: '姓名',
                tel: '电话',
            },
            qparam: {
                tender_id: null,
                content: '',
            },
            fileindex: null,
            questionshow: false,
            qlist: [],
            fapiao: [
                {
                    label: '普通发票',
                    value: '普通发票',
                },
                {
                    label: '增值税专用发票',
                    value: '增值税专用发票',
                },
            ],
            tou: '',
        };
    },
    components: { UploadExcel },
    computed: {
        ...mapState('game', ['gameList']),
    },
    watch: {},
    mounted() {},
    methods: {
        downloadFile33(url2) {
            let url = Base.baseURL + '/pc/file/download?name=' + url2;
            let a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.setAttribute('download', url2.substring(url2.lastIndexOf('/') + 1));
            document.body.appendChild(a);
            a.click(); //执行下载
            window.URL.revokeObjectURL(a.href); //释放url
            document.body.removeChild(a);
        },
        async submitTong() {
            if (!this.hui.content) {
                this.$message.error('请输入回复内容');
            } else {
                try {
                    await BidApi.huiTongzhi(this.hui);
                    this.$message.success('回复成功');
                    this.tongshow = false;
                    this.hui.content = '';
                    await this.getTongzhi();
                } catch (error) {
                    this.$message.error(error.message);
                }
            }
        },
        //回复通知
        huifutongzhi(item) {
            this.tongshow = true;
            this.hui.id = item.id;
        },
        //获取我的澄清通知
        async getTongzhi() {
            let res = await BidApi.myTongzhi({
                id: this.bid,
            });
            console.log(res);
            this.tongzhilist = res;
        },
        async addFj(file) {
            let pnglist = ['JPG', 'jpg', 'jpeg', 'png', 'PNG', 'gif', 'GIF', 'PNG'];
            let fname = file.name.substring(file.name.lastIndexOf('.') + 1);
            if (pnglist.indexOf(fname) > -1) {
                try {
                    let res = await UploadApi.uFiles({
                        file: file.raw,
                    });
                    this.qparam.fj = res;
                } catch (e) {
                    this.$message.error(e.message);
                }
            } else {
                this.$message.error('仅支持PNG,JPG,GIF格式文件');
            }
        },
        ...mapActions('user', ['login', 'getUserInfo']),
        ...mapMutations('user', ['loginOut']),
        //用户登录
        toLogin() {
            this.$store.commit('login/setShow', true);
            this.$store.commit('login/setType', 'accountReg');
        },
        async userLogin() {
            if (!this.param.account) {
                this.$message.error('请输入账号或手机号');
            } else if (!this.param.password) {
                this.$message.error('请输入密码');
            } else {
                let form = { ...this.param };
                form.password = RsaPubEncrypt(form.password);
                try {
                    let res = await UserApi.uLogin(form);
                    this.$message.success('登录成功');
                    localStorage.setItem('token', res.token);
                    this.userinfo = res.user;
                    this.isLogin = res.token;
                } catch (error) {
                    this.$message.error(error.message);
                }
            }
        },
        changePrice() {
            for (let i = 0; i < this.signparam.bao.length; i++) {
                if (this.signparam.bao[i].unprice && this.signparam.bao[i].taxrate) {
                    this.signparam.bao[i].inprice = (this.signparam.bao[i].unprice * (1 + this.signparam.bao[i].taxrate / 100)).toFixed(2);
                }
            }
            this.bhszj = 0;
            this.hszj = 0;
            //计算含税跟不含税总价
            for (let i = 0; i < this.signparam.bao.length; i++) {
                this.bhszj += parseFloat(this.signparam.bao[i].unprice * this.signparam.bao[i].shuliang);
                if (this.signparam.bao[i].inprice) {
                    this.hszj += parseFloat(this.signparam.bao[i].inprice * this.signparam.bao[i].shuliang);
                }
            }
        },
        //导入报价
        dy(results) {
            let isImport = true;
            for (let i = 0; i < results.results.length; i++) {
                for (let j = 0; j < this.signparam.bao.length; j++) {
                    // if (parseFloat(results.results[i].税率) > 0) {
                    if (results.results[i].物资ID == this.signparam.bao[j].id) {
                        //     this.signparam.bao[j].inprice = (
                        //       results.results[i].不含税价 *
                        //       (1 + results.results[i].税率 / 100)
                        //     ).toFixed(2);
                        this.signparam.bao[j].unprice = results.results[i].不含税价;
                        // this.signparam.bao[j].taxrate = results.results[i].税率;
                        // this.signparam.bao[j].invoicetype = results.results[i].发票类型;
                        this.signparam.bao[j].beizhu = results.results[i].备注;
                    }
                }
                // } else {
                //   isImport = false;
                //   this.$message.error("导入报价单税率只能为数字");
                //   break;
                // }
            }

            if (isImport) {
                this.changePrice();
            }
        },
        //导出报价
        dc() {
            console.log(this.baojialist);
            import('@/vendor/Export2Excel').then(excel => {
                const tHeader = [
                    '物资ID',
                    '名称',
                    '单位',
                    '数量',
                    '控制价',
                    '不含税价',
                    // "税率",
                    // "发票类型",
                    '备注',
                ];
                const filterVal = [
                    'id',
                    'name',
                    'danwei',
                    'shuliang',
                    'nomore',
                    'unprice',
                    // "taxrate",
                    // "invoicetype",
                    'beizhu',
                ];
                const data = this.formatJson(filterVal, this.baojialist);
                excel.export_json_to_excel({
                    header: tHeader, //表头 必填
                    data, //具体数据 必填
                    filename: '报价清单', //非必填
                    autoWidth: true, //非必填
                    bookType: 'xlsx', //非必填
                });
            });
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map(v =>
                filterVal.map(j => {
                    return v[j];
                })
            );
        },
        //获取用户信息
        async getUSerinfo() {
            try {
                let res = await UserApi.user();
                this.userinfo = res;
            } catch (error) {
                this.$message.error(error.message);
            }
        },
        opendrawer() {
            this.drawer = true;
        },
        //获取招标详情
        async getDetail() {
            try {
                let res = await BidApi.bidDetail({
                    id: this.bid,
                });
                this.signparam.id = res.id;
                this.biddetail = res;
                this.qua = res.json.pqd;
                for (let i = 0; i < this.qua.length; i++) {
                    let key = this.qua[i].ident;
                    this.signparam.qua.push({ [key]: '' });
                }
                //循环插入报价列表
                for (let j = 0; j < res.json.details.length; j++) {
                    let number = res.json.details[j].wid;
                    let newnumber = number.toString();
                    this.signparam.bao.push({
                        id: newnumber.substring(7),
                        name: res.json.details[j].name,
                        shuliang: res.json.details[j].shuliang,
                        guige: res.json.details[j].guige,
                        nomore: res.json.details[j].nomore,
                        danwei: res.json.details[j].danwei,
                        inprice: '',
                        unprice: '',
                        taxrate: '',
                        invoicetype: '',
                        beizhu: res.json.details[j].beizhu,
                    });
                }
                //循环插入报价列表
                for (let k = 0; k < res.json.details.length; k++) {
                    let number = res.json.details[k].wid;
                    let newnumber = number.toString();
                    this.baojialist.push({
                        id: newnumber.substring(7),
                        name: res.json.details[k].name,
                        danwei: res.json.details[k].danwei,
                        shuliang: res.json.details[k].shuliang,
                        nomore: res.json.details[k].nomore,
                        inprice: '',
                        unprice: '',
                        taxrate: '',
                        invoicetype: '',
                        beizhu: res.json.details[k].beizhu,
                    });
                }
            } catch (error) {
                this.$message.error(error.message);
            }
        },
        //报名
        toSignup() {
            if (!this.isLogin) {
                this.$message.error('请先登录');
                setTimeout(() => {
                    this.$router.push('/');
                }, 1200);
            } else {
                //用户已登录，判断招标状态
                if (this.biddetail.status == 2) {
                } else {
                    this.$message.error('已过招标期，停止报名');
                }
            }
        },
        async upMemEntrImage(file) {
            let pnglist = ['JPG', 'jpg', 'jpeg', 'png', 'PNG', 'gif', 'GIF', 'PNG'];
            let fname = file.name.substring(file.name.lastIndexOf('.') + 1);
            if (pnglist.indexOf(fname) > -1) {
                try {
                    let res = await UploadApi.uFiles({
                        file: file.raw,
                    });
                    let ident = [];
                    for (let i = 0; i < this.signparam.qua.length; i++) {
                        ident.push(Object.keys(this.signparam.qua[i]));
                    }
                    let kname = ident[this.quaindex][0];
                    this.signparam.qua[this.quaindex][kname] = res;
                    console.log(this.signparam);
                } catch (e) {
                    this.$message.error(e.message);
                }
            } else {
                this.$message.error('仅支持PNG,JPG,GIF格式文件');
            }
        },
        uploadindex(index) {
            this.quaindex = index;
        },
        async upMemEntrImages(file) {
            try {
                let res = await UploadApi.uFiles({
                    file: file.raw,
                    e: 1,
                });
                this.$set(this.signparam.files, this.fileindex, res);
                // this.signparam.files[this.fileindex] = res;
            } catch (e) {
                this.$message.error(e.message);
            }
        },
        uploadindexs(index) {
            this.fileindex = index;
            console.log(index);
        },
        addPerson() {
            this.signparam.contact.push({
                name: '',
                tel: '',
            });
        },
        addFiles() {
            this.signparam.files.push('');
        },
        checkQua() {
            // for (let i = 0; i < this.signparam.qua.length; i++) {
            //   for (let key in this.signparam.qua[i]) {
            //     if (this.signparam.qua[i][key] == "") {
            //       return false;
            //     }
            //   }
            // }
            return true;
        },
        checkFile() {
            for (let i = 0; i < this.signparam.files.length; i++) {
                if (this.signparam.files[i] == '') {
                    return false;
                }
            }
            return true;
        },
        checkPerson() {
            for (let i = 0; i < this.signparam.contact.length; i++) {
                for (let key in this.signparam.contact[i]) {
                    if (this.signparam.contact[i][key] == '') {
                        return false;
                    }
                }
            }
            return true;
        },
        checkBao() {
            for (let i = 0; i < this.signparam.bao.length; i++) {
                for (let key in this.signparam.bao[i]) {
                    console.log(this.signparam.bao);
                    console.log(key);
                    // if (this.signparam.bao[i][key] == "") {
                    if (this.signparam.bao[i]['unprice'] == '') {
                        this.$message.error('请完善' + this.signparam.bao[i].name + '的报价清单');
                        return false;
                    }
                }
            }
            return true;
        },
        checkMore() {
            for (let i = 0; i < this.signparam.bao.length; i++) {
                if (parseFloat(this.signparam.bao[i].unprice) > parseFloat(this.signparam.bao[i].nomore)) {
                    this.$message.error(this.signparam.bao[i].name + '的报价高于控制价');
                    return false;
                }
            }
            return true;
        },
        async submitSign() {
            //检测是否有资格文件没有上传
            if (!this.checkQua()) {
                this.$message.error('请上传资格文件');
            } else if (!this.checkFile()) {
                //检测联系人信息
                this.$message.error('请上传招标附件');
            } else if (!this.checkPerson()) {
                //检测联系人信息
                this.$message.error('请完善联系人信息');
            } else {
                if (this.checkBao()) {
                    console.log(1);
                    if (this.checkMore()) {
                        console.log(2);
                        try {
                            await BidApi.bidSign(this.signparam);
                            this.drawer = false;
                            this.$message.success('投标成功');
                            this.drawer = false;
                            this.signparam.bao = [];
                            this.signparam.files = [''];
                            this.signparam.contact = [{ name: '', tel: '' }];
                            this.$router.push('/UserBid');
                        } catch (error) {
                            this.$message.error(error.message);
                        }
                    }
                }
            }
        },
        delPerson(index) {
            if (index == 0) {
                this.$message.error('请至少添加一位联系人');
            } else {
                this.signparam.contact.splice(index, 1);
            }
        },
        delFiles(index) {
            if (index == 0) {
                this.$message.error('请至少添加一个附件');
            } else {
                this.signparam.files.splice(index, 1);
            }
        },
        downFile(item) {
            let imgUrl = '';
            if (item.wjmc.includes('http') || item.wjmc.includes('https')) {
                imgUrl = item.wjmc;
                const el = document.createElement('a');
                el.style.display = 'none';
                el.setAttribute('target', '_blank');
                el.href = imgUrl;
                console.log(el);
                document.body.appendChild(el);
                el.click();
                document.body.removeChild(el);
            } else {
                let url = Base.baseURL + '/pc/file/download?name=' + item.wjmc;
                let a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.setAttribute('download', item.wjmc.substring(item.wjmc.lastIndexOf('/') + 1));
                document.body.appendChild(a);
                a.click(); //执行下载
                window.URL.revokeObjectURL(a.href); //释放url
                document.body.removeChild(a);

                // axios({
                //   method: "POST",
                //   url: Base.baseURL + "/file/download?name=" + item.wjmc,
                //   headers: {
                //     'X-Token': localStorage.getItem('token')
                //   },
                //   responseType: 'blob'
                // }).then(res => {
                //   // 请求成功后处理流
                //   if (res.status === 200) {
                //     const blob = new Blob([res.data])
                //     const downloadElement = document.createElement('a');
                //     const href = window.URL.createObjectURL(blob); //创建下载的链接
                //     downloadElement.href = href;
                //     downloadElement.download = item.wjmc; //下载后文件名
                //     document.body.appendChild(downloadElement);
                //     downloadElement.click(); //点击下载
                //     document.body.removeChild(downloadElement); //下载完成移除元素
                //     window.URL.revokeObjectURL(href); //释放掉blob对象
                //   }
                // })
            }
        },
        toBuDetail(item) {
            let url = this.$router.resolve({
                path: '/bid/addendum',
                query: { id: item.id },
            });
            window.open(url.href, '');
        },
        //提交问题
        async submitQuestion() {
            try {
                await BidApi.sQuestion(this.qparam);
                this.$message.success('提交成功');
                this.questionshow = false;
                this.qparam.content = '';
                await this.getAllQuestion();
            } catch (error) {
                this.$message.error(error.message);
            }
        },
        async getAllQuestion() {
            try {
                console.log(this.qparam.id);
                let res = await BidApi.mQuestion({
                    id: this.bid,
                });
                this.qlist = res;
            } catch (error) {
                this.$message.error(error.message);
            }
        },
    },
    async created() {
        this.tou = this.$route.query.tou;
        //获取招标ID
        if (this.$route.query.id) {
            this.bid = this.$route.query.id;
            this.qparam.tender_id = this.$route.query.id;
            await this.getDetail();
            this.getTongzhi();
        }
        if (localStorage.getItem('token')) {
            this.isLogin = localStorage.getItem('token');
            await this.getUSerinfo();
            await this.getAllQuestion();
        }
    },
};
</script>
<style>
.el-dialog {
    margin-top: 8vh !important;
}

.signup-main::-webkit-scrollbar {
    display: none !important;
}

.el-drawer__wrapper::-webkit-scrollbar {
    display: none;
}
</style>
<style scoped>
/* 整体盒子 */
#HomePage {
    width: 100%;
}

.signup-main::-webkit-scrollbar {
    display: none !important;
}

.block-place {
    width: 1360px;
    min-height: 680px;
    overflow: hidden;
    margin: auto;
    background: #fff;
    margin-top: 20px;
    padding: 20px;
    position: relative;
}

.submit {
    width: 370px;
    height: auto;
    overflow: hidden;
    background: #9e241d;
    padding: 18px 0;
    color: #fff;
    text-align: center;
    cursor: pointer;
    margin-left: 20px;
    margin-bottom: 100px;
}

.submit:hover {
    background: #d1372f;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.block-box {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.content {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.info {
    width: calc(100% - 40px);
    height: auto;
    overflow: hidden;
    padding: 30px 20px 20px 20px;
    background: #fff;
}

.dese {
    width: calc(100% - 40px);
    height: auto;
    overflow: hidden;
    background: #fff;
    padding: 20px;
}

.desc-title {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-bottom: solid 1px #eee;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
}

.desc-title span {
    float: right;
    font-size: 14px;
    color: #999;
    padding-top: 10px;
}

.desc-desc {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-top: 20px;
    line-height: 24px;
}

.info-one {
    width: 50%;
    height: auto;
    overflow: hidden;
    float: left;
    padding-bottom: 20px;
}

.b-left {
    width: 970px;
    height: auto;
    overflow: hidden;
    float: left;
    border-right: solid 1px #eee;
}

.b-right {
    width: 370px;
    float: right;
    height: auto;
    overflow: hidden;
}

.bid-title {
    width: 100%;
    height: auto;
    overflow: hidden;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 30px;
    color: #9e241d;
}

.file-title {
    width: 100%;
    height: auto;
    overflow: hidden;
    font-weight: bold;
    padding-bottom: 20px;
}

.file-title span {
    font-size: 14px;
    font-weight: normal !important;
    color: #999;
}

.file-list {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 50px;
}

.file-one {
    width: 100%;
    height: auto;
    overflow: hidden;
    font-size: 14px;
    padding: 15px 0;
    cursor: pointer;
}

.file-one i {
    display: inline-block;
    padding-right: 5px;
}

.file-one label {
    cursor: pointer;
}

.file-one label:hover {
    color: #9e241d;
}

.signup-main {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 20px;
}

.s-main {
    width: 50%;
    height: auto;
    overflow: hidden;
    margin-bottom: 35px;
}

.s-mains {
    width: 97%;
    height: auto;
    overflow: hidden;
    margin-bottom: 35px;
}

.s-title {
    width: 100%;
    height: auto;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
}

.s-title label {
    display: block;
    float: right;
    margin-left: 20px;
    color: #fff;
    background: #e52d27;
    cursor: pointer;
    border-radius: 3px;
    padding: 6px 15px;
    font-size: 12px;
}

.qua-one {
    width: calc(99% - 40px);
    height: auto;
    overflow: hidden;
    border: solid 1px #f2f2f2;
    margin-top: 20px;
    padding: 15px 10px;
}

.qua-one span {
    float: left;
    display: block;
    color: #666;
    margin-top: 3px;
}

.c-image {
    display: block;
    float: right;
}

.success-style {
    width: auto;
    font-size: 12px;
    background: green;
    color: #fff;
    padding: 3px 8px;
}

.success-styles {
    width: auto;
    font-size: 12px;
    background: rgb(84, 84, 84);
    color: #fff;
    padding: 3px 8px;
}

.submit-sign {
    width: 400px;
    height: 54px;
    background: #9e241d;
    color: #fff;
    cursor: pointer;
    text-align: center;
    line-height: 54px;
    margin: auto;
    margin-top: 60px;
    border-radius: 5px;
}

.submit-sign:hover {
    background: #d1372f;
}

.qua-one input {
    display: inline-block;
    padding: 8px 10px;
    margin-right: 15px;
    border: solid 1px #f2f2f2;
}

.que-main {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.que-main textarea {
    display: block;
    width: calc(100% - 20px);
    border: 0;
    background: #eee;
    padding: 10px;
    font-size: 14px;
}

.q-list {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 30px;
}

.q-one {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 20px;
    border-bottom: dashed 1px #f2f2f2;
    padding-bottom: 20px;
}

.q-question {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.q-question span {
    min-width: 20px;
    height: 20px;
    background: #04be02;
    color: #fff;
    font-size: 12px;
    text-align: center;
    display: block;
    line-height: 20px;
    float: left;
}

.q-question label {
    display: block;
    width: calc(100% - 30px);
    height: auto;
    float: right;
    font-size: 14px;
    line-height: 22px;
}

.bu-one {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 10px 0;
    cursor: pointer;
}

.bu-one:hover {
    color: #e52d27;
}

.bu-one span {
    float: right;
    font-size: 14px;
    color: #999;
}

.sp-main {
    width: calc(94% - 2px);
    height: auto;
    overflow: hidden;
    border: solid 1px #eee;
    padding: 20px 20px 0 20px;
    margin-top: 15px;
}

.price-input {
    width: 25%;
    height: auto;
    float: left;
    margin-bottom: 20px;
}

.price-input span {
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    display: block;
    font-size: 12px;
}

.name-title {
    width: 100%;
    height: auto;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 15px;
}
</style>
