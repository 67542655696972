import Base from "./base"

export default class BannerApi extends Base {

  //banner列表
  static getBanner(param) {
    return super.get('/banner', param)
  }

}
