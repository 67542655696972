<template>
  <div>
    <el-dialog
      @close="$store.commit('login/setShow', false)"
      width="420px"
      :modal-append-to-body="false"
      :append-to-body="true"
      :close-on-click-modal="false"
      :modal="true"
      title="新用户注册"
      :visible="show"
    >
      <!-- 手机验证码登录 -->
      <div class="login-main" v-show="type == 'accountReg'">
        <div class="lm-one">
          <input
            placeholder="请输入登录账号"
            type="text"
            v-model="param.account"
          />
        </div>
        <div class="lm-one">
          <input
            placeholder="请输入登录密码"
            type="password"
            v-model="param.password"
          />
        </div>
        <div class="lm-one">
          <input
            placeholder="请输入手机号码"
            type="text"
            v-model="param.phone"
          />
        </div>

        <div class="lm-ones">
          <input placeholder="请输入验证码" type="text" v-model="param.code" />
          <span v-if="!isSend" @click="sendCode">{{ phoneCodeText }}</span>
          <span class="sendok" v-else>{{ sendDate }}秒后获取</span>
        </div>

        <div @click="userReg" class="login-btn">立即注册</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import checkData from "../util/index.js"
import { RsaPubEncrypt } from "../util/cipher";
import UserApi from "../api/userApi";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  props: {},
  data() {
    return {
      captchaImg: "",
      codeText: "发送验证码",

      //注册
      param: {
        account: "",
        password: "",
        phone: "",
        code: "",
      },
      //注册验证码弹窗
      isSend: false,
      sendDate: 300,
      //手机登录验证码弹窗
      phoneCodeDialog: false,
      phoneCodeText: "发送验证码",

    };
  },
  components:{checkData},
  computed: {
    ...mapState("login", ["type", "show"]),
  },
  created() {},
  methods: {
    ...mapActions("user", ["login", "getUserInfo", "registerByPhone"]),
    ...mapMutations("user", ["loginOut"]),
    ...mapMutations("login", ["setShow", "setType"]),
    /**
     * 注册
     */
    async userReg() {
      if (!this.param.account) {
        this.$message.error("请输入登录账号");
      }else if(!checkData.enOrNum(this.param.account)){
        this.$message.error("登录账号只能为字母或数字");
      }else if(this.param.account.length<8){
        this.$message.error("登录账号不能小于8个字符");
      }else if (!this.param.password) {
        this.$message.error("请输入登录密码");
      }else if(this.param.password.length<6){
        this.$message.error("登录密码不能小于6个字符");
      } else if (!this.param.phone) {
        this.$message.error("请输入手机号码");
      }else if(!checkData.mobile(this.param.phone)){
        this.$message.error("手机号码格式错误");
      } else if (!this.param.code) {
        this.$message.error("请输入验证码");
      } else {
        try {
          let form = { ...this.param };
          form.password = RsaPubEncrypt(form.password);
          await UserApi.register(form);
          this.$message.success("注册成功");
          this.$store.commit("login/setShow", false);
        } catch (e) {
          this.$message.error(e.message);
        }
      }
    },
    async sendCode() {
      if (!this.param.phone) {
        this.$message.error("请输入手机号码");
      } else {
        try {
          let res = await UserApi.sendCode({
            phone: this.param.phone,
          });
          this.isSend = true;
          this.sendDate = 300;
          let t = setInterval(() => {
            if (this.sendDate == 0) {
              clearInterval(t);
              this.isSend = false;
            } else {
              this.sendDate--;
            }
          }, 1000);
          this.$message.success("验证码发送成功");
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },

  },

  watch: {},
};
</script>

<style>
.el-dialog {
  width: 360px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05) !important;
  margin-top: 300px !important;
  z-index: 11 !important;
  border-top: solid 5px #cb141d;
}

.login-main {
  width: 340px;
  height: auto;
  margin: auto;
}

.lm-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 20px;
}

.lm-one input {
  background: #f9f9f9;
  display: block;
  width: 88%;
  padding: 15px 15px;
  border: solid 1px #eee;
}

.lm-ones {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 20px;
}

.lm-ones input {
  background: #f9f9f9;
  display: block;
  width: 48%;
  padding: 15px;
  border: solid 1px #eee;
  float: left;
}

.sendok {
  background: #eee !important;
  color: #999;
}

.actives {
  background-color: #eee !important;
}

.lm-ones img {
  display: block;
  float: left;
  width: 100px;
  margin-top: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.login-btn {
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #444;
  color: #fff;
  padding: 15px 0;
  text-align: center;
  margin-top: 30px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.login-btn:hover {
  background: #000;
}

.reg-fondpas {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
  color: #333;
  padding-top: 15px;
}

.reg-fondpas span {
  padding: 0 10px;
  cursor: pointer;
}

.reg-fondpas span:hover {
  color: #000;
}

.lm-ones span {
  display: block;
  width: 35%;
  height: auto;
  text-align: center;
  background: #fee5a4;
  float: right;
  padding: 15px 0;
  cursor: pointer;
  margin-right: 10px;
}

.xieyi {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
  color: #ccc;
  padding-top: 20px;
}

.xieyi span {
  color: #666;
  padding: 0px 5px;
  cursor: pointer;
}

.xieyi span:hover {
  color: #000;
}
</style>
