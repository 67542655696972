import Vue from 'vue'
import VueRouter from 'vue-router'
//引入页面
import Index from '@/view/HomePage' //首页
import blist from '@/view/bid/bidlist' //招标列表
import bdetail from '@/view/bid/detail' //招标详情
import bup from '@/view/bid/signup' //投标
import afile from '@/view/bid/addendum' //补遗
import ilist from '@/view/inquiry/inquirylist' //询价列表
import idetail from '@/view/inquiry/detail' //询价详情
import isubmit from '@/view/inquiry/inquiry' //询价
import nlist from '@/view/news/list' //新闻列表
import ndetail from '@/view/news/detail' //新闻详情
import ucenter from '@/view/UserCenter' //用户中心
import mycom from '@/view/UserCenter/UserCompany' //我的企业
import mybid from '@/view/UserCenter/UserBid' //我的投标
import myin from '@/view/UserCenter/UserIn' //我的询价
import invibid from '@/view/UserCenter/invitationBid' //邀请招标
import cpwd from '@/view/UserCenter/UserChangePwd' //修改密码
import fpwd from '@/view/forgetpwd' //忘记密码


Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


const routes = [
  { path: '/', redirect: '/Index' },
  { path: '/Index', component: Index },
  //招标公告
  { path: '/bid/bidlist', component:blist,title:'招标公告'},
  //招标详情
  { path: '/bid/detail', component:bdetail},
  //投标
  { path: '/bid/signup', component:bup},
  //补遗详情
  { path: '/bid/addendum', component:afile},
  //询价公告
  { path: '/inquiry/inquirylist', component:ilist},
  //询价详情
  { path: '/inquiry/detail', component:idetail},
  //询价
  { path: '/inquiry/userinquiry', component:isubmit},
  //新闻列表
  { path: '/news/list', component:nlist},
  //新闻详情
  { path: '/news/detail', component:ndetail},
  //用户中心
  { path: '/UserCenter', component:ucenter},
  //我的企业
  { path: '/UserCompany', component:mycom},
  //我的投标
  { path: '/UserBid', component:mybid},
  //我的询价
  { path: '/UserIn', component:myin},
  //邀请招标
  { path: '/InvitationBid', component:invibid},
  //修改密码
  { path: '/UserChangePwd', component:cpwd},
  //忘记密码
  { path: '/forgetpwd', component:fpwd}

]



const router = new VueRouter({
  mode: 'history',
  routes
})



export default router
