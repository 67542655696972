<template>
  <div id="HomePage">
    <!--招标信息-->
    <div class="block-place">
      <div class="block-box">
        <!--顶部-->
        <div class="top-bg">
        </div>
        <!--基础内容-->
        <div class="content">
          <div class="info">
            <div class="news-title">{{datadetail.title}}</div>
            <div class="news-titles">发布时间：{{datadetail.created_at}}</div>
            <div class="news-desc" v-html="datadetail.content"></div>
            <div class="files" v-if="datadetail&&datadetail.enclosure">
              <p>附件下载</p>
              <span @click="downFile(item)" v-for="(item,index) in datadetail.enclosure" :key="index">{{item.substring(item.lastIndexOf('/')+1)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Base from "../../api/base";
import { RsaPubEncrypt } from "../../util/cipher";
import BidApi from "../../api/bidApi";
import UserApi from "../../api/userApi";
import NewsApi from "../../api/newApi";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "HomePage",
  metaInfo() {
    return this.$head("招标补遗-电子招标采购平台-四川纹江致远建筑开发工程有限公司");
  },
  data() {
    return {
      bid: "",
      datadetail: {},
      userinfo: {},
      isLogin: "",
      show: false,
      param: {
        account: "",
        password: "",
      },
      count: "", //倒计时
      seconds: null, // 10天的秒数
      status: 0,
      timeshow:true,
      nid:null
    };
  },
  components: {},
  computed: {
    ...mapState("game", ["gameList"]),
  },
  watch: {},
  mounted() {
  },
  methods: {
    ...mapActions("user", ["login", "getUserInfo"]),
    ...mapMutations("user", ["loginOut"]),
    //用户登录
    toLogin() {
      this.$store.commit("login/setShow", true);
      this.$store.commit("login/setType", "accountReg");
    },
    async userLogin() {
      if (!this.param.account) {
        this.$message.error("请输入账号或手机号");
      } else if (!this.param.password) {
        this.$message.error("请输入密码");
      } else {
        let form = { ...this.param };
        form.password = RsaPubEncrypt(form.password);
        try {
          let res = await UserApi.uLogin(form);
          this.$message.success("登录成功");
          localStorage.setItem("token", res.token);
          this.userinfo = res.user;
          this.isLogin = res.token;
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },
    //获取用户信息
    async getUSerinfo() {
      let res = await UserApi.user();
        this.userinfo = res;
    },
    //获取详情
    async getDetail() {
      try {
        let res = await BidApi.buDetail({
          id: this.bid,
        });
        this.datadetail = res;
        console.log(this.datadetail)
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    downFile(item){
       let url = Base.baseURL + "/pc/file/download?name=" + item;
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.setAttribute("download", item.substring(item.lastIndexOf("/") + 1));
      document.body.appendChild(a);
      a.click(); //执行下载
      window.URL.revokeObjectURL(a.href); //释放url
      document.body.removeChild(a);
      // console.log(item);
      // axios({
      //     method: "POST",
      //     url: Base.baseURL + "/file/download?name=" + item,
      //     headers: {
      //       'X-Token': localStorage.getItem('token')
      //     },
      //     responseType: 'blob'
      //   }).then(res => {
      //     // 请求成功后处理流
      //     if (res.status === 200) {
      //       const blob = new Blob([res.data])
      //       const downloadElement = document.createElement('a');
      //       const href = window.URL.createObjectURL(blob); //创建下载的链接
      //       downloadElement.href = href;
      //       downloadElement.download = item; //下载后文件名
      //       document.body.appendChild(downloadElement);
      //       downloadElement.click(); //点击下载
      //       document.body.removeChild(downloadElement); //下载完成移除元素
      //       window.URL.revokeObjectURL(href); //释放掉blob对象
      //     }
      //   })
    }
  },
  async created() {
    //获取新闻ID
    if (this.$route.query.id) {
      this.bid = this.$route.query.id;
    }
    if (localStorage.getItem("token")) {
      this.isLogin = localStorage.getItem("token");
      await this.getUSerinfo();
    }
    await this.getDetail();
  },
};
</script>
<style scoped>
/* 整体盒子 */
#HomePage {
  width: 100%;
}

.block-place {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin: auto;
}

.block-box {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 20px 0 40px 0;
}

.top-bg {
  width: 1240px;
  height: 120px;
  background-image: url("../../assets/img/bbg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  line-height: 120px;
  padding: 0 20px;
}
.top-bg span {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
.top-bg label {
  display: block;
  background: #f08616;
  cursor: pointer;
  color: #fff;
  float: right;
  width: 120px;
  height: 46px;
  line-height: 46px;
  margin-top: 37px;
  text-align: center;
}

.top-bg label:hover {
  background: #f59834;
}
.content {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.info {
  width: calc(100% - 140px);
  height: auto;
  overflow: hidden;
  padding:50px 70px;
  background: #fff;
}
.dese {
  width: calc(100% - 40px);
  height: auto;
  overflow: hidden;
  margin-top: 20px;
  background: #fff;
  padding: 20px;
}
.desc-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-bottom: solid 1px #eee;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}
.desc-desc {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 20px;
  line-height: 24px;
}
.info-one {
  width: 25%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-bottom: 30px;
}
.end-time {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
  color: #f08616;
}
.news-title{width: 100%; height:auto; overflow: hidden; text-align:center; font-size: 22px; font-weight: bold;}
.news-titles{width: 100%; height:auto; overflow: hidden; text-align:center; font-size: 14px; padding-top:10px}
.news-info{width: 100%; height:auto; overflow: hidden; text-align:center; font-size: 14px; color:#999; padding: 20px 0;}
.news-desc{width: 100%; height:auto; overflow: hidden; line-height: 22px; padding-top: 50px;}
.files{width: calc(100% - 40px); height:auto; overflow: hidden; padding: 20px; background: #eee; margin-top: 50px;}
.files p{display: block; font-weight: bold;}
.files span{display: block; margin-top: 15px; cursor: pointer;}
</style>
