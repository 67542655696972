
export default {

    namespaced: true,

    state: {
        type: "login",
        show: false,
    },

    mutations: {
        setType: (state, data) => state.type = data,
        setShow: (state, data) => state.show = data,
    },

    actions: {


    },
}
