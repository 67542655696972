<template>
  <div id="UserCenter">
    <div class="content-main">
      <!--下半部分-->
      <div class="more-user">
        <!--左边-->
        <div class="mu-left">
          <Left></Left>
        </div>
        <!--右边-->
        <div class="mu-right">
          <div class="mr-title">修改密码<span> - 建议3个月修改一次</span></div>
          <div class="mr-fcm">
            <div class="mf-one">
              <span>原密码：</span>
              <input
                type="password"
                v-model="param.old"
                placeholder="请输入原密码"
              />
            </div>
            <div class="mf-one">
              <span>新密码：</span>
              <input
                type="password"
                v-model="param.new"
                placeholder="请输入新密码"
              />
            </div>
            <div class="mf-one">
              <span>新密码：</span>
              <input
                type="password"
                v-model="again"
                placeholder="请输入确认密码"
              />
            </div>
            <div class="mf-btn" @click="change">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserApi from "../../api/userApi";
import Left from "../../components/Left.vue";
import { RsaPubEncrypt } from "../../util/cipher";
export default {
  name: "UserCenter",
  metaInfo() {
    return this.$head("修改密码");
  },
  data() {
    return {
      param: {
        old: "",
        new: "",
      },
      again: "",
    };
  },
  components: {
    Left
  },
  mounted() {},
  methods: {
    async change() {
      if (!this.param.old) {
        this.$message.error("请输入原密码");
      } else if (!this.param.new) {
        this.$message.error("请输入新密码");
      } else if (!this.again) {
        this.$message.error("请输入确认密码");
      } else if (this.param.new != this.again) {
        this.$message.error("新密码与确认密码不一致");
      } else {
        try {
          let p = { ...this.param };
          p.old = RsaPubEncrypt(p.old);
          p.new = RsaPubEncrypt(p.new);
          await UserApi.cPwd(p);
          this.$message.success("修改成功");
          this.param.old = "";
          this.param.new = "";
          this.again = "";
        } catch (e) {
          this.$message.error(e);
        }
      }
    },
  },
};
</script>
<style scoped>
/* 整体盒子 */
#UserCenter {
  width: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}

.content-main {
  width: 1280px;
  height: auto;
  position: relative;
  margin: auto;
  z-index: 2;
  overflow: hidden;
}
.more-user {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 30px;
}
.mu-left {
  width: 300px;
  height: auto;
  overflow: hidden;
  background: #fff;
  float: left;
  padding: 30px 0;
}
.mu-right {
  width: 960px;
  min-height: 800px;
  overflow: hidden;
  background: #fff;
  float: right;
  padding: 30px 0;
}
.mr-title {
  width: 93%;
  height: auto;
  margin: auto;
  border-left: solid 3px #000;
  height: 16px;
  line-height: 16px;
  padding-left: 1%;
}
.mr-title span {
  font-size: 14px;
  color: #999;
}
.mr-fcm {
  width: 89%;
  height: auto;
  overflow: hidden;
  background: #f9f9f9;
  padding: 2% 3%;
  margin: auto;
  margin-top: 30px;
}
.mf-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 30px;
}
.mf-one span {
  font-size: 14px;
}
.mf-one input {
  display: inline-block;
  background: #fff;
  border: solid 1px #eee;
  padding: 12px 10px;
  width: 250px;
}
.mf-btn {
  width: 160px;
  height: auto;
  overflow: hidden;
  text-align: center;
  background: #333;
  margin-top: 30px;
  margin-left: 56px;
  padding: 10px 0;
  color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
}
.mf-btn:hover {
  background: #000;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
</style>
