import store from '../store/index'

const isWechat = () => {
    const ua = window.navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == 'micromessenger';
}

const isLogin = () => {
    return localStorage.getItem('Authorization')
}

const domain = () => {
    return document.location.protocol + '//' + document.domain;
}


export const head = (title) => {

    title = title || "纹江致远统一招采协同平台系统";

    return {

        title: `${title} - 纹江致远统一招采协同平台系统`,

        meta: [
            {
                name: 'keywords',
                content: '纹江致远统一招采协同平台系统'
            },
            {
                name: 'description',
                content: '纹江致远统一招采协同平台系统'
            },
        ],
        link: [
            {
                rel: 'asstes',
                href: 'http://wc7.com'
            }
        ]
    }
}

export const toast = (msg) => {


    if (store.state.toast.show) {
        clearInterval(store.state.toast.timer);
        store.commit('toast/setShow', false);
        setTimeout(() => {
            toast(msg);
        }, 100);
        return;
    }

    store.commit('toast/setMsg', msg);

    store.commit('toast/setShow', true);

    store.commit('toast/setTimer', setTimeout(() => {
        store.commit('toast/setShow', false);
    }, 2300));

};


export const setCookie = (cname, cvalue) => {
    const d = new Date();
    d.setTime(d.getTime() + (9999 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + ';path=/;domain=.wc7.com';

}

export const getCookie = (name) => {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
        return unescape(arr[2]);
    else
        return null;
}

//删除cookies
export const delCookie = (name) => {
    console.log('123');
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (cval != null)
        document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString() + ';path=/;domain=.wc7.com';
}
//获取get参数

const getParams = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}


export default {
    install: function (Vue) {
        Vue.prototype.$isWechat = isWechat();
        Vue.prototype.$isLogin = isLogin;
        Vue.prototype.$domain = domain();
        Vue.prototype.$head = head;
        Vue.prototype.$toast = toast;
        Vue.prototype.$getCookie = getCookie;
        Vue.prototype.$delCookie = delCookie;
        Vue.prototype.$setCookie = setCookie;
        Vue.prototype.$getParams = getParams;
    }
}
