import axios from 'axios';
import axiosRetry from "axios-retry";


axiosRetry(axios, {
    retries: 2,                       // 设置自动发送请求次数
    retryDelay: (retryCount) => {
      return retryCount * 1000;      // 重复请求延迟
    },
    shouldResetTimeout: true,       //  重置超时时间
    retryCondition: (error) => {
        console.log(error.message)
      //true为打开自动发送请求，false为关闭自动发送请求
      if (error.message.includes("timeout")) {
        return true;
      }else if(error.message.includes("Network")){
        return true;
      } else {
        return false;
      };
    }
  });

import { Message, Loading } from 'element-ui';

export default class Base {

    //  请求域名
    static baseURL = "https://xcx.scwjzy.cn/index.php"
    // static baseURL = "http://192.168.0.114:5000"

    // 请求控制器
    static controller = ''

    // 成功状态码
    static successCode = [0]

    // 如果请求话费了超过 `timeout` 的时间，请求将被中断
    static timeout = 2000*20


    static getUrl(method = '') {
        method = method.startsWith('/') ? method : '/' + method
        let sessionid = sessionStorage.getItem('sessionid')
        return this.baseURL + this.controller + method + (sessionid ? `?jsessionid=${sessionid}` : '')
    }

    /**
     * 
     * @param {*} method     请求路径
     * @param {*} params     请求参数
     * @param {*} returnAll  是否返回直接返回res,默认返回res.data
     * @param {*} loading    是否显示loading
     * @param {*} formData   formData模式
     * @returns 
     */
    static async post(method, params = {}, returnAll = false, loading = false, formData = true,) {

        let url = this.getUrl(method)
        let Authorization = ''
        if (localStorage.getItem('token')) {
            Authorization = localStorage.getItem('token')
        }
        try {
            let { data: res } = await axios({
                url,
                method: 'post',
                transformRequest: [function (oldData) {
                    if (formData) {
                        let formData = new FormData()
                        let keys = Object.keys(oldData);
                        for (let i = 0; i < keys.length; i++) {
                            formData.append(keys[i], oldData[keys[i]])
                        }
                        return formData
                    } else {
                        return oldData
                    }
                }],
                data: params,
                timeout: this.timeout,
                headers: {
                    'x-token': Authorization
                }
            });


            if (returnAll) {
                if (res.code != 0) {
                    Message.error(res.msg);
                }
                return res;
            } else {
                if (this.successCode.includes(Number(res.code))) {
                    return res.data
                }
            }
        } catch (e) {
            throw new Error(e.response.data.message)
        }

    }


    static async json(method, params = {}, returnAll = false) {
        try {
            let url = this.getUrl(method)
            let Authorization = ''
            if (localStorage.getItem('token')) {
                Authorization = localStorage.getItem('token')
            }
            let {
                data: res
            } = await axios({
                url,
                method: 'post',
                headers: {
                    'x-Token': Authorization,
                    //'Signature': this.verification('POST', url),
                    "Content-Type": "application/json"
                },
                data: params,
            })
            if (returnAll) {
                if (res.status != 0) {
                    Message.error(res.msg);
                }
                return res;
            } else {
                if (this.successCode.includes(Number(res.code))) {
                    return res.data
                }
            }
        } catch (error) {
            throw new Error(error.response.data.message);
        }

    }

    static async get(method, params = {}, returnAll = false) {

        let url = this.getUrl(method)

        let Authorization = '';

        if (localStorage.getItem('token')) {
            Authorization = localStorage.getItem('token')
        }
        try {
            let { data: res } = await axios({
                method: 'get',
                url,
                headers: {
                    'x-token': Authorization
                },
                params,
                timeout: this.timeout
            })
            if (Number(res.code) == 0) {
                return res.data
            }
        } catch (e) {
            if (e.response&&e.response.data&&e.response.data.message == "登录超时") {
                localStorage.removeItem('token')
                location.reload()
                //self.location = "http://zc.scwjzy.cn/Index"
                //self.location = "http://192.168.31.125:8080/"
            }
        }
    }
}
