import Base from "./base";

export default class UploadApi extends Base {



    //文件上传
    static uFiles(param) {
        return super.post('/file/upload', param)
    }

    //文件加密上传
    static uFilesEnc(file) {
        return super.post('/file/encryptedUpload', {file})
    }


}
