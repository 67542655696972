// import JSEncrypt from "jsencrypt";
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'
import CryptoJS from "crypto-js";

const key = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQClDUln4L9UlKWP1M7YwP+hcJpqg0Ue1/GMDwylQdGh7o89DPhTdKQLPgJA0TnDn7MhLHfBZjcbXPjW3BjpXXrmuW/U8j+4q79ozjAhrBfPAaU52ymce/ZeSBUVqUpBZ9hv4qmjuObVdJ3iTwltt3OTPyFF66bwPVeBlkTMmRRpQQIDAQAB`;

// export function RsaPubEncrypt(data) {
//   let encryptStr = new JSEncrypt();
//   let now = Date.now().toString();
//   console.log(now)

//   encryptStr.setPublicKey(key);

//   return encryptStr.encrypt(`${data}-${now}`);
// }

export function RsaPubEncrypt(txt) {
  let now = Date.now().toString();
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(key) // 设置公钥
  return encryptor.encrypt(txt + "-" + now) // 对数据进行加密
}


export function md5(str) {
  return CryptoJS.MD5(str).toString();
}

export function hmacSha256(str, key) {
  return CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(str, key));
}

export function sign(method, url) {
  let encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(key);
  let path = "/pc" + url;
  let now = Date.now().toString();
  let nonce = md5(now);
  let agent = md5(navigator.userAgent);
  let message = `${method.toLocaleUpperCase()}\n${path}\n${now}\n${nonce}\n${agent}`;
  let authorization = encryptStr.encrypt(`${message}-${now}`);
  return {
    Timestamp: now,
    Authorization: authorization,
  };
}
