import Base from "./base";

export default class CompanyApi extends Base {



    //我的企业
    static myCompany(param) {
        return super.get('/companyInfo/business', param)
    }

    //供应商类型
    static comType(param) {
        return super.get('/procurementClassify/parent', param)
    }

    //创建商类型
    static createCom(param) {
        return super.json('/companyInfo/create', param)
    }


    //供应商详情
    static comDetail(param) {
        return super.get('/companyInfo/detail', param)
    }

    //编辑供应商
    static updateCom(param) {
        return super.json('/companyInfo/update', param)
    }

}
