<template>
    <div id="UserCenter">
        <div class="content-main">
            <!--下半部分-->
            <div class="more-user">
                <!--左边-->
                <div class="mu-left">
                    <Left></Left>
                </div>
                <!--右边-->
                <div class="mu-right">
                    <div class="mr-title">我的投标</div>
                    <div class="mr-order">
                        <el-table :data="bidlist" style="width: 100%; font-size: 12px" :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }">
                            <el-table-column prop="number" label="项目标题">
                                <template slot-scope="scope">
                                    <span @click="toInDetail(scope.row)" style="cursor: pointer;">{{ scope.row.project.title }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="money" label="投标日期">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.created_at }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="money" label="开标日期">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.project.bid_open_time }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="money" label="状态">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.project.status == 1">未开始</span>
                                    <span v-if="scope.row.project.status == 2">报名中</span>
                                    <span v-if="scope.row.project.status == 3">报名结束</span>
                                    <span v-if="scope.row.project.status == 4">已开标</span>
                                    <span v-if="scope.row.project.status == 5">已流标</span>
                                    <span v-if="scope.row.project.status == 6">已中标</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="money" label="操作">
                                <template slot-scope="scope">
                                    <el-link v-if="scope.row.project.status == 2" type="primary" @click="toTouAiain(scope.row)">编辑投标</el-link>
                                    <el-link v-if="scope.row.project.status != 2" type="primary" @click="toTouAiains(scope.row)">查看投标</el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!--分页-->
                    <div class="listpage" v-if="bidlist.length > 0">
                        <el-pagination :current-page="param.page" :page-size="param.size" layout="prev, pager, next" :total="numtotal" @current-change="handlePageChange"></el-pagination>
                    </div>
                    <!--上传招标文件-->
                    <el-dialog
                        width="80%"
                        title="项目投标"
                        top="8vh"
                        :close-on-click-modal="false"
                        :wrapperClosable="false"
                        :modal="true"
                        :modal-append-to-body="true"
                        :append-to-body="true"
                        :visible.sync="drawer"
                    >
                        <div class="signup-main">
                            <!--资格文件上传-->
                            <div class="s-main">
                                <div class="s-title">资格文件上传 - 必传项</div>
                                <div class="qua-one" v-for="(item, index) in qua" :key="index">
                                    <span>
                                        <label class="success-style" v-if="signparam.qua[index][item.ident]">已上传</label>
                                        <label class="success-styles" v-else>未上传</label>
                                        {{ item.name }}
                                    </span>
                                    <div @click="uploadindex(index)" v-if="!look">
                                        <el-upload class="c-image" action="" :on-change="upMemEntrImage" :show-file-list="false" :auto-upload="false">
                                            <i class="el-icon-plus el-icon-circle-plus" style="font-size: 24px"></i>
                                        </el-upload>
                                    </div>
                                </div>
                            </div>
                            <!--其他评分法-->
                            <div class="s-mains">
                                <div class="s-title">
                                    报价清单
                                    <span v-if="!look" for="" style="float: right;margin-right:30px">
                                        <UploadExcel :onSuccess="dy" />
                                    </span>
                                    <label v-if="!look" for="" @click="dc">导出报价清单</label>
                                </div>
                                <div style="margin-top: 20px;margin-bottom: 20px;">
                                    <el-table :data="signparam.bao" style="width: 100%">
                                        <el-table-column type="index" label="编号" width="80"></el-table-column>
                                        <el-table-column prop="wuzi" label="名称">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="danwei" label="规格(特征描述，参数说明)" width="70">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.guige }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="danwei" label="单位" width="70">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.danwei }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="danwei" label="数量" width="70">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.shuliang }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="danwei" label="控制价不含税单价（元）">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.nomore }}</span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column prop="danwei" label="不含税单价（元）">
                                            <template slot-scope="scope">
                                                <el-input
                                                    :disabled="look"
                                                    style="width: 90%"
                                                    type="number"
                                                    v-model="signparam.bao[scope.$index].unprice"
                                                    @blur="changePrice"
                                                    placeholder="请输入不含税单价"
                                                />
                                            </template>
                                        </el-table-column>
                                        <!-- <el-table-column prop="danwei" label="含税单价（元）">
                      <template slot-scope="scope">
                        <el-input style="width: 90%" disabled type="number" v-model="signparam.bao[scope.$index].inprice"
                          placeholder="该列自动计算" />
                      </template>
                    </el-table-column>
                    <el-table-column prop="danwei" label="税率（纯数字，不填写%号）">
                      <template slot-scope="scope">
                        <el-input style="width: 90%" :disabled="look" type="text"
                          v-model="signparam.bao[scope.$index].taxrate" @blur="changePrice" placeholder="请输入税率" />
                      </template>
                    </el-table-column>
                    <el-table-column prop="danwei" label="发票类型">
                      <template slot-scope="scope">
                        <el-input :disabled="look" style="width: 90%" type="text"
                          v-model="signparam.bao[scope.$index].invoicetype" placeholder="请输入发票类型" />
                      </template>
                    </el-table-column> -->
                                        <el-table-column prop="beizhu" label="备注" width="200">
                                            <template slot-scope="scope">
                                                <el-input :disabled="look" style="width: 90%" type="text" v-model="signparam.bao[scope.$index].beizhu" placeholder="请输入备注内容" />
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <div style="width:98%;height:auto;overflow:hidden;font-size:14px;text-align:right;font-weight: bold;margin-top: 20px; padding-right: 50px;">
                                        <!-- 含税总计：{{ parseFloat(hszj).toFixed(2) }}，不含税总计：{{ parseFloat(bhszj).toFixed(2) }}</div> -->
                                        不含税总计：{{ parseFloat(bhszj).toFixed(2) }}
                                    </div>
                                </div>
                            </div>
                            <!--附件上传-->
                            <div class="s-main">
                                <div class="s-title">投标文件 - 必传项</div>
                                <div class="qua-one" v-for="(item, index) in signparam.files" :key="index">
                                    <span style="font-size: 14px">
                                        {{ item ? item.substring(item.lastIndexOf('/') + 1) : '请上传附件' }}
                                        <i style="padding-left: 20px; cursor: pointer" @click="delFiles(index)" class="el-icon-circle-close" v-if="index > 0"></i>
                                    </span>
                                    <div @click="uploadindexs(index)" v-if="!look">
                                        <el-upload class="c-image" action="" :on-change="upMemEntrImages" :show-file-list="false" :auto-upload="false">
                                            <i class="el-icon-plus el-icon-circle-plus" style="font-size: 24px"></i>
                                        </el-upload>
                                    </div>
                                </div>
                                <el-link v-if="!look" type="primary" @click="addFiles" style="margin-top: 20px">添加附件</el-link>
                            </div>
                            <!--添加联系人-->
                            <div class="s-main">
                                <div class="s-title">添加联系人 - 至少添加一位联系人</div>
                                <div class="qua-one" v-for="(item, index) in signparam.contact" :key="index">
                                    <label>姓名：</label>
                                    <input type="text" :disabled="look" v-model="signparam.contact[index].name" placeholder="请输入联系人姓名" />
                                    <label>电话：</label>
                                    <input type="number" :disabled="look" v-model="signparam.contact[index].tel" placeholder="请输入联系人电话" />
                                    <i class="el-icon-circle-close" v-if="index > 0" style="cursor: pointer" @click="delPerson(index)"></i>
                                </div>
                                <el-link v-if="!look" type="primary" @click="addPerson" style="margin-top: 20px">添加联系人</el-link>
                            </div>
                            <div class="submit-sign" @click="submitSign" v-if="!look">提交投标</div>
                        </div>
                    </el-dialog>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UploadApi from '../../api/uploadApi';
import BidApi from '../../api/bidApi';
import Left from '../../components/Left.vue';
import UserApi from '../../api/userApi';
import UploadExcel from '../../components/UploadExcel';
export default {
    name: 'UserCenter',
    metaInfo() {
        return this.$head('我的投标');
    },
    data() {
        return {
            hszj: 0,
            bhszj: 0,
            dataCounts: null,
            isLogin: '',
            bidlist: [],
            param: {
                page: 1,
                size: 20,
            },
            numtotal: null,
            drawer: false,
            qua: [],
            signparam: {
                id: '',
                qua: [],
                price: {
                    inprice: '',
                    unprice: '',
                    taxrate: '',
                    invoicetype: '',
                },
                bao: [],
                files: [''],
                contact: [{ name: '', tel: '' }],
            },
            quaindex: null,
            fileindex: null,
            baojialist: [],
            look: false,
        };
    },
    components: {
        UploadExcel,
        Left,
    },
    watch: {
        drawer(n) {
            if (!n) {
                setTimeout(() => {
                    this.look = false;
                }, 200);
            }
        },
    },
    mounted() {},
    methods: {
        changePrice() {
            for (let i = 0; i < this.signparam.bao.length; i++) {
                if (this.signparam.bao[i].unprice && this.signparam.bao[i].taxrate) {
                    this.signparam.bao[i].inprice = (this.signparam.bao[i].unprice * (1 + this.signparam.bao[i].taxrate / 100)).toFixed(2);
                }
            }
            this.bhszj = 0;
            this.hszj = 0;
            //计算含税跟不含税总价
            for (let i = 0; i < this.signparam.bao.length; i++) {
                this.bhszj += parseFloat(this.signparam.bao[i].unprice * this.signparam.bao[i].shuliang);
                if (this.signparam.bao[i].inprice) {
                    this.hszj += parseFloat(this.signparam.bao[i].inprice * this.signparam.bao[i].shuliang);
                }
            }
        },
        toInDetail(item) {
            this.$router.push({ path: '/bid/detail', query: { id: item.project.id } });
        },
        //获取我的投标
        async getMyBid() {
            try {
                let res = await BidApi.myBid(this.param);
                this.bidlist = res.list;
                this.numtotal = res.total;
            } catch (error) {
                this.$message.error(error.message);
            }
        },
        //获取招标详情
        async getDetail(id) {
            try {
                let res = await BidApi.bidDetail({
                    id: id,
                });
                this.qua = res.json.pqd;
                for (let i = 0; i < this.qua.length; i++) {
                    let key = this.qua[i].ident;
                    this.signparam.qua.push({ [key]: '' });
                }
                //循环插入报价列表
                for (let k = 0; k < res.json.details.length; k++) {
                    let number = res.json.details[k].wid;
                    let newnumber = number.toString();
                    this.baojialist.push({
                        id: newnumber.substring(7),
                        name: res.json.details[k].name,
                        danwei: res.json.details[k].danwei,
                        nomore: res.json.details[k].nomore,
                        shuliang: res.json.details[k].shuliang,
                        inprice: '',
                        unprice: '',
                        taxrate: '',
                        invoicetype: '',
                        beizhu: res.json.details[k].beizhu,
                    });
                }
            } catch (error) {
                this.$message.error(error.message);
            }
        },
        toTouAiain(item) {
            if (item.project.status == 2) {
                this.$confirm('确定编辑上次的投标数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(async () => {
                    //可以重新投标
                    await this.getDetail(item.project.id);
                    await this.getToudetail(item.project.id);
                    this.signparam.id = item.project.id;
                    this.drawer = true;
                });
            } else {
                this.$message.error('已过投标期，不能重新投标');
            }
        },
        async toTouAiains(item) {
            //可以重新投标
            this.look = true;
            await this.getDetail(item.project.id);
            await this.getToudetail(item.project.id);
            this.signparam.id = item.project.id;
            this.look = true;
            this.drawer = true;
        },
        //获取投标详情
        async getToudetail(id) {
            try {
                let res = await BidApi.touDetail({
                    id: id,
                });
                this.signparam.bao = res.content.bao;
                this.signparam.contact = res.content.contact;
                this.signparam.files = res.content.files;
                this.signparam.qua = res.content.qua;
                this.bhszj = 0;
                this.hszj = 0;
                //计算含税跟不含税总价
                for (let i = 0; i < this.signparam.bao.length; i++) {
                    this.bhszj += parseFloat(this.signparam.bao[i].unprice * this.signparam.bao[i].shuliang);
                    if (this.signparam.bao[i].inprice) {
                        this.hszj += parseFloat(this.signparam.bao[i].inprice * this.signparam.bao[i].shuliang);
                    }
                }
            } catch (error) {
                this.$message.error(error.message);
            }
        },
        //导入报价
        dy(results) {
            let isImport = true;
            console.log(results);
            for (let i = 0; i < results.results.length; i++) {
                for (let j = 0; j < this.signparam.bao.length; j++) {
                    // if (parseFloat(results.results[i].税率) > 0) {
                    if (results.results[i].物资ID == this.signparam.bao[j].id) {
                        //   this.signparam.bao[j].inprice = (results.results[i].不含税价 * (1 + results.results[i].税率 / 100)).toFixed(2);

                        this.signparam.bao[j].unprice = results.results[i].不含税价;
                        console.log(results.results[i].不含税价);
                        console.log(this.signparam.bao[j].unprice);
                        // this.signparam.bao[j].taxrate = results.results[i].税率;
                        // this.signparam.bao[j].invoicetype = results.results[i].发票类型;
                        this.signparam.bao[j].beizhu = results.results[i].备注;
                    }
                }
                // } else {
                //   isImport = false
                //   this.$message.error('导入报价单税率只能为数字')
                //   break;
                // }
            }
            // }
            console.log(this.signparam.bao);
            if (isImport) {
                this.changePrice();
            }
        },
        //导出报价
        dc() {
            console.log(this.baojialist);
            import('@/vendor/Export2Excel').then(excel => {
                const tHeader = [
                    '物资ID',
                    '名称',
                    '单位',
                    '数量',
                    '控制价',
                    '不含税价',
                    // "税率",
                    // "发票类型",
                    '备注',
                ];
                const filterVal = [
                    'id',
                    'name',
                    'danwei',
                    'shuliang',
                    'nomore',
                    'unprice',
                    // "taxrate",
                    // "invoicetype",
                    'beizhu',
                ];
                const data = this.formatJson(filterVal, this.baojialist);
                excel.export_json_to_excel({
                    header: tHeader, //表头 必填
                    data, //具体数据 必填
                    filename: '报价清单', //非必填
                    autoWidth: true, //非必填
                    bookType: 'xlsx', //非必填
                });
            });
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map(v =>
                filterVal.map(j => {
                    return v[j];
                })
            );
        },
        delPerson(index) {
            if (index == 0) {
                this.$message.error('请至少添加一位联系人');
            } else {
                this.signparam.contact.splice(index, 1);
            }
        },
        delFiles(index) {
            if (index == 0) {
                this.$message.error('请至少添加一个附件');
            } else {
                this.signparam.files.splice(index, 1);
            }
        },
        async upMemEntrImages(file) {
            try {
                let res = await UploadApi.uFiles({
                    file: file.raw,
                });
                this.$set(this.signparam.files, this.fileindex, res);
                // this.signparam.files[this.fileindex] = res;
            } catch (e) {
                this.$message.error(e.message);
            }
        },
        addFiles() {
            this.signparam.files.push('');
        },
        addPerson() {
            this.signparam.contact.push({
                name: '',
                tel: '',
            });
        },
        async upMemEntrImage(file) {
            let pnglist = ['JPG', 'jpg', 'jpeg', 'png', 'PNG', 'gif', 'GIF', 'PNG'];
            let fname = file.name.substring(file.name.lastIndexOf('.') + 1);
            if (pnglist.indexOf(fname) > -1) {
                try {
                    let res = await UploadApi.uFiles({ file: file.raw });
                    let ident = [];
                    for (let i = 0; i < this.signparam.qua.length; i++) {
                        ident.push(Object.keys(this.signparam.qua[i]));
                    }
                    let kname = ident[this.quaindex][0];
                    this.signparam.qua[this.quaindex][kname] = res;
                } catch (e) {
                    this.$message.error(e.message);
                }
            } else {
                this.$message.error('仅支持PNG,JPG,GIF格式文件');
            }
        },
        uploadindex(index) {
            this.quaindex = index;
        },
        uploadindexs(index) {
            this.fileindex = index;
            console.log(index);
        },
        checkQua() {
            for (let i = 0; i < this.signparam.qua.length; i++) {
                for (let key in this.signparam.qua[i]) {
                    if (this.signparam.qua[i][key] == '') {
                        return false;
                    }
                }
            }
            return true;
        },
        checkFile() {
            for (let i = 0; i < this.signparam.files.length; i++) {
                if (this.signparam.files[i] == '') {
                    return false;
                }
            }
            return true;
        },
        checkPerson() {
            for (let i = 0; i < this.signparam.contact.length; i++) {
                for (let key in this.signparam.contact[i]) {
                    if (this.signparam.contact[i][key] == '') {
                        return false;
                    }
                }
            }
            return true;
        },
        checkBao() {
            for (let i = 0; i < this.signparam.bao.length; i++) {
                for (let key in this.signparam.bao[i]) {
                    if (this.signparam.bao[i]['unprice'] == '' || this.signparam.bao[i]['unprice'] == null) {
                        this.$message.error('请完善' + this.signparam.bao[i].name + '的报价清单');
                        return false;
                    }
                }
            }
            return true;
        },
        checkMore() {
            for (let i = 0; i < this.signparam.bao.length; i++) {
                if (parseFloat(this.signparam.bao[i].unprice) > parseFloat(this.signparam.bao[i].nomore)) {
                    this.$message.error(this.signparam.bao[i].name + '的报价高于控制价');
                    return false;
                }
            }
            return true;
        },
        //投标
        async submitSign() {
            //检测是否有资格文件没有上传
            if (!this.checkQua()) {
                this.$message.error('请上传资格文件');
            } else if (!this.checkFile()) {
                //检测联系人信息
                this.$message.error('请上传招标附件');
            } else if (!this.checkPerson()) {
                //检测联系人信息
                this.$message.error('请完善联系人信息');
            } else {
                if (this.checkBao()) {
                    console.log(1);
                    if (this.checkMore()) {
                        console.log(2);
                        try {
                            await BidApi.bidSign(this.signparam);
                            this.drawer = false;
                            this.$message.success('投标成功');
                            this.drawer = false;
                            this.signparam.bao = [];
                            this.signparam.files = [''];
                            this.signparam.contact = [{ name: '', tel: '' }];
                            this.$router.push('/UserBid');
                        } catch (error) {
                            this.$message.error(error.message);
                        }
                    }
                }
            }
        },
        handlePageChange(page) {
            this.param.page = page;
            this.getMyBid();
        },
    },
    async created() {
        this.isLogin = localStorage.getItem('token');
        if (this.isLogin) {
            await this.getMyBid();
        } else {
            this.bidlist = [];
        }
    },
};
</script>
<style>
.signup-main::-webkit-scrollbar {
    display: none !important;
}

.el-dialog {
    margin-top: 8vh !important;
}

.el-drawer__wrapper::-webkit-scrollbar {
    display: none;
}
</style>
<style scoped>
/* 整体盒子 */
#UserCenter {
    width: 100%;
    min-height: 100vh;
    background: #f2f2f2;
}

.content-main {
    width: 1280px;
    height: auto;
    position: relative;
    margin: auto;
    z-index: 2;
    overflow: hidden;
}

.more-user {
    width: 1280px;
    height: auto;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 30px;
}

.mu-left {
    width: 300px;
    height: auto;
    overflow: hidden;
    background: #fff;
    float: left;
    padding: 30px 0;
}

.mu-right {
    width: 960px;
    min-height: 800px;
    overflow: hidden;
    background: #fff;
    float: right;
    padding: 30px 0;
}

.mr-title {
    width: 93%;
    height: auto;
    margin: auto;
    border-left: solid 3px #000;
    height: 16px;
    line-height: 16px;
    padding-left: 1%;
}

.mr-title span {
    font-size: 14px;
    color: #999;
}

.mr-order {
    width: 94%;
    height: auto;
    overflow: hidden;
    background: #f9f9f9;
    margin: auto;
    margin-top: 60px;
}

.listpage {
    width: 100%;
    height: auto;
    overflow: hidden;
    text-align: center;
    padding: 20px 0;
}

.signup-main {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 20px;
}

.s-main {
    width: 50%;
    height: auto;
    overflow: hidden;
    margin-bottom: 35px;
}

.s-title {
    width: 100%;
    height: auto;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
}

.s-title label {
    display: block;
    float: right;
    margin-left: 20px;
    color: #fff;
    background: #e52d27;
    cursor: pointer;
    border-radius: 3px;
    padding: 6px 15px;
    font-size: 12px;
}

.qua-one {
    width: calc(99% - 40px);
    height: auto;
    overflow: hidden;
    border: solid 1px #f2f2f2;
    margin-top: 20px;
    padding: 20px;
}

.qua-one span {
    float: left;
    display: block;
    color: #666;
}

.c-image {
    display: block;
    float: right;
}

.success-style {
    width: auto;
    font-size: 12px;
    background: green;
    color: #fff;
    padding: 3px 8px;
}

.success-styles {
    width: auto;
    font-size: 12px;
    background: rgb(84, 84, 84);
    color: #fff;
    padding: 3px 8px;
}

.submit-sign {
    width: 200px;
    height: 54px;
    background: #9e241d;
    color: #fff;
    cursor: pointer;
    text-align: center;
    line-height: 54px;
    margin-top: 50px;
}

.submit-sign:hover {
    background: #d1372f;
}

.qua-one input {
    display: inline-block;
    padding: 8px 10px;
    margin-right: 15px;
    border: solid 1px #f2f2f2;
}

.que-main {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.que-main textarea {
    display: block;
    width: calc(100% - 20px);
    border: 0;
    background: #eee;
    padding: 10px;
    font-size: 14px;
}

.q-list {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 30px;
}

.q-one {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 20px;
    border-bottom: dashed 1px #f2f2f2;
    padding-bottom: 20px;
}

.q-question {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.q-question span {
    width: 20px;
    height: 20px;
    background: #04be02;
    color: #fff;
    font-size: 12px;
    text-align: center;
    display: block;
    line-height: 20px;
    float: left;
}

.q-question label {
    display: block;
    width: calc(100% - 30px);
    height: auto;
    float: right;
    font-size: 14px;
    line-height: 22px;
}

.bu-one {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 10px 0;
    cursor: pointer;
}

.bu-one:hover {
    color: #e52d27;
}

.bu-one span {
    float: right;
    font-size: 14px;
    color: #999;
}
</style>
