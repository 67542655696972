<template>
  <div id="HomePage">
    <!--招标信息-->
    <div class="block-place">
      <div class="block-box">
        <!--分类-->
        <div class="typelist">
          <div class="typetitle">
            | 条件筛选
            <!--搜索-->
            <div class="search">
              <div class="sinput">
                <el-input v-model="inname" clearable placeholder="请输入招标项目标题"></el-input>
              </div>
              <span
                style="display:inline-block;margin-left:15px;font-size:14px;background:#9E241D; color:#fff; padding:10px 20px; border-radius: 6px; cursor: pointer;"
                @click="getBidlist">搜索</span>
            </div>
          </div>
          <div class="type-one">
            <div class="to-title">招标分类：</div>
            <div style="width:calc(100% - 100px); float:left:height:auto; overflow:hidden">
              <div class="tt-one" v-for="(item, index) in typelist" :key="index">
                <label :class="param.tid == v.id ? 'active' : ''" @click="chooseTid(v)" v-for="(v, k) in item.children"
                  :key="k">{{ v.name }}</label>
              </div>
            </div>
          </div>
          <div class="type-one">
            <div class="to-title">招标状态：</div>
            <div class="choose-status">
              <el-radio-group style="float: left; margin-top: 5px" v-model="status">
                <el-radio :label="0">全部招标</el-radio>
                <el-radio :label="1">未开始</el-radio>
                <el-radio :label="2">报名中</el-radio>
                <el-radio :label="3">报名结束</el-radio>
                <el-radio :label="4">已开标</el-radio>
                <el-radio :label="5">已流标</el-radio>
                <el-radio :label="6">已中标</el-radio>
                <el-radio :label="7">终止招标</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <!--所有询价-->
        <div class="in-list">
          <!--无数据-->
          <div class="no-data" style="padding-top: 200px" v-if="bList.length == 0">
            <el-empty description="- 暂无数据 -"></el-empty>
          </div>
          <!--招标信息-->
          <div class="block-boxss">
            <!--所有询价-->
            <div class="in-list" v-if="bList.length > 0">
              <div class="in-header">
                <span style="
                    width: calc(40% - 20px);
                    text-align: left;
                    padding-left: 20px;
                  ">招标名称</span>
                <span style="width: 20%; text-align: left">招标单位</span>
                <span style="width: 15%; text-align: left">报名开始日期</span>
                <span style="width: 15%; text-align: left">报名截止日期</span>
                <span style="width: 10%; text-align: left">状态</span>
              </div>
              <div class="in-one" @click="toDetail(item)" v-for="(item, index) in bList" :key="index">
                <div style="
                    width: calc(40% - 20px);
                    text-align: left;
                    padding-left: 20px;
                  ">
                  {{ item.title }}
                </div>
                <div style="width: 20%; text-align: left">
                  {{ item.gongsi?item.gongsi:'未设置' }}
                </div>
                <div style="width: 15%; text-align: left">
                  {{ item.bidding_start_time }}
                </div>
                <div style="width: 15%; text-align: left">
                  {{ item.bidding_end_time }}
                </div>
                <div style="width: 10%; text-align: left" v-if="item.bid_open==0">
                  <span style="font-weight: bold" v-if="item.status == 1">未开始</span>
                  <span style="font-weight: bold; color: #eb1d24" v-if="item.status == 2">报名中</span>
                  <span style="font-weight: bold; color: #666666" v-if="item.status == 3">报名结束</span>
                  <span style="font-weight: bold; color: #2c2cb5" v-if="item.status == 4">已开标</span>
                  <span style="font-weight: bold" v-if="item.status == 5">已流标</span>
                  <span style="font-weight: bold" v-if="item.status == 6">已中标</span>
                  <span style="font-weight: bold" v-if="item.status == 7">终止招标</span>
                </div>
                <div style="width: 10%; text-align: left" v-if="item.bid_open==1">
                  <span style="font-weight: bold">该项目为线下开标</span>
                </div>
              </div>
            </div>
          </div>

          <!--分页-->
          <div class="listpage" v-if="bList.length > 0">
            <el-pagination :current-page="param.page" :page-size="param.size" layout="prev, pager, next"
              :total="numtotal" @current-change="handlePageChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RsaPubEncrypt } from "../../util/cipher";
import BidApi from "../../api/bidApi";
import UserApi from "../../api/userApi";
import NewsApi from "../../api/newsApi";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "HomePage",
  metaInfo() {
    return this.$head("招标列表-电子招标采购平台-四川纹江致远建筑开发工程有限公司");
  },
  data() {
    return {
      userinfo: {},
      isLogin: "",
      show: false,
      param: {
        account: "",
        password: "",
      },
      status: 0,
      inname: "",
      param: {
        page: 1,
        size: 15,
        tid: "",
        status: "",
        name: "",
      },
      numtotal: null,
      bList: [],
      bannerList: [
        {
          id: 0,
          url: "http://builderp.cn/DYJGJTJC/images/2020Theme/index_top2.jpg",
        },
      ],
      typelist: [],
      selecttempid: "",
      selecttempids: "",
      inList: [],
      dicts: {
        0: {
          label: "未启用",
          color: "#333333",
        },
        1: {
          label: "未开始",
          color: "#25b864",
        },
        2: {
          label: "招标中",
          color: "#EB1D24",
        },
        3: {
          label: "招标结束",
          color: "#747DA1",
        },
        4: {
          label: "已开标",
          color: "#2c2cb5",
        },
        5: {
          label: "已流标",
          color: "#747DA1",
        },
      },
      hotGame: [],
      hotGmaeId: [],
      hotGameNews: [],
      allGame: [],
      userDetail: {},
      opendSer: [],
      newsSer: [],
      myGame: [],
      newslist: [],
      platform: "2",
      allPhoneGame: [],
      codeUrl: location.origin + "/MobileGameDown",
      page: 1,
      size: 20,
      aid: "",
    };
  },
  components: {
  },
  computed: {
    ...mapState("game", ["gameList"]),
  },
  watch: {
    inname(n) {
      if (n) {
        this.param.name = n;
      } else {
        this.param.name = "";
        this.getBidlist();
      }
    },
    status(n) {
      if (n != 0) {
        this.param.status = n;
      } else {
        this.param.status = null;
      }
      this.param.page = 1;
      this.param.size = 10;
      this.getBidlist();
    },
  },
  methods: {
    ...mapActions("user", ["login", "getUserInfo"]),
    ...mapMutations("user", ["loginOut"]),
    //获取banner列表
    async getBannerList() {
      let res = await BannerApi.getBanner();
      this.bannerList = res;
    },
    //用户登录
    toLogin() {
      this.$store.commit("login/setShow", true);
      this.$store.commit("login/setType", "accountReg");
    },
    async userLogin() {
      if (!this.param.account) {
        this.$message.error("请输入账号或手机号");
      } else if (!this.param.password) {
        this.$message.error("请输入密码");
      } else {
        let form = { ...this.param };
        form.password = RsaPubEncrypt(form.password);
        try {
          let res = await UserApi.uLogin(form);
          this.$message.success("登录成功");
          localStorage.setItem("token", res.token);
          this.userinfo = res.user;
          this.isLogin = res.token;
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },
    //获取用户信息
    async getUSerinfo() {
      let res = await UserApi.user();
      this.userinfo = res;
    },
    toUser() {
      location.href = "/UserCenter";
    },
    //获取招标询价分类
    async getAlltype() {
      let res = await BidApi.allType();
      this.typelist = res;
    },
    async chooseTid(item) {
      this.param.tid = item.id;
      await this.getBidlist();
    },
    //获取招标列表
    async getBidlist() {
      let res = await BidApi.bidList(this.param);
      this.bList = res.list;
      this.numtotal = res.total;
    },
    handlePageChange(page) {
      this.param.page = page;
      this.getBidlist();
    },
    toDetail(item) {
      this.$router.push({ path: "/bid/detail", query: { id: item.id } });
    },
  },
  async created() {
    if (localStorage.getItem("token")) {
      this.isLogin = localStorage.getItem("token");
      await this.getUSerinfo();
    }
    await this.getAlltype();
    await this.getBidlist();
  },
};
</script>
<style scoped>
/* 整体盒子 */
#HomePage {
  width: 100%;
}

.block-place {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin: auto;
}

.title {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
}

.title span {
  font-size: 16px;
  float: right;
  display: block;
  font-weight: normal !important;
  color: #999;
  padding-top: 8px;
  cursor: pointer;
}

.title span:hover {
  color: #000;
}

.block-box {
  width: calc(100% - 60px);
  min-height: 600px;
  overflow: hidden;
  background: #fff;
  padding: 30px;
  margin: 20px 0 40px 0;
}

.block-boxss {
  width: 100;
  min-height: 800px;
  overflow: hidden;
  background: #fff;
}

.typelist {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.active {
  color: #9e241d !important;
  font-weight: bold;
}

.in-list {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 20px;
}

.in-header {
  width: calc(100% - 2px);
  height: auto;
  overflow: hidden;
  background: #f2f2f2;
  border-top: solid 1px #d5d5d5;
  border-left: solid 1px #d5d5d5;
  border-right: solid 1px #d5d5d5;
}

.in-header>span {
  float: left;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #666;
  padding: 15px 0;
}

.in-one {
  width: calc(100% - 2px);
  height: auto;
  overflow: hidden;
  border-left: solid 1px #d5d5d5;
  border-right: solid 1px #d5d5d5;
  border-bottom: solid 1px #d5d5d5;
}

.in-one>div {
  float: left;
  text-align: center;
  font-size: 14px;
  padding: 25px 0 25px 0;
  cursor: pointer;
}

.in-one:hover {
  background: #f2f9fe;
}


.bid-title {
  font-size: 18px;
}

.in-one:hover .bid-title {
  color: #9e241d;
}

.typetitle {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-weight: bold;
  padding-bottom: 30px;
}

.tt-one {
  width: auto;
  height: auto;
  overflow: hidden;
  float: left;
}

.tt-one label {
  display: inline-block;
  margin-right: 60px;
  cursor: pointer;
  color: #666;
  margin-bottom: 20px
}

.tt-one label:hover {
  color: #000;
}

.tt-one span {
  display: block;
  color: #9e241d;
  font-weight: bold;
}

.small-box {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.small-box label {
  display: inline-block;
  font-size: 14px;
  background: #f2f2f2;
  border: solid 1px #f2f2f2;
  padding: 6px 20px;
  margin-right: 20px;
  cursor: pointer;
  margin-top: 15px;
}

.small-box label:hover {
  border: solid 1px #e2e2e2;
}

.bid-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-weight: bold;
  font-size: 18px;
}

.bid-desc {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  padding: 20px 0;
}

.bid-desc label {
  flex: 1;
  font-size: 14px;
}

.bid-desc label b {
  display: inline-block;
  padding: 0 3px;
  font-weight: bold;
  color: #9e241d;
}

.bid-date {
  font-size: 14px;
  color: #ccc;
}

.bid-date i {
  display: inline-block;
  padding-right: 5px;
}

.listpage {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  padding: 20px 0;
}

.choose-status {
  width: calc(100% -500px);
  height: auto;
  overflow: hidden;
}

.search {
  width: auto;
  float: right;
}

.sinput {
  display: block;
  float: left;
}

.search i {
  display: block;
  float: left;
  font-size: 30px;
  margin-left: 10px;
  margin-top: 2px;
  color: #999;
  cursor: pointer;
}

.search i:hover {
  color: #666;
}

.type-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-bottom: solid 1px #eee;
  padding-bottom: 20px;
  margin-top: 20px;
}

.type-one:last-child {
  border: 0;
}

.to-title {
  width: 100px;
  float: left;
  font-weight: bold;
}
</style>
