import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import toast from './modules/toast';
import user from './modules/user';
import login from './modules/login';

export default new Vuex.Store({
    state: {
        toastShow: false
    },
    mutations: {

    },
    actions: {

    },
    modules: {
        toast,
        user,
        login,
    }
})
