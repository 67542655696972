import Base from "./base";

export default class BidApi extends Base {



    //招标分类
    static allType(param) {
        return super.get('/procurementClassify/cascade', param)
    }


    //询价列表
    static inquiryList(param) {
        return super.get('/inquirySupplier/inquiry_list', param)
    }

     //招标列表
     static bidList(param) {
        return super.get('/procurementList/announcement', param)
    }

    //招标详情
    static bidDetail(param) {
        return super.get('/procurementList/detail', param)
    }

    //投标
    static bidSign(param) {
        return super.json('/procurementList/tender', param)
    }

    //我的投标
    static myBid(param) {
        return super.get('/procurementList/myRecord', param)
    }

    //询价详情
    static inDetail(param) {
        return super.get('/inquirySupplier/get_inquiry_details', param)
    }

    //询价报价
    static inSubmit(param) {
        return super.json('/inquirySupplier/inquiry_offer', param)
    }

    //我的询价
    static myIn(param) {
        return super.get('/inquirySupplier/get_self_offer_list', param)
    }

    //中标公示
    static proPublic(param) {
        return super.get('/procurementPublic/list', param)
    }

    //公示详情
    static proDetail(param) {
        return super.get('/procurementPublic/detail', param)
    }

    //提交问题
    static sQuestion(param) {
        return super.post('/procurementMentor/publish', param)
    }

    //我的提问
    static mQuestion(param) {
        return super.get('/procurementMentor/mine', param)
    }

    //补遗列表
    static addendumList(param) {
        return super.get('/procurementClarify/list', param)
    }

    //补遗详情
    static buDetail(param) {
        return super.get('/procurementClarify/detail', param)
    }


    //报价详情
    static inDetailinfo(param) {
        return super.get('/inquirySupplier/get_offer_by_supplier', param)
    }

    //邀请招标
    static inviBid(param) {
        return super.get('/procurementList/invitedTender', param)
    }

    //banner列表
    static bannerList(param) {
        return super.get('/banner/all', param)
    }

    //获取我的投标信息
    static touDetail(param) {
        return super.get('/procurementList/supplier', param)
    }

    //获取我的澄清通知
    static myTongzhi(param) {
        return super.get('/procurementClarifyNotice/mine', param)
    }

     //回复通知
     static huiTongzhi(param) {
        return super.post('/procurementClarifyNotice/rep', param)
    }

    
}
