<template>
  <div id="HomePage">
    <!--招标信息-->
    <div class="block-place">
      <div class="block-box">
        <!--顶部-->
        <div class="top-bg">
          <span>{{cloumnname}}</span>
        </div>
        <!--基础内容-->
        <!-- <div class="news-search">
          <span @click="searchbtn">搜索</span>
          <el-input class="inpu" placeholder="请输入关键词" v-model="title" clearable>
          </el-input>
        </div> -->
        <div class="content">
          <div class="p-one" @click="toDetail(item)" v-for="(item, index) in datalist" :key="index">
            {{ item.title }}<label for="">{{
            item.created_at
            }}</label>
          </div>
          <!--无数据-->
          <div class="no-data" style="padding-top:200px" v-if="datalist.length==0">
            <el-empty description="- 暂无数据 -"></el-empty>
          </div>
          <div class="page" v-if="datalist.length>0">
            <el-pagination :current-page="params.page" :page-size="params.size" layout="prev, pager, next"
              :total="datacount" @current-change="handlePageChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RsaPubEncrypt } from "../../util/cipher";
import BidApi from "../../api/bidApi";
import UserApi from "../../api/userApi";
import NewsApi from "../../api/newApi";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "HomePage",
  metaInfo() {
    return this.$head("询价详情-电子招标采购平台-四川纹江致远建筑开发工程有限公司");
  },
  data() {
    return {
      bid: "",
      biddetail: {},
      userinfo: {},
      isLogin: "",
      show: false,
      param: {
        account: "",
        password: "",
      },
      count: "", //倒计时
      seconds: null, // 10天的秒数
      status: 0,
      timeshow: true,
      params: {
        id: '',
        title: '',
        page: 1,
        size: 20,
      },
      title: '',
      plist: [],
      datacount: null,
      cid: null,
      datalist: [],
      cloumnname: ''
    };
  },
  components: {},
  computed: {
    ...mapState("game", ["gameList"]),
  },
  watch: {
    title(n) {
      if (n) {
        this.params.title = n
      } else {
        this.params.title = ""
        this.getList()
      }
    }
  },
  mounted() {
    this.Time(); //调用定时器
  },
  methods: {
    async searchbtn() {
      await this.getList()
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      d = d < 10 ? "0" + d : d;
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      s = s < 10 ? "0" + s : s;
      this.count = d + "天" + h + "时" + m + "分" + s + "秒";
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        this.seconds -= 1;
        this.countDown();
      }, 1000);
    },
    ...mapActions("user", ["login", "getUserInfo"]),
    ...mapMutations("user", ["loginOut"]),
    //用户登录
    toLogin() {
      this.$store.commit("login/setShow", true);
      this.$store.commit("login/setType", "accountReg");
    },
    async userLogin() {
      if (!this.param.account) {
        this.$message.error("请输入账号或手机号");
      } else if (!this.param.password) {
        this.$message.error("请输入密码");
      } else {
        let form = { ...this.param };
        form.password = RsaPubEncrypt(form.password);
        try {
          let res = await UserApi.uLogin(form);
          this.$message.success("登录成功");
          localStorage.setItem("token", res.token);
          this.userinfo = res.user;
          this.isLogin = res.token;
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },
    //获取用户信息
    async getUSerinfo() {
      let res = await UserApi.user();
      this.userinfo = res;
    },
    //获取所有栏目
    async getAllCloumn() {
      let res = await NewsApi.allCloumn()
      for (let i = 0; i < res.length; i++) {
        if (res[i].id == this.cid) {
          this.cloumnname = res[i].name
        }
      }
    },
    //获取新闻列表
    async getList() {
      let res = await NewsApi.allNews(this.params);
      this.datalist = res.list;
      this.datacount = res.total
    },
    async handlePageChange(page) {
      this.params.page = page;
      await this.getList();
    },
    toDetail(item) {
      this.$router.push({ path: '/news/detail', query: { id: item.id } })
    }
  },
  async created() {
    //获取栏目ID
    if (this.$route.query.id) {
      this.cid = this.$route.query.id;
      this.params.id = this.$route.query.id;
    }
    if (localStorage.getItem("token")) {
      this.isLogin = localStorage.getItem("token");
      await this.getUSerinfo();
    }
    await this.getList();
    await this.getAllCloumn()
  },
};
</script>
<style scoped>
/* 整体盒子 */
#HomePage {
  width: 100%;
}

.block-place {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin: auto;
}

.block-box {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 20px 0 40px 0;
}

.top-bg {
  width: 1240px;
  height: 120px;
  background-image: url("../../assets/img/bbg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  line-height: 120px;
  padding: 0 20px;
}

.top-bg span {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.top-bg label {
  display: block;
  background: #f08616;
  cursor: pointer;
  color: #fff;
  float: right;
  width: 120px;
  height: 46px;
  line-height: 46px;
  margin-top: 37px;
  text-align: center;
}

.top-bg label:hover {
  background: #f59834;
}

.content {
  width: calc(100% - 40px);
  min-height: 800px;
  overflow: hidden;
  background: #fff;
  padding: 10px 20px;
}

.p-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 20px 0;
  border-bottom: solid 1px #f9f9f9;
  cursor: pointer;
  font-size: 18px;
}

.p-one label {
  float: right;
  color: #666;
  font-size: 14px;
}

.p-one:hover {
  color: #e52d27;
}

.page {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  padding-top: 30px;
}

.news-search {
  width: calc(100% - 40px);
  height: auto;
  overflow: hidden;
  background: #fff;
  margin-top: 20px;
  padding: 20px 20px 10px 20px;
}

.inpu {
  display: block;
  float: right;
  height: 40px;
  line-height: 40px;
  width: 200px;
  border-radius: 8px;
  padding: 0 10px;
}

.news-search span {
  display: block;
  float: right;
  background: rgb(158, 36, 29);
  border-radius: 6px;
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
  height: 40px;
  width: 80px;
  color: #fff;
  text-align: center;
  line-height: 40px;
}

.news-search span:hover {
  background: rgb(194, 53, 46);
}
</style>
