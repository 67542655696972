<template>
  <div id="left" class="container-fluid">
    <div class="left-main">
      <!--用户信息-->
      <div class="left-user">
        <div class="user-logo">
          <img
            src="https://srm.cnzgc.com/static/portal/ec2021M8/style/images/zjttx.png"
          />
        </div>
        <div class="user-accou">{{ userinfo.account }}</div>
      </div>
      <div
        :class="muid == item.mid ? 'menu-one active' : 'menu-one'"
        @click="turnMenu(item)"
        v-for="(item, index) in menuList"
        :key="index"
      >
        <i :class="'iconfont ' + item.micon"></i>
        <span>{{ item.mname }} <b v-if="item.num>0">{{item.num}}</b> </span>
      </div>
    </div>
  </div>
</template>
<script>
import UserApi from "../api/userApi";
import BidApi from "../api/bidApi";
export default {
  name: "left",
  data() {
    return {
      userinfo: {},
      muid: "0",
      menuList: [
        {
          mid: "0",
          mname: "基本资料",
          micon: "icon-icon-active",
          murl: "/UserCenter",
          num:''
        },
        {
          mid: "1",
          mname: "我的企业",
          micon: "icon-yonghu",
          murl: "/UserCompany",
          num:''
        },
        {
          mid: "2",
          mname: "我的投标",
          micon: "icon-youxiyouxitingyouxijiyouxishoubingxianxing",
          murl: "/UserBid",
          num:''
        },
        {
          mid: "3",
          mname: "我的询价",
          micon: "icon-wenti",
          murl: "/UserIn",
          num:''
        },
        {
          mid: "4",
          mname: "邀请招标",
          micon: "icon-youxiyouxitingyouxijiyouxishoubingxianxing",
          murl: "/InvitationBid",
          num:''
        },
        {
          mid: "5",
          mname: "修改密码",
          micon: "icon-xiaoxi",
          murl: "/UserChangePwd",
          num:''
        },
        {
          mid: "6",
          mname: "退出登录",
          micon: "icon-xiaoxi",
          murl: "",
          num:''
        },
      ],
    };
  },
  methods: {
    //获取用户信息
    async getUSerinfo() {
      let res = await UserApi.user();
      this.userinfo = res;
    },
    //获取我的投标
    async getMyBid() {
      let res = await BidApi.inviBid(this.param);
        console.log(res)
        this.menuList[4].num = res.total
    },
    turnMenu(item) {
      if(item.mid==6){
        this.$message.success('退出成功')
        localStorage.clear()
        this.$router.push('/')
      }else{
        location.href = item.murl;
      }
    },

    setMenu() {
      let path = this.$route.path.replace("/", "").replace("/", "");

      const menu = this.menuList.find((v) => v.murl.includes(path));
      if(menu&&menu.mid){
          this.muid = menu.mid
      }else{
          this.muid = "0"
      }
    },
  },
  async created() {
    this.setMenu();
    if(localStorage.getItem("token")){
      this.isLogin = localStorage.getItem("token")
      await this.getUSerinfo();
      await this.getMyBid()
    }
  },
};
</script>
<style scoped>
#left {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.menu-one {
  width: 240px;
  height: auto;
  overflow: hidden;
  padding: 25px 30px;
  color: #555;
  cursor: pointer;
}

.menu-one:hover {
  background: #f9f9f9;
}

.menu-one i {
  padding-right: 10px;
}

.menu-one span {
  letter-spacing: 2px;
}
.menu-one b{display: inline-block;width: 16px; height:16px; background: #e52d27; border-radius: 100px; text-align: center; color:#fff; line-height: 16px; font-size: 12px;}
.active {
  background: #eee;
  border-right: solid 5px #e52d27;
  width: 235px;
  padding: 25px 30px;
  color: #000;
}
.user-logo {
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 100px;
}
.user-logo img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
}
.user-accou {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  padding: 15px 0 50px 0;
}
</style>
