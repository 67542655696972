<template>
	<div id="footer" class="container-fluid">
		<div class="f-main">
			<div class="f-left">
				<div class="link">
					版权所有：四川纹江致远建筑开发工程有限公司<span style="display:inline-block;width:20px"></span>电话：0838-3273592<span style="display:inline-block;width:20px"></span>地址：罗江区雨村东路北段（原国土资源局）
				</div>
				<div class="other-link">
					<a href="http://www.beian.gov.cn/portal/index.do" target="_blank">
						<label>备案号： 蜀ICP备2021027257号-1</label>
					</a>
					<span style="float:right">平台技术支持：骏杰科技<span style="display:inline-block;width:1px"></span>
						联系电话：0838-2221680<span style="display:inline-block;width:1px"></span>QQ咨询：754737912@qq.com</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Footer",
	data() {
		return {};
	},
	methods: {
		toFang() {
			this.$router.push('/SysOther')
			localStorage.setItem('leftId', '0')
		},
		toSafe() {
			this.$router.push('/SysSafe')
			localStorage.setItem('leftId', '1')
		},
		toFamily() {
			this.$router.push('/SysFamily')
			localStorage.setItem('leftId', '2')
		},
		toGamebad() {
			this.$router.push('/SysGamebad')
			localStorage.setItem('leftId', '3')
		}
	}
};
</script>
<style scoped>
#footer {
	width: 100%;
	height: 100%;
	color: #fff;
	background: #fff;
	overflow: hidden;
	position: relative;
	z-index: 2;
	border-top: solid 1px #eee;
}

.f-main {
	width: 1280px;
	text-align: left;
	height: auto;
	overflow: hidden;
	margin: auto;
	padding: 30px 0 15px 0;
	position: relative;
}

.f-left {
	width: 100%;
	height: auto;
	overflow: hidden;
	font-size: 12px;
	color: #ccc;
}

.link {
	padding-bottom: 10px;
	width: auto;
	color: #333
}

.other-link {
	width: 100%;
	padding-top: 10px;
	height: auto;
	overflow: hidden;
}

.other-link span {
	display: inline-block;
	padding-right: 40px;
	cursor: pointer;
	color: #333
}

.other-link a {
	display: inline-block;
	width: auto;
	height: 30px;
	color: #333;
	cursor: pointer;
}

.other-link img {
	width: 30px;
	height: 30px;
	float: left
}

.other-link label {
	width: auto;
	padding-right: 30px;
	padding-top: 10px;
	color: #333 !important;
	display: block;
	float: left
}

.gametip {
	padding-top: 15px;
	color: #999;
	font-size: 12px;
}

.f-right {
	width: 440px;
	height: auto;
	overflow: hidden;
	float: right;
	text-align: right;
}

.f-logo {
	width: 150px;
	height: auto;
	float: right
}

.f-logo img {
	width: 150px;
	height: auto;
	-webkit-filter: grayscale(100%);
	/* Chrome, Safari, Opera */
	filter: grayscale(100%);
}

.age {
	width: 70px;
	height: auto;
	margin: auto;
	margin-top: 20px;
	position: absolute;
	right: 0;
	top: 10px
}

.age img {
	width: 70px;
	height: auto;
}

.container-fluid {
	margin-top: 50px;
}
</style>
