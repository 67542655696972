<template>
  <div id="app">
    <Header v-if="!$route.meta.hideHeader"></Header>
    <router-view></router-view>
    <Toast></Toast>
    <Login></Login>
    <Footer v-if="!$route.meta.hideFooter"></Footer>
  </div>
</template>

<script>
import Header from '../src/components/Header'
import Footer from '../src/components/Footer'
import Toast from "../src/components/Toast"
import Login from "../src/components/Login"
export default {

  data() {
    return {

    }
  },
  components: {
    Toast,Login,Header,Footer
  },

  created() {

  },

  mounted() {

  },
  methods: {

  },

}
</script>

<style >
</style>
