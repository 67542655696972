<template>
  <div id="HomePage">
    <!--招标信息-->
    <div class="block-place">
      <!--上传招标文件-->
      <el-dialog
        width="70%"
        title=""
        top="8vh"
        :close-on-click-modal="false"
        :wrapperClosable="false"
        :modal="true"
        :visible.sync="drawer"
      >
        <div class="signup-main">
          <!--添加联系人-->
          <div class="s-main">
            <div class="s-title">
              提交报价 - 请填写询价物资单价！
              <span for="" style="float: right"
                ><UploadExcel :onSuccess="dy" /></span
              ><label for="" @click="dc">导出报价清单</label>
            </div>

            <div style="margin-top: 20px; width: 98%">
              <el-table :data="inparam" style="width: 100%">
                <el-table-column type="index" label="编号" width="80">
                </el-table-column>
                <el-table-column prop="wuzi" label="名称">
                  <template slot-scope="scope">
                    <span>{{ inparam[scope.$index].wuzhi }}</span>
                  </template>
                </el-table-column>

                <el-table-column prop="danwei" label="含税单价（元）">
                  <template slot-scope="scope">
                    <el-input
                      disabled
                      style="width: 90%"
                      type="number"
                      v-model="inparam[scope.$index].tax_inclusive_price"
                      placeholder="该列自动计算"
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="danwei" label="不含税单价（元）">
                  <template slot-scope="scope">
                    <el-input
                      style="width: 90%"
                      type="number"
                      v-model="inparam[scope.$index].price_excluding_tax"
                      placeholder="请输入不含税单价"
                      @blur="changePrice"
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="danwei" label="税率（%）">
                  <template slot-scope="scope">
                    <el-input
                      style="width: 90%"
                      type="number"
                      min="0"
                      v-model="inparam[scope.$index].tax_rate"
                      placeholder="请输入税率"
                      @blur="changePrice"
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="danwei" label="发票类型">
                  <template slot-scope="scope">
                    <el-input
                      style="width: 90%"
                      type="text"
                      v-model="inparam[scope.$index].type"
                      placeholder="请输入发票类型"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div class="s-main">
            <div class="s-title">投标文件 - 必传项</div>
            <div class="qua-one" v-for="(item, index) in files" :key="index">
              <span style="font-size: 14px"
                >{{
                  item
                    ? item.substring(item.lastIndexOf("/") + 1)
                    : "请上传投标文件"
                }}<i
                  style="padding-left: 20px; cursor: pointer"
                  @click="delFiles(index)"
                  class="el-icon-circle-close"
                  v-if="index > 0 && item"
                ></i
              ></span>
              <div @click="uploadindexs(index)">
                <el-upload
                  class="c-image"
                  action=""
                  :on-change="upMemEntrImages"
                  :show-file-list="false"
                  :auto-upload="false"
                >
                  <i
                    class="el-icon-plus el-icon-circle-plus"
                    style="font-size: 24px"
                  ></i>
                </el-upload>
              </div>
            </div>
            <el-link type="primary" @click="addFiles" style="margin-top: 20px"
              >添加投标文件</el-link
            >
          </div>

          <div class="submit-sign" @click="submitSign">提交投标</div>
        </div>
      </el-dialog>
      <div class="b-left">
        <div class="block-box">
          <!--基础内容-->
          <div class="content">
            <div class="info">
              <div class="bid-title">{{ biddetail.name }}</div>
              <div class="info-one">询价类型：{{ biddetail.tName }}</div>
              <div class="info-one">招标编号：{{ biddetail.num }}</div>
              <div class="info-one">发布日期：{{ biddetail.created_at }}</div>
              <div class="info-one">开始日期：{{ biddetail.start_time }}</div>
              <div class="info-one">截止日期：{{ biddetail.end_time }}</div>
              <div class="info-one">
                询价方式：{{ biddetail.type == 1 ? "一般" : "紧急" }}
              </div>
            </div>
            <!-- <div class="dese">
              <div class="desc-title">询价项目</div>
              <div class="desc-desc">
                <el-table
                  :data="biddetail.materials"
                  style="width: 100%"
                >
                  <el-table-column prop="name" label="物资名称">
                    <template slot-scope="scope">
                      <span>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="specifications" label="规格型号">
                    <template slot-scope="scope">
                      <span>{{ scope.row.specifications }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="company" label="物资单位">
                    <template slot-scope="scope">
                      <span>{{ scope.row.company }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="quantity" label="数量">
                    <template slot-scope="scope">
                      <span>{{ scope.row.quantity }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div> -->
            <div class="dese">
              <div class="desc-title">询价详情</div>
              <div class="desc-desc" v-html="biddetail.remarks"></div>
            </div>
            <!--报名按钮-->
            <div class="submit" @click="opendrawer">提交询价</div>
          </div>
        </div>
      </div>
      <div class="b-right">
        <div class="file-title">附件 - <span>点击即可下载</span></div>
        <div class="file-list">
          <div
            class="file-one"
            @click="downFile(item)"
            v-for="(item, index) in biddetail.inquiry_list_enclosure"
            :key="index"
          >
            <i
              style="color: #e52d27"
              class="iconfont icon-file-jpg-Fill"
              v-if="
                item.path.split('.')[1] == 'jpg' ||
                item.path.split('.')[1] == 'png' ||
                item.path.split('.')[1] == 'bmp'
              "
            ></i>
            <i
              style="color: blue"
              class="iconfont icon-Word"
              v-else-if="
                item.path.split('.')[1] == 'doc' ||
                item.path.split('.')[1] == 'docx'
              "
            ></i>
            <i
              style="color: green"
              class="iconfont icon-excel"
              v-else-if="
                item.path.split('.')[1] == 'xls' ||
                item.path.split('.')[1] == 'xlsx'
              "
            ></i>
            <i
              style="color: #c00"
              class="iconfont icon-pdf"
              v-else-if="item.path.split('.')[1] == 'pdf'"
            ></i>
            <i style="color: #333" class="iconfont icon-wenjian" v-else></i>
            <label for="">{{
              item.path.substring(item.path.lastIndexOf("/") + 1)
            }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RsaPubEncrypt } from "../../util/cipher";
import BidApi from "../../api/bidApi";
import UploadApi from "../../api/uploadApi";
import UserApi from "../../api/userApi";
import UploadExcel from "../../components/UploadExcel";
import NewsApi from "../../api/newsApi";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "HomePage",
  metaInfo() {
    return this.$head(
      "参与询价-电子招标采购平台-四川纹江致远建筑开发工程有限公司"
    );
  },
  data() {
    return {
      drawer: false,
      bid: "",
      biddetail: {},
      userinfo: {},
      isLogin: "",
      show: false,
      param: {
        account: "",
        password: "",
      },
      status: 0,
      qua: [],
      inparam: [],
      files: [""],
      baojialist: [],
      fileindex: null,
    };
  },
  components: { UploadExcel },
  computed: {
    ...mapState("game", ["gameList"]),
  },
  watch: {},
  mounted() {},
  methods: {
    delFiles(index) {
      if (index == 0) {
        this.$message.error("请至少添加一个附件");
      } else {
        this.files.splice(index, 1);
      }
    },

    uploadindexs(index) {
      this.fileindex = index;
      console.log(index);
    },

    addFiles() {
      this.files.push("");
    },

    async upMemEntrImages(file) {
      try {
        let res = await UploadApi.uFiles({
          file: file.raw,
          e: 1,
        });
        this.$set(this.files, this.fileindex, res);
        // this.signparam.files[this.fileindex] = res;
      } catch (e) {
        this.$message.error(e.message);
      }
    },

    ...mapActions("user", ["login", "getUserInfo"]),
    ...mapMutations("user", ["loginOut"]),
    //用户登录
    toLogin() {
      this.$store.commit("login/setShow", true);
      this.$store.commit("login/setType", "accountReg");
    },
    changePrice() {
      for (let i = 0; i < this.inparam.length; i++) {
        if (this.inparam[i].price_excluding_tax && this.inparam[i].tax_rate) {
          this.inparam[i].tax_inclusive_price = (
            this.inparam[i].price_excluding_tax *
            (1 + this.inparam[i].tax_rate / 100)
          ).toFixed(2);
        }
      }
    },
    async userLogin() {
      if (!this.param.account) {
        this.$message.error("请输入账号或手机号");
      } else if (!this.param.password) {
        this.$message.error("请输入密码");
      } else {
        let form = { ...this.param };
        form.password = RsaPubEncrypt(form.password);
        try {
          let res = await UserApi.uLogin(form);
          this.$message.success("登录成功");
          localStorage.setItem("token", res.token);
          this.userinfo = res.user;
          this.isLogin = res.token;
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },
    //获取用户信息
    async getUSerinfo() {
      try {
        let res = await UserApi.user();
        this.userinfo = res;
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    //导入报价
    dy(results) {
      for (let i = 0; i < results.results.length; i++) {
        for (let j = 0; j < this.inparam.length; j++) {
          if (results.results[i].id == this.inparam[j].inquiry_materials_id) {
            this.inparam[j].tax_inclusive_price = (
              results.results[i].不含税价 *
              (1 + results.results[i].税率 / 100)
            ).toFixed(2);
            this.inparam[j].price_excluding_tax = results.results[i].不含税价;
            this.inparam[j].tax_rate = results.results[i].税率;
            this.inparam[j].type = results.results[i].发票类型;
          }
        }
      }
    },
    //导出报价
    dc() {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = ["id", "名称", "不含税价", "税率", "发票类型"];
        const filterVal = [
          "inquiry_materials_id",
          "wuzhi",
          "tax_inclusive_price",
          "tax_rate",
          "type",
        ];
        const data = this.formatJson(filterVal, this.inparam);
        excel.export_json_to_excel({
          header: tHeader, //表头 必填
          data, //具体数据 必填
          filename: "询价清单", //非必填
          autoWidth: true, //非必填
          bookType: "xlsx", //非必填
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    opendrawer() {
      this.drawer = true;
    },
    //获取招标详情
    async getDetail() {
      try {
        let res = await BidApi.inDetail({
          id: this.bid,
        });
        this.biddetail = res;
        for (let i = 0; i < res.materials.length; i++) {
          this.inparam.push({
            inquiry_materials_id: res.materials[i].id.toString(),
            wuzhi: res.materials[i].name,
            price_excluding_tax: "",
            tax_inclusive_price: "",
            tax_rate: "",
            quantity: res.materials[i].quantity,
            type: "",
          });
        }
        console.log(this.inparam);
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    //报名
    toSignup() {
      if (!this.isLogin) {
        this.$message.error("请先登录");
        setTimeout(() => {
          this.$router.push("/");
        }, 1200);
      } else {
        //用户已登录，判断招标状态
        if (this.biddetail.status == 2) {
        } else {
          this.$message.error("已过招标期，停止报名");
        }
      }
    },
    checkData() {
      for (let i = 0; i < this.inparam.length; i++) {
        for (let key in this.inparam[i]) {
          if (this.inparam[i][key] == "") {
            this.$message.error(
              "请完善物资" + this.biddetail.materials[i].name + "的询价表单"
            );
            return;
          }
        }
      }
      return true;
    },
    async submitSign() {
      //检测是否有询价未上传
      if (this.checkData()) {
        try {
          if (!this.files || !this.files[0]) {
            return this.$message.error("请上传投标文件");
          }
          let form = {
            inparam:this.inparam,
            files:this.files,
          };
          await BidApi.inSubmit(form);
          this.drawer = false;
          this.$message.success("报价成功");
          this.drawer = false;
          this.$router.push("/UserIn");
          this.inparam = [];
          await this.getDetail();
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },
    downFile(item) {
      let imgUrl = "";
      if (item.path.includes("http") || item.path.includes("https")) {
        imgUrl = item.path;
      } else {
        imgUrl = "http://oa.scwjzy.cn/UpLoader/" + item.path;
      }

      const el = document.createElement("a");
      el.style.display = "none";
      el.setAttribute("target", "_blank");
      el.href = imgUrl;
      console.log(el);
      document.body.appendChild(el);
      el.click();
      document.body.removeChild(el);
    },
  },
  async created() {
    //获取招标ID
    if (this.$route.query.id) {
      this.bid = this.$route.query.id;
    }
    if (localStorage.getItem("token")) {
      this.isLogin = localStorage.getItem("token");
      await this.getUSerinfo();
    }
    await this.getDetail();
  },
};
</script>
<style>
.el-dialog {
  margin-top: 8vh !important;
}
.signup-main::-webkit-scrollbar {
  display: none !important;
}
.el-drawer__wrapper::-webkit-scrollbar {
  display: none;
}
</style>
<style scoped>
/* 整体盒子 */
#HomePage {
  width: 100%;
}
.signup-main::-webkit-scrollbar {
  display: none !important;
}
.block-place {
  width: 1360px;
  min-height: 680px;
  overflow: hidden;
  margin: auto;
  background: #fff;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}
.submit {
  width: 370px;
  height: auto;
  overflow: hidden;
  background: #9e241d;
  padding: 18px 0;
  color: #fff;
  text-align: center;
  cursor: pointer;
  margin-left: 20px;
  margin-bottom: 100px;
  margin-top: 100px;
}
.submit:hover {
  background: #d1372f;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.block-box {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.content {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.info {
  width: calc(100% - 40px);
  height: auto;
  overflow: hidden;
  padding: 30px 20px 20px 20px;
  background: #fff;
}
.dese {
  width: calc(100% - 40px);
  height: auto;
  overflow: hidden;
  background: #fff;
  padding: 20px;
}
.desc-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-bottom: solid 1px #eee;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}
.desc-desc {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 20px;
  line-height: 24px;
}
.info-one {
  width: 30%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-bottom: 20px;
}
.info-one:nth-child(3n) {
  float: right;
}
.info-one:nth-child(3n-2) {
  margin-left: 5%;
}
.b-left {
  width: 970px;
  height: auto;
  overflow: hidden;
  float: left;
  border-right: solid 1px #eee;
}
.b-right {
  width: 370px;
  float: right;
  height: auto;
  overflow: hidden;
}
.bid-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
  color: #9e241d;
}
.file-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-weight: bold;
  padding-bottom: 20px;
}
.file-title span {
  font-size: 14px;
  font-weight: normal !important;
  color: #999;
}
.file-list {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.file-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 14px;
  padding: 15px 0;
  cursor: pointer;
}
.file-one i {
  display: inline-block;
  padding-right: 5px;
}
.file-one label {
  cursor: pointer;
}
.file-one label:hover {
  color: #9e241d;
}
.signup-main {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 20px;
}
.s-main {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 35px;
}
.s-title {
  width: 98%;
  height: auto;
  overflow: hidden;
  font-weight: bold;
}
.s-title label {
  display: block;
  float: right;
  margin-left: 20px;
  color: #fff;
  background: #e52d27;
  cursor: pointer;
  border-radius: 3px;
  padding: 6px 15px;
  font-size: 12px;
}
.submit-sign {
  width: 200px;
  height: 54px;
  background: #9e241d;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 54px;
  margin-top: 50px;
}
.submit-sign:hover {
  background: #d1372f;
}
.in-one {
  width: calc(99% - 40px);
  height: auto;
  overflow: hidden;
  padding: 20px;
  border: solid 1px #f2f2f2;
  margin-top: 30px;
}
.in-title {
  width: 100%;
  height: auto;
  padding-bottom: 15px;
  font-weight: bold;
}
.in-desc {
  width: 100%;
  height: auto;
  display: flex;
}
.in-desc > div {
  flex: 1;
}
.in-desc > div span {
  display: block;
  font-size: 14px;
  color: #999;
  padding-bottom: 15px;
}
.in-desc > div input {
  display: block;
  width: 80%;
  padding: 10px;
  border: solid 1px #eee;
}

.qua-one {
  width: calc(99% - 40px);
  height: auto;
  overflow: hidden;
  border: solid 1px #f2f2f2;
  margin-top: 20px;
  padding: 15px 10px;
}

.qua-one span {
  float: left;
  display: block;
  color: #666;
  margin-top: 3px;
}

.c-image {
  display: block;
  float: right;
}
</style>
