<template>
  <div id="HomePage">
    <!--招标信息-->
    <div class="block-place">
      <div class="block-box">
        <!--顶部-->
        <div class="top-bg">
          <span>招标详情</span>
          <label for="" @click="toSignup" v-if="biddetail.bid_open==0&&biddetail.status >= 2&&biddetail.status<5">立即投标</label>
        </div>
        <!--基础内容-->
        <div class="content">
          <div class="info">
            <div class="bid-bigtitle">{{ biddetail.title }}</div>
            <!-- <div class="info-one">
              招标类型：
              <label for="" v-if="biddetail.json && biddetail.json.type == 0"
                >大宗材料采购</label
              >
              <label for="" v-if="biddetail.json && biddetail.json.type == 1"
                >专业分包</label
              >
              <label for="" v-if="biddetail.json && biddetail.json.type == 2"
                >机械入库</label
              >
              <label for="" v-if="biddetail.json && biddetail.json.type == 3"
                >服务项目</label
              >
            </div> -->
            <div class="info-one" v-if="biddetail.json && biddetail.json.bianhao">
              招标编号：{{ biddetail.json && biddetail.json.bianhao }}
            </div>
            <div class="info-one" v-if="biddetail.json && biddetail.json.xm">
              项目名称：{{ biddetail.json && biddetail.json.xm }}
            </div>
            <div class="info-one">
              招标单位：{{ biddetail.json && biddetail.json.gongsi }}
            </div>
            <div class="info-one">报名开始：{{ biddetail.bidding_start_time }}</div>
            <div class="info-one">报名结束：{{ biddetail.bidding_end_time }}</div>
            <div class="info-one">
              开标日期：{{ biddetail.bid_open_time }}
            </div>
            <div class="info-one">
              招标分类：{{ biddetail.classify&&biddetail.classify.name?biddetail.classify.name:'' }}
            </div>
            <div class="info-one">
              开标类型：{{ biddetail.bid_open==0?'网络线上开标':'线下开标' }}
            </div>
            <div class="info-one" v-if="biddetail.bid_open==0">
              当前状态：
              <b style="font-weight: bold" v-if="biddetail.status == 1">未开始</b>
              <b style="font-weight: bold" v-if="biddetail.status == 2">报名中</b>
              <b style="font-weight: bold" v-if="biddetail.status == 3">报名结束</b>
              <b style="font-weight: bold" v-if="biddetail.status == 4">已开标</b>
              <b style="font-weight: bold" v-if="biddetail.status == 5">已流标</b>
              <b style="font-weight: bold" v-if="biddetail.status == 6">已中标</b>
              <b style="font-weight: bold" v-if="biddetail.status == 7">终止招标</b>
            </div>
            <div class="info-one" v-if="biddetail.status == 6">
              中标单位：{{ biddetail.unitName }}
            </div>
            <div class="info-one" v-if="biddetail.status == 5">
              流标原因：{{biddetail.memo.split('，')[0]}}
            </div>
            <div class="info-one" v-if="biddetail.status == 5">
              {{biddetail.memo.split('，')[1]}}
            </div>
            <!-- <div v-if="biddetail.status == 2" class="end-time">
              距报名结束：{{ count }}
            </div> -->
          </div>
          <div class="dese">
            <div class="desc-title">招标详情</div>
            <div class="desc-desc" v-html="biddetail.content"></div>
          </div>
          <div class="dese" v-if="biddetail.status != 6">
            <div class="desc-title">
              招标附件<span>提示：点击文件名即可下载</span>
            </div>
            <div class="file-list" v-if="biddetail.json && biddetail.json.accessory">
              <div class="file-one" @click="downFile(item)" v-for="(item, index) in biddetail.json.accessory"
                :key="index">
                <i style="color: #e52d27" class="iconfont icon-file-jpg-Fill" v-if="item.wjmc.substring(item.wjmc.lastIndexOf('/') + 1).split('.')[1] == 'jpg' ||item.wjmc.substring(item.wjmc.lastIndexOf('/') + 1)
                    .split('.')[1] == 'png' ||
                  item.wjmc
                    .substring(item.wjmc.lastIndexOf('/') + 1)
                    .split('.')[1] == 'bmp'
                "></i>
                <i style="color: blue" class="iconfont icon-Word" v-else-if="
                  item.wjmc
                    .substring(item.wjmc.lastIndexOf('/') + 1)
                    .split('.')[1] == 'doc' ||
                  item.wjmc
                    .substring(item.wjmc.lastIndexOf('/') + 1)
                    .split('.')[1] == 'docx'
                "></i>
                <i style="color: green" class="iconfont icon-excel" v-else-if="
                  item.wjmc
                    .substring(item.wjmc.lastIndexOf('/') + 1)
                    .split('.')[1] == 'xls' ||
                  item.wjmc
                    .substring(item.wjmc.lastIndexOf('/') + 1)
                    .split('.')[1] == 'xlsx'
                "></i>
                <i style="color: #c00" class="iconfont icon-pdf" v-else-if="item.wjmc.split('.')[1] == 'pdf'"></i>
                <i style="color: #333" class="iconfont icon-wenjian" v-else></i>
                <label for="" v-if="
                  item.wjmc.includes('http') || item.wjmc.includes('https')
                ">{{
                item.wjmc.substring(item.wjmc.lastIndexOf("/") + 1)
                }}</label>
                <label for="" v-else>{{ item.wjmc}}</label>
              </div>
            </div>
          </div>
          <div class="dese" v-if="biddetail.clarify && biddetail.clarify.length > 0">
            <div class="desc-title">招标补遗</div>
            <div class="desc-desc">
              <div class="bu-one" @click="toBuDetail(v)" v-for="(v, k) in biddetail.clarify" :key="k">
                {{ v.title }}<label style="font-weight:bold;padding-left: 15px;">[附件数量：{{
                v&&v.enclosure?v.enclosure.length:'0' }}]</label><span>发布时间：{{v.created_at}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Base from "../../api/base";
import { RsaPubEncrypt } from "../../util/cipher";
import BidApi from "../../api/bidApi";
import UserApi from "../../api/userApi";
import NewsApi from "../../api/newsApi";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "HomePage",
  metaInfo() {
    return this.$head("招标详情-电子招标采购平台-四川纹江致远建筑开发工程有限公司");
  },
  data() {
    return {
      bid: "",
      biddetail: {},
      userinfo: {},
      isLogin: "",
      show: false,
      param: {
        account: "",
        password: "",
      },
      count: "", //倒计时
      seconds: null, // 10天的秒数
      status: 0,
      dict: {
        0: {
          label: "未启用",
        },
        1: {
          label: "未开始",
        },
        2: {
          label: "招标中",
        },
        3: {
          label: "招标结束",
        },
        4: {
          label: "已开标",
        },
        5: {
          label: "已流标",
        },
        6: {
          label: "已中标",
        },
        7: {
          label: "终止招标",
        },
      },
    };
  },
  components: {},
  computed: {
    ...mapState("game", ["gameList"]),
  },
  watch: {},
  mounted() {
    this.Time(); //调用定时器
  },
  methods: {
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      d = d < 10 ? "0" + d : d;
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      s = s < 10 ? "0" + s : s;
      this.count = d + "天" + h + "时" + m + "分" + s + "秒";
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        this.seconds -= 1;
        this.countDown();
      }, 1000);
    },
    ...mapActions("user", ["login", "getUserInfo"]),
    ...mapMutations("user", ["loginOut"]),
    //用户登录
    toLogin() {
      this.$store.commit("login/setShow", true);
      this.$store.commit("login/setType", "accountReg");
    },
    async userLogin() {
      if (!this.param.account) {
        this.$message.error("请输入账号或手机号");
      } else if (!this.param.password) {
        this.$message.error("请输入密码");
      } else {
        let form = { ...this.param };
        form.password = RsaPubEncrypt(form.password);
        try {
          let res = await UserApi.uLogin(form);
          this.$message.success("登录成功");
          localStorage.setItem("token", res.token);
          this.userinfo = res.user;
          this.isLogin = res.token;
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },
    downFile(item) {
      if (localStorage.getItem("token")) {
        let imgUrl = "";
        if (item.wjmc.includes("http") || item.wjmc.includes("https")) {
          imgUrl = item.wjmc;
          const el = document.createElement("a");
          el.style.display = "none";
          el.setAttribute("target", "_blank");
          el.href = imgUrl;
          console.log(el);
          document.body.appendChild(el);
          el.click();
          document.body.removeChild(el);
        } else {
              let url = Base.baseURL + "/pc/file/download?name=" + item.wjmc;
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.setAttribute("download", item.wjmc.substring(item.wjmc.lastIndexOf("/") + 1));
      document.body.appendChild(a);
      a.click(); //执行下载
      window.URL.revokeObjectURL(a.href); //释放url
      document.body.removeChild(a);


          // axios({
          //   method: "POST",
          //   url: Base.baseURL + "/file/download?name=" + item.wjmc,
          //   headers: {
          //     'X-Token': localStorage.getItem('token')
          //   },
          //   responseType: 'blob'
          // }).then(res => {
            
          //   // 请求成功后处理流
          //   if (res.status === 200) {
          //     const blob = new Blob([res.data])
          //     const downloadElement = document.createElement('a');
          //     const href = window.URL.createObjectURL(blob); //创建下载的链接
          //     downloadElement.href = href;
          //     downloadElement.download = item.wjmc; //下载后文件名
          //     document.body.appendChild(downloadElement);
          //     downloadElement.click(); //点击下载
          //     document.body.removeChild(downloadElement); //下载完成移除元素
          //     window.URL.revokeObjectURL(href); //释放掉blob对象
          //   }
          // })
        }
      } else {
        this.$message.error('请登陆后下载招标文件')
      }

    },
    //获取用户信息
    async getUSerinfo() {
      try {
        let res = await UserApi.user();
        this.userinfo = res;
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    toBuDetail(item) {
      let url = this.$router.resolve({
        path: "/bid/addendum",
        query: { id: item.id },
      });
      window.open(url.href, "");
    },
    //获取招标详情
    async getDetail() {
      try {
        let res = await BidApi.bidDetail({
          id: this.bid,
        });
        this.biddetail = res;
        let now = Date.parse(new Date()) / 1000;
        let date = new Date(res.bidding_end_time).getTime() / 1000;
        this.seconds = date - now;
        console.log(this.seconds);
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    //报名
    toSignup() {
      if (!this.isLogin) {
        this.$message.error("请先登录");
        setTimeout(() => {
          this.$router.push("/");
        }, 1200);
      } 
      // else if (this.biddetail.isTender == true) {
      //   this.$message.error("您已投标，请勿重复投标");
      // } 
      else {
        //用户已登录，判断招标状态
        // if (this.biddetail.status == 2) {
          //判断是否有审核通过的供应商
          if (!this.userinfo.info) {
            this.$message.error("暂无审核通过的供应商");
          } else {
            //判断是否黑名单
            if (this.userinfo.info.black) {
              this.$message.error("您已被加入供应商黑名单，暂不能进行报名");
            } else {
              this.$confirm(
                "确认使用用" + this.userinfo.info.name + "进行报名, 是否继续?",
                "提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then(() => {
                  //前往报名页面
                  this.$router.push({
                    path: "/bid/signup",
                    query: { id: this.bid,tou:this.biddetail.isTender?1:2 },
                  });
                })
                .catch(() => { });
            }
          }
        // } else {
        //   this.$message.error("已过招标期，停止报名");
        // }
      }
    },
  },
  async created() {
    //获取招标ID
    if (this.$route.query.id) {
      this.bid = this.$route.query.id;
    }
    if (localStorage.getItem("token")) {
      this.isLogin = localStorage.getItem("token");
      await this.getUSerinfo();
    }
    await this.getDetail();
  },
};
</script>
<style scoped>
/* 整体盒子 */
#HomePage {
  width: 100%;
}

.block-place {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin: auto;
}

.block-box {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 20px 0 40px 0;
}

.top-bg {
  width: 1240px;
  height: 120px;
  background-image: url("../../assets/img/bbg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  line-height: 120px;
  padding: 0 20px;
}

.top-bg span {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.top-bg label {
  display: block;
  background: #f08616;
  cursor: pointer;
  color: #fff;
  float: right;
  width: 120px;
  height: 46px;
  line-height: 46px;
  margin-top: 37px;
  text-align: center;
}

.top-bg label:hover {
  background: #f59834;
}

.content {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.info {
  width: calc(100% - 40px);
  height: auto;
  overflow: hidden;
  padding: 30px 20px 20px 20px;
  background: #fff;
}

.dese {
  width: calc(100% - 40px);
  height: auto;
  overflow: hidden;
  margin-top: 20px;
  background: #fff;
  padding: 20px;
}

.desc-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-bottom: solid 1px #eee;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}

.desc-desc {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 20px;
  line-height: 24px;
}

.info-one {
  width: 30%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-bottom: 20px;
}

.desc-title span {
  float: right;
  font-size: 14px;
  color: #999;
  padding-top: 10px;
}

.file-list {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 50px;
}

.file-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 14px;
  padding: 15px 0;
  cursor: pointer;
}

.file-one i {
  display: inline-block;
  padding-right: 5px;
}

.file-one label {
  cursor: pointer;
}

.file-one label:hover {
  color: #9e241d;
}

.end-time {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
  color: #f08616;
}

.bu-one {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 10px 0;
  cursor: pointer;
}

.bu-one:hover {
  color: #e52d27;
}

.bu-one span {
  float: right;
  font-size: 14px;
  color: #999;
}

.bid-bigtitle {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 10px 0 30px 0;
  font-size: 20px;
  font-weight: bold;
}
</style>
