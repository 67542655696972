<template>
  <div id="HomePage">
    <!--招标信息-->
    <div class="block-place">
      <div class="block-box">
        <!--顶部-->
        <div class="top-bg">
        </div>
        <!--基础内容-->
        <div class="content">
          <div class="info">
            <div class="news-title">{{newsdetail.title}}</div>
            <div class="news-info">阅读数：{{newsdetail.read}} 发布时间：{{newsdetail.created_at}}</div>
            <div class="news-desc" v-html="newsdetail.detail&&newsdetail.detail.content?newsdetail.detail.content:''">
            </div>
            <div class="files" v-if="newsdetail.detail&&newsdetail.detail.attachment.length>0">
              <p>附件下载</p>
              <span @click="downFile(item.url)" v-for="(item,index) in newsdetail.detail.attachment"
                :key="index">{{item.url.substring(item.url.lastIndexOf('/')+1)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RsaPubEncrypt } from "../../util/cipher";
import BidApi from "../../api/bidApi";
import UserApi from "../../api/userApi";
import NewsApi from "../../api/newApi";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "HomePage",
  metaInfo() {
    return this.$head("电子招标采购平台-四川纹江致远建筑开发工程有限公司");
  },
  data() {
    return {
      bid: "",
      newsdetail: {},
      userinfo: {},
      isLogin: "",
      show: false,
      param: {
        account: "",
        password: "",
      },
      count: "", //倒计时
      seconds: null, // 10天的秒数
      status: 0,
      timeshow: true,
      nid: null
    };
  },
  components: {},
  computed: {
    ...mapState("game", ["gameList"]),
  },
  watch: {},
  mounted() {
  },
  methods: {
    ...mapActions("user", ["login", "getUserInfo"]),
    ...mapMutations("user", ["loginOut"]),
    //用户登录
    toLogin() {
      this.$store.commit("login/setShow", true);
      this.$store.commit("login/setType", "accountReg");
    },
    async userLogin() {
      if (!this.param.account) {
        this.$message.error("请输入账号或手机号");
      } else if (!this.param.password) {
        this.$message.error("请输入密码");
      } else {
        let form = { ...this.param };
        form.password = RsaPubEncrypt(form.password);
        try {
          let res = await UserApi.uLogin(form);
          this.$message.success("登录成功");
          localStorage.setItem("token", res.token);
          this.userinfo = res.user;
          this.isLogin = res.token;
        } catch (error) {
          this.$message.error(error.message);
        }
      }
    },
    //获取用户信息
    async getUSerinfo() {
      try {
        let res = await UserApi.user();
        this.userinfo = res;
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    downFile(item) {
      const el = document.createElement("a");
      el.style.display = "none";
      el.setAttribute("target", "_blank");
      el.href = item;
      console.log(el);
      document.body.appendChild(el);
      el.click();
      document.body.removeChild(el);

    },
    //获取新闻详情
    async getDetail() {
      try {
        let res = await NewsApi.newsDetail({
          id: this.nid,
        });
        this.newsdetail = res;
        console.log(this.newsdetail)
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    //报名
    toSignup() {
      if (!this.isLogin) {
        this.$message.error("请先登录");
        setTimeout(() => {
          this.$router.push("/");
        }, 1200);
      } else {
        //用户已登录，判断招标状态
        if (this.biddetail.state == 2) {
          //判断是否有审核通过的供应商
          if (!this.userinfo.info) {
            this.$message.error("暂无审核通过的供应商");
          } else {
            //判断是否黑名单
            if (this.userinfo.info.black) {
              this.$message.error("您已被加入供应商黑名单，暂不能进行报名");
            } else {
              this.$confirm(
                "确认使用用" + this.userinfo.info.name + "进行询价, 是否继续?",
                "提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then(() => {
                  //前往报名页面
                  this.$router.push({
                    path: "/inquiry/userinquiry",
                    query: { id: this.bid },
                  });
                })
                .catch(() => { });
            }
          }
        } else {
          this.$message.error("已过询价期，停止询价");
        }
      }
    },
  },
  async created() {
    //获取新闻ID
    if (this.$route.query.id) {
      this.nid = this.$route.query.id;
    }
    if (localStorage.getItem("token")) {
      this.isLogin = localStorage.getItem("token");
      await this.getUSerinfo();
    }
    await this.getDetail();
  },
};
</script>
<style scoped>
/* 整体盒子 */
#HomePage {
  width: 100%;
}

.block-place {
  width: 1280px;
  height: auto;
  overflow: hidden;
  margin: auto;
}

.block-box {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 20px 0 40px 0;
}

.top-bg {
  width: 1240px;
  height: 120px;
  background-image: url("../../assets/img/bbg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  line-height: 120px;
  padding: 0 20px;
}

.top-bg span {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.top-bg label {
  display: block;
  background: #f08616;
  cursor: pointer;
  color: #fff;
  float: right;
  width: 120px;
  height: 46px;
  line-height: 46px;
  margin-top: 37px;
  text-align: center;
}

.top-bg label:hover {
  background: #f59834;
}

.content {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.info {
  width: calc(100% - 140px);
  height: auto;
  overflow: hidden;
  padding: 50px 70px;
  background: #fff;
}

.dese {
  width: calc(100% - 40px);
  height: auto;
  overflow: hidden;
  margin-top: 20px;
  background: #fff;
  padding: 20px;
}

.desc-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-bottom: solid 1px #eee;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}

.desc-desc {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 20px;
  line-height: 24px;
}

.info-one {
  width: 25%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-bottom: 30px;
}

.end-time {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
  color: #f08616;
}

.news-title {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

.news-info {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  color: #999;
  padding: 20px 0;
}

.news-desc {
  width: 100%;
  height: auto;
  overflow: hidden;
  line-height: 22px;
  padding-top: 50px;
}

.files {
  width: calc(100% - 40px);
  height: auto;
  overflow: hidden;
  padding: 20px;
  background: #eee;
  margin-top: 50px;
}

.files p {
  display: block;
  font-weight: bold;
}

.files span {
  display: block;
  margin-top: 15px;
  cursor: pointer;
}
</style>
