import UserApi from '../../api/userApi';

import { setCookie, delCookie, toast } from '../../common/common';
import { Message } from 'element-ui';

export default {

    namespaced: true,

    state: {
        gameList: [],
    },

    mutations: {

        setAuthorization: (state, data) => {
            if (data) {
                localStorage.setItem('Authorization', data);
                setCookie('Authorization', data);
            } else {
                localStorage.removeItem('Authorization');
                delCookie('Authorization');
            }
        },

        setUserInfo: (state, data) => {
            if (data) {
                localStorage.setItem('userInfo', JSON.stringify(data));
                setCookie('userInfo', JSON.stringify(data));
            } else {
                localStorage.removeItem('userInfo');
                delCookie('userInfo');
            }
        },

        /**
         * 退出登录
         */
        loginOut(state, stat) {
            this.commit('user/setUserInfo', null);
            this.commit('user/setAuthorization', null);

            Message.success('退出成功~')

            setTimeout(() => {
                location.reload();
            }, 500);

        },

    },

    actions: {

        /**
         * 账号密码快捷登录
         */
        async shortcutLogin({ commit }, param) {

            let res = await UserApi.shortcutLogin(param);

            if (res.status == 1) {
                commit('setAuthorization', res.data);
            }

            return res;

        },
        /**
         * 账号密码登录
         */
        async login({ commit, dispatch }, param) {

            let res = await UserApi.login(param, true)

            if (res.status == 1) {
                commit('setAuthorization', res.data);
            }

            return res;

        },

        /**
         * 获取用户信息
         */
        async getUserInfo({ commit }) {

            const res = await UserApi.user()

            if (res.status == 1) {

                Message.success('登录成功~');

                commit('setUserInfo', res.data);

                setTimeout(() => {
                    location.reload();
                }, 500);
            }

        },
        /**
         * 手机注册
         */
        async registerByPhone({ commit }, param) {

            const res = await UserApi.registerByPhone(param);

            if (res.status == 1) {
                commit('setAuthorization', res.data);
            }

            return res;

        },

    },
}
